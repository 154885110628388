@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.grid-bot-content {
  .grid-bot-left-title {
    padding-top: fn-px2rem(10px);
    padding-left: fn-px2rem(15px);
  }

  .grid-bot-right-title {
    padding-left: fn-px2rem(115px);
    padding-top: fn-px2rem(10px);
  }

  .toggle-icon {
    padding-left: fn-px2rem(10px);
    padding-top: fn-px2rem(10px);
    color: #fff;
  }

  .form-input-shorter {
    width: 50%;
    padding-top: fn-px2rem(10px);
  }
  .form-input-longer {
    width: 100%;
    padding-top: fn-px2rem(10px);
  }
}
.dca-content {
  .dca-bot-left-title {
    padding-top: fn-px2rem(10px);
    padding-bottom: fn-px2rem(10px);
  }

  .dca-bot-right-title {
    padding-left: fn-px2rem(218px);
    padding-top: fn-px2rem(10px);
    padding-bottom: fn-px2rem(10px);
  }
  .dca-bot-at-title {
    padding-left: fn-px2rem(200px);
    padding-top: fn-px2rem(10px);
    padding-bottom: fn-px2rem(10px);
  }

  .form-input-shorter {
    width: 50%;
  }

  .custom-selectors {
    display: flex;
    .right-selector {
      width: 50%;
      padding-left: fn-px2rem(5px);
    }
    .right-input-button,
    .left-input-button {
      background: inherit;
      color: $global-colors-font-0;
      font-weight: 600;
      font-size: fn-px2rem(14px);
    }
    
    .right-input-button {
      border-top-right-radius: fn-px2rem(4px);
      border-bottom-right-radius: fn-px2rem(4px);
      border-left: none;
    }

    .left-input-button {
      border-top-left-radius: fn-px2rem(4px);
      border-bottom-left-radius: fn-px2rem(4px);
      border-right: none;
      padding: fn-px2rem(6px) fn-px2rem(6px) fn-px2rem(6px) fn-px2rem(12px);
    }
    .date-form {
      background-color: inherit;
      color: $global-colors-font-0;
    }
  }
}

.drop-down-button {
  padding-top: fn-px2rem(10px);
  padding-left: fn-px2rem(3px);
}

.day-buttons {
  display: flex;
  .button-today {
    padding-left: fn-px2rem(10px);
  }

  .button-tomorrow {
    margin-left: fn-px2rem(10px);
  }
}
