@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$rem-1-value: fn-px2rem(16px);

.smart-trade {
  &-info-container {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: $rem-1-value;

    .pair-row {
      height: $rem-1-value;
      line-height: $rem-1-value;
    }

    .content-controls {
      height: fn-px2rem(64px);
      border-radius: $rem-1-value;
      background: $global-colors-background-panel;
      align-items: center;
      grid-template-columns: max-content max-content max-content 100%;
      display: grid;
    }

    .history-filters-template {
      background-color: $global-colors-background-panel;
      padding: fn-px2rem(25px);
      border: 1px solid $global-colors-border-neutral-0;
      border-radius: fn-px2rem(16px);

      @media(max-width: 768px){
        width: 100%;
      }

      header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          display: flex;
          align-items: center;

          p {
            margin-bottom: fn-px2rem(0px);
          }

          .arrow {
            margin-left: fn-px2rem(20px);
            cursor: pointer;
          }
        }

        button {
          background-color: #F93939;  
          color: white;
          border-radius: fn-px2rem(4px);
          font-size: fn-px2rem(14px);
          width: fn-px2rem(143px);
          height: fn-px2rem(37px);
          display: flex;
          justify-content: center;
          align-items: center;
        } 
      }

      main {
        margin-top: fn-px2rem(20px);

        .top {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: fn-px2rem(24px);
          margin-bottom: fn-px2rem(35px);

          @media(max-width: 768px){
            grid-template-columns: 1fr;
          }
          
          .col {
            label {
              margin-bottom: fn-px2rem(6px);
            }

            .datesRange {
              display: flex;
              align-items: center;
              height: fn-px2rem(37px);

              .datesDropdown {
                width: fn-px2rem(140px);
                margin-right: fn-px2rem(8px);
              }

              input[type='text']{
                flex: 1;
                height: 100%;
                border-radius: fn-px2rem(4px);
                border: 1px solid #B1B5BF;
                color: $global-font-color-primary;
              }
            }
          }
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media(max-width: 768px){
            flex-direction: column;
            align-items: start;
          }

          .toggle-buttons {
            display: flex;
            gap: fn-px2rem(38px);

            @media(max-width: 768px){
              flex-direction: column;
            }
            
            .react-toggle-track {
              background-color: #d4ddeb;
            }
  
            .react-toggle-thumb {
              background-image: linear-gradient(#4674B6 0%, #2E62AC 100%);
              box-shadow: 0px 2px 4px rgba(24, 58, 106, 0.24)
            }

            div {
              display: flex;
              align-items: center;
              gap: fn-px2rem(16px);
            }
          }

          button {
            background-color: #4674B6;
            color: white;
            border-radius: fn-px2rem(8px);
            padding: 10px 20px;

            @media(max-width: 760px){
              margin-top: fn-px2rem(20px);
            }

            .icon {
              margin-right: fn-px2rem(12px);
            }
          }
        }
      }
    }

    .history-filters-night-theme {
      main {
        .top {
          input[type='text']{
            background-color: #0f131c;
          }
        }
      }
    }

    .table-content {
      border-radius: fn-px2rem(16px);
      background: $global-colors-background-panel;
      border: 1px solid $global-colors-border-neutral-0;

      // @media(max-width: 768px){
      //     margin-bottom: 100px;
      // }
    }
    .custom-table {
      display: grid;
      grid-template-columns: minmax(0, 100%);
      grid-template-rows: minmax(50px, auto) minmax(0, 100%);
      max-height: 100%;
      // color: $global-colors-font-1;

      .scroll-container {
        overflow: auto;
        max-height: fn-px2rem(300px);
      }

      .table-header {
        display: grid;
        // grid-template-columns: minmax(0, 3%) minmax(0, 10%) minmax(0, 10%) minmax(0, 15%) minmax(0, 32%) minmax(0, 10%) minmax(0, 10%) minmax(0, 10%);
        // grid-template-rows: minmax(60px, auto);
        align-items: center;
        padding-left: fn-px2rem(16px);
        padding-right: fn-px2rem(16px);

        .react-toggle-track {
          background-color: #414456;
          border: none;
        }

        .react-toggle-thumb {
          background-color: white;
        }

        @media (max-width: 768px) {
          padding: fn-px2rem(16px);
        }
      }

      .table-header-home {
        display: grid;
        grid-template-columns: 15% 10% 15% 30% 10% 10% 10%;

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }

      .table-header-order {
        display: grid;
        grid-template-columns: 2% 15% 10% 15% 30% 10% 10%;
        justify-content: space-between;

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
          justify-content: start;
        }
      }

      .table-header-history {
        display: grid;
        // grid-template-columns:
        //   minmax(0, 3%) minmax(0, 25%) minmax(0, 20%) minmax(0, 25%) minmax(0, 20%)
        //   minmax(0, 15%);
        // grid-template-rows: minmax(50px, auto);
        grid-template-columns: 13% 8% 14% 12% 13% 13% 14% 10%;
        align-items: center;

        @media(max-width: 768px){
          grid-template-columns: 1fr;
        }
      }

      .paginate-history {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-top: fn-px2rem(20px);

        span {
          width: fn-px2rem(32px);
          height: fn-px2rem(32px);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: fn-px2rem(4px);
          margin: 0px 16px;
          cursor: pointer;
        }

        .arrow {
          background-color: white;
        }

        .page {
          background-color: white;
        }

        .selected {
          background-color: #4674B6;
          color: white;
        }
      }

      .table-body {
        padding: 0 fn-px2rem(16px);

        .search {
          display: flex;
          width: fn-px2rem(600px);
          height: fn-px2rem(37px);

          .input-text {
            width: fn-px2rem(500px);
            height: 100%;
            position: relative;

            .loop {
              position: absolute;
              top: 50%;
              left: fn-px2rem(10px);
            }
          }
        }
      }

      &-row {
        .collapse-header {
          display: grid;

          grid-template-rows: minmax(60px, auto);
          align-items: center;

          cursor: default;
          border-radius: fn-px2rem(4px);
          padding: fn-px2rem(10px) 0;

          .custom-table-body-cell {
            padding-top: fn-px2rem(10px);
            overflow-wrap: break-word;
          }

          .text {
            margin-top: fn-px2rem(26px);
            margin-left: fn-px2rem(8px);

            @media (max-width: 768px) {
              display: none;
            }
          }

          .note {
            color: #00a859;
            font-size: fn-px2rem(12px);
            margin-top: fn-px2rem(10px);
          }

          .profitLose {
            color: #f7931a;
          }
        }

        .colapse-header-home {
          display: grid;
          grid-template-columns: 15% 10% 15% 30% 10% 10% 10%;

          @media (max-width: 768px) {
            grid-template-columns: 1fr;
          }
        }

        .colapse-header-order {
          display: grid;
          grid-template-columns: 2% 15% 10% 15% 30% 10% 10%;
          justify-content: space-between;

          @media (max-width: 768px) {
            grid-template-columns: 1fr;
            justify-content: start;
            gap: fn-px2rem(20px);
            padding: fn-px2rem(10px) 0;
          }
        }

        .collapse-row {
          .collapse-content {
            //background-color: white;
            //background-color: $global-colors-background-panel;

            .collapse-content-wrapper {
              //padding-bottom: 112px;

              .collapse-data {
                display: grid;
                grid-template-columns: minmax(0, 25%) minmax(0, 25%);
                grid-template-rows: auto;
                row-gap: 1rem;
                padding: fn-px2rem(16px);
              }

              .sell-steps-wrapper {
                padding: 0 fn-px2rem(16px) 0 fn-px2rem(16px);

                .step-title {
                  color: $global-colors-font-0;
                  font-weight: 500;
                }

                .sell-steps {
                  display: grid;
                  grid-template-columns: minmax(0, 25%) minmax(0, 25%) minmax(0, 25%) minmax(0, 25%);
                  grid-template-rows: auto;

                  @media (max-width: 768px) {
                    display: none;
                  }

                  @media (max-width: 800px) {
                    grid-template-columns: minmax(0, 25%) minmax(0, 25%) minmax(0, 25%);
                    display: none;
                  }

                  .step-item {
                    text-align: center;

                    @media (max-width: 768px) {
                      text-align: left;
                    }

                    margin-bottom: fn-px2rem(10px);
                    color: $global-colors-font-2;

                    &-header {
                      color: $global-colors-font-0;
                    }
                  }
                }
              }

              .active-trades {
                padding: fn-px2rem(16px) fn-px2rem(16px) 0 fn-px2rem(16px);

                .active-trade-item {
                  margin-right: fn-px2rem(80px);
                  margin-bottom: fn-px2rem(10px);
                  color: $global-colors-font-2;

                  &.horizontal {
                    display: inline-block;
                  }

                  &.vertical {
                    display: block;
                  }

                  .value {
                    color: $info-label-color;
                  }
                }
              }
            }
          }
        }

        &.table-body {
          .custom-table-body-cell {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &.range-cell {
              padding-left: fn-px2rem(10px);
            }

            .more {
              cursor: pointer;
            }
          }

          .pnl {
            color: #f93939;
          }

          .range-cell {
            width: 80%;
          }

          .valign-middle {
            align-items: center;
          }

          // &:nth-of-type(odd) {
          //     background: $global-colors-table-row-even;
          //     color: $global-font-color-secondary;
          // }

          // &:hover {
          //     //background-color: $global-table-hover-color;
          //     //color: $global-font-color-primary;
          // }
        }

        &.table-header,
        &.table-header-history {
          // width: calc(100% - 20px);
          //border-bottom: 1px solid $global-colors-border-neutral-3;
          border-bottom: 1px solid $global-colors-border-neutral-0;
          // padding-left: fn-px2rem(14px);
          // padding-right: fn-px2rem(14px);
          border-radius: 0;
          white-space: nowrap;
        }
      }

      .params-and-profits {
        display: grid;
        grid-template-columns: 15% 30% auto auto;
        grid-auto-rows: auto;
        margin-top: fn-px2rem(20px);
        font-size: fn-px2rem(14px);
        background-color: $global-colors-table-row-even;
        padding: fn-px2rem(16px);

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
          justify-content: start;
          gap: fn-px2rem(30px);
        }

        h3 {
          font-size: fn-px2rem(14px);
          font-weight: 500;
          margin-bottom: fn-px2rem(30px);

          &:nth-child(3) {
            margin-bottom: fn-px2rem(4px);
          }
        }

        h4 {
          font-size: fn-px2rem(14px);
          font-weight: 500;
          margin-bottom: fn-px2rem(4px);
        }

        p {
          margin-bottom: fn-px2rem(4px);
        }

        div {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            div {
              margin-bottom: fn-px2rem(20px);

              @media (max-width: 768px) {
                margin-bottom: fn-px2rem(0px);
              }
            }
          }

          &:nth-child(4) {
            justify-self: end;

            @media (max-width: 768px) {
              justify-self: start;
              margin-bottom: fn-px2rem(0px);
            }

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: fn-px2rem(260px);
              height: fn-px2rem(37px);
              color: white;
              background-color: #21b06e;
              margin-top: fn-px2rem(11px);
            }
          }
        }

        .second-col {
          margin-top: fn-px2rem(45px);
        }

        .usdt {
          color: #21b06e;
          margin-left: fn-px2rem(11px);
        }

        .upper-price {
          color: #00a859;
        }

        .lower-price {
          color: #f93939;
        }
      }

      .table-collapsed {
        padding-bottom: fn-px2rem(100px);
      }
    }

    .custom-night-table {
      .paginate-history {
        .page {
          color: black;
        }
        .selected {
          color: white;
        }
      }
    }

  }
}

.history-data-wrapper {
  display: grid;
  // grid-template-columns: minmax(0, 24%) minmax(0, 23%) minmax(0, 26%) minmax(0, 32%) minmax(0, 17%);
  // grid-template-rows: minmax(50px, auto);
  grid-template-columns: 13% 8% 14% 12% 13% 13% 14% 10%;
  align-items: center;
  // padding-left: (4%);
  // background: $global-colors-table-row-even;
  padding: 10px 0px;
  border-bottom: 1px solid $global-colors-border-neutral-0;
  // &.title-data,
  // &.wallet-data {
  //   width: fn-px2rem(148px);
  //   height: fn-px2rem(17px);
  //   font-family: 'Inter';
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: fn-px2rem(14px);
  // }

  @media(max-width: 768px){
    grid-template-columns: 1fr;
  }

  // .title-data {
  //   color: $global-colors-link-default;
  // }

  // .wallet-data {
  //   color: #707991;
  // }
  // .transfer-data-wrapper {
  //   color: #00a859;
  // }

  &:nth-child(6) {
    border-bottom: none;
  }

  .sell {
    color: #F93939;
  }

  .buy {
    color: #00A859;
  }

  .filled-state {
    color: #F93939;
  }
}

.slider {
  &.-primary {
    &.custom-range {
      height: 32px;
      &.rc-slider-disabled {
        background-color: transparent;
      }
    }
    .rc-slider-track {
      height: fn-px2rem(2px);
      background: rgba(70, 116, 182, 1);
    }
    .rc-slider-rail {
      height: fn-px2rem(2px);
    }
    .rc-slider-mark-text {
      color: $global-colors-link-default;
    }
    .rc-slider-dot {
      border-color: rgba(70, 116, 182, 1);
    }
    .rc-slider-handle {
      box-shadow: none;
      border: 1px solid #ffffff;
      background: rgba(70, 116, 182, 1);
    }
  }
  &.-danger {
    .rc-slider-track {
      height: fn-px2rem(2px);
      background: $global-negative-color;
    }
    .rc-slider-rail {
      height: fn-px2rem(2px);
    }
    .rc-slider-mark-text {
      color: $global-negative-color;
    }

    .rc-slider-dot {
      border-color: $global-negative-color;
    }

    .rc-slider-handle {
      border: 1px solid #ffffff;
      background: $global-negative-color;
    }
  }
}