@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$rem-1-value: fn-px2rem(16px);
$font-color-right-button: #E2E3EF;

.smart-trade {
  &-bot-settings {

    &-container {

    }
    #spin {
      display: none;
    }

    &-content {
      display: grid;
      grid-template-rows: auto auto;
      grid-row-gap: $rem-1-value;

      .content-body {
        border-radius: $rem-1-value;
        background: $global-colors-background-panel;
        border: 1px solid $global-colors-border-neutral-0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto;
        justify-items: center;

        &>div {
          padding: 16px;
          width: 100%;
        }

        .submit-content {
          border-top: 1px solid $global-colors-border-neutral-0;
          display: grid;
          justify-items: center;
          align-items: center;
          width: 100%;
          button {
            width: 100%;
          }
        }

        .button-100 {
          button {
            width: 100%;
          }
        }
        .customized-toggle .react-toggle {
          top: 0!important;
        }

        .form-divider {
          width: 100%;
          border-bottom: 1px solid $global-colors-border-neutral-0;
          height: 0;
          padding-top: fn-px2rem(8px);
          padding-bottom: fn-px2rem(8px);

          &.hidden {
            padding-top: 0;
          padding-bottom: 0;
          }
        }
        .left-form, .middle-form, .right-form {
          display: grid;
          grid-template-rows: repeat(auto-fill, auto);
          grid-row-gap: $rem-1-value;
          
          .plus-minus-btn {
            padding-left: fn-px2rem(11px);
            padding-right: fn-px2rem(11px);
            cursor: pointer;
            border-radius: 4px !important;
            background: $global-colors-background-main;

            &:hover {
              opacity: .6;
            }
          }

          .controls-buttons {
            margin-top: fn-px2rem(24px);
            margin-bottom: fn-px2rem(12px);
          }

          .stop-loss-range-input {
            margin-top: fn-px2rem(32px);
            margin-bottom: fn-px2rem(24px);
          }

          .form-item {

            .stop-loss-label {
              margin-bottom: fn-px2rem(12px);
            }

            .trailing-stop-loss {
              margin-top: fn-px2rem(12px);
              margin-bottom: fn-px2rem(12px);
            }

            .table-row-wrapper {
              display: grid;
              grid-template-rows: auto;
              grid-template-columns: minmax(132px, 30%) minmax(93px, 70%);
              margin-top: fn-px2rem(12px);
              margin-bottom: fn-px2rem(24px);
            }

            .small-custom-table {
              display: grid;
              grid-template-columns: minmax(0, 100%);
              grid-template-rows: minmax(31px, auto);
              max-height: 100%;
              color: $global-colors-font-1;
      
              .scroll-container {
                  overflow: auto;
              }
      
              &-row {
                  display: grid;
                  grid-template-columns: minmax(0, 50%) minmax(0, 20%) minmax(0, 20%) minmax(0, 10%);
                  grid-template-rows: fn-px2rem(31px);
                  align-items: center;
                  padding-left: fn-px2rem(33px);
                  cursor: default;
                  border-bottom: 1px solid $global-colors-border-neutral-3;
      
      
                  &.small-table-body {
                      .custom-table-body-cell {
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          overflow: hidden;

                          & .btn-close-cell {
                            cursor: pointer;
                            padding: fn-px2rem(5px);

                            &:hover {
                              color: $global-button-color-hover-danger;
                            }
                          }
                      }
      
                      .valign-middle {
                          align-items: center;
                      }
      
                      // &:nth-of-type(odd) {
                      //     background: $global-colors-table-row-even;
                      //     //color: $global-font-color-secondary;
                      // }
      
                      &:hover {
                          //background-color: $global-table-hover-color;
                          //color: $global-font-color-primary;
                      }
                  }
      
                  &.table-header {
                      padding-left: fn-px2rem(33px);
                      padding-right: fn-px2rem(14px);
                      border-radius: 0;
                      white-space: nowrap;
                  }
              }
          }

            .form-label {
              .balance-text {
                color: $icon-color;
                margin-right: fn-px2rem(4px);
              }
            }
            
            .negative-color {
              color: $global-negative-color;
            }
            .positive-color {
              color: $global-positive-color;
            }

            .price-label {
              margin-bottom: fn-px2rem(26px);
            }

            .trailing {
              margin-bottom: fn-px2rem(13px);

              &-span {
                margin-right: fn-px2rem(58px);
              }
            }

            .total {
              margin-bottom: fn-px2rem(24px);
            }

            .size-amount {
              margin-bottom: fn-px2rem(12px);
            }

            

            .form-control {
              border-top-left-radius: 4px !important;
              border-bottom-left-radius: 4px !important;
              border-right: none;
              background: inherit;
              color: inherit;

              &:focus {
                border-color: #ced4da;
                box-shadow: none !important;
                z-index: 0;
              }
            }

            .right-input-button {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              background: inherit;
              border-left: none;
              color: $font-color-right-button;
            }
          }
        }
      }

    }
  }
}

.slider {
  &.-primary {
    .rc-slider-track {
      height: 2px;
      background: rgba(70, 116, 182, 1);
    }
    .rc-slider-rail {
      height: 2px;
    }
    .rc-slider-mark-text {
      color: $global-colors-link-default;
    }
    .rc-slider-dot {
      border-color: rgba(70, 116, 182, 1);
    }
    .rc-slider-handle {
      border: 1px solid #ffffff;
      background: rgba(70, 116, 182, 1);
    }
  }
  &.-danger {
    .rc-slider-track {
      height: 2px;
      background: $global-negative-color;
    }
    .rc-slider-rail {
      height: 2px;
    }
    .rc-slider-mark-text {
      color: $global-negative-color;
    }

    .rc-slider-dot {
      border-color: $global-negative-color;
    }

    .rc-slider-handle {
      border: 1px solid #ffffff;
      background: $global-negative-color;
    }
  }
}