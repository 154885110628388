@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.header {
  height: $header-height;
  width: 100%;
  margin-top: fn-px2rem(8px);

  @media(max-width: 768px){
    height: fn-px2rem(144px);
    margin-top: fn-px2rem(0px);
  }

  .temp-sizes-handle {
    top: 0;
    color: rgba(112, 112, 121, .6);
  }

  &-container {
    height: 100%;
    position: relative;
    max-width: 100%;

    .balances-favorites-container {
      position: relative;
      width: 100%;

      .balances, .favorites {
        // width: fit-content;
        position: relative;

        .decorative-frame {
          stroke: $balances-frame-color;
        }

        .decorative-frame-label {
          fill: $balances-label-color;
        }
      }
    }
  }
}