$font-path-unbounded: "/public/fonts/unbounded";

@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: Unbounded;
  src: url('#{$font-path-unbounded}/Unbounded-Light.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: Unbounded;
  src: url('#{$font-path-unbounded}/Unbounded-Regular.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: Unbounded;
  src: url('#{$font-path-unbounded}/Unbounded-Medium.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: Unbounded;
  src: url('#{$font-path-unbounded}/Unbounded-Blond.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: Unbounded;
  src: url('#{$font-path-unbounded}/Unbounded-Bold.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: Unbounded;
  src: url('#{$font-path-unbounded}/Unbounded-Black.ttf') format('truetype');
}