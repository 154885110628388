.create-bot.modal {
  .modal-content {
    background: $global-colors-background-main;
    color: $global-font-color-primary;
    border-radius: fn-px2rem(16px);

    .modal-header {
      border: none;
      justify-content: right;
      padding-bottom: 0;

      .icon-cancel {
        color: $global-colors-font-0;
      }
    }

    .modal-body {
      padding-top: 0;

      .create-bot-subtitle {
        width: 100%;
        max-width: fn-px2rem(464px);
        margin: 0 auto;
      }

      .grid-rows-33-33-33 {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 33%));
        grid-template-rows: auto;
        column-gap: fn-px2rem(16px);

        @media(max-width: 768px){
          grid-template-columns: minmax(0, 100%);
        }
      }

      @import "_tab_styles";

      .new {
        color: white;
        width: fn-px2rem(65px);
        height: fn-px2rem(23px);
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(96.68deg, #0068FF 10.68%, #7000FF 83.55%);
        border-radius: fn-px2rem(2px) 0 0 fn-px2rem(2px);
        font-size: fn-px2rem(12px);
        font-weight: 300;
        position: absolute;
        right: 0;
      }

      .global-colors-font-2 {
        color: $global-colors-font-2;
      }

      .submit-button {
        .w-33 {
          width: 33%;

          @media(max-width: 768px){
            width: 100%;
          }
        }

        .btn-default {
          background: $global-colors-button-default;
          color: $global-button-color-primary;
          border-color: $global-colors-button-default;
          width: 48%;
        }

        .btn-transparent {
          background-color: $global-colors-background-main;
          color: $global-colors-button-default;
          border-color: $global-colors-button-default;
          width: 48%;
        }
      }
    }
  }
}