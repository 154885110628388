@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}



.example {
  &-container {
    position: relative;
    width: 100%;
    padding: 1rem;
    height: 500px;

    .construction {
      background-image: url('/public/images/static/bricks.svg');
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      position: absolute;

      &-sign {
        background-image: url('/public/images/static/danger.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 50%;
      }
    }
  }
}