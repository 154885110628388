.wrapper {
  height: 100%;
  width: 100%;
  min-width: fn-px2rem(930px);
  color: $global-font-color-primary;
  background: $content-background-color;

  .inner-frame {
    width: 100%;
    height: calc(100% - #{$header-height});
  }

  &.vertical-scroll {
    .inner-frame {
      padding-right: fn-px2rem(19px);
    }
    .main-content-scroll {
      .rcs-positioning {
        right: 0px;
      }
      .rcs-custom-scrollbar {
        height: calc(100% - 6px);
        top: 2px;
      }
    }
  }
}
