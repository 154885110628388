@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.buy-wrapper {
  height: 135vh;
  position: relative;
  background-color: rgba(12, 21, 30, 1);
  background-image: url(/public/images/dashboard-library/home-page-images/buyBackground.svg);
  background-repeat: no-repeat;
  background-size: cover;

  .token-icon {
    border-radius: 100%;
  }

  // img {
  //   height: auto;
  //   width: 100%;
  //   overflow-y: hidden;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   z-index: 0;
  // }

  .buy-container {
    padding-right: 155px;
    padding-left: 155px;
    padding-bottom: 500px;
    padding-top: 150px;
   height: 100%;
    display: grid;
    grid-template-columns: minmax(500px, 586px) minmax(300px, 500px);
    grid-template-rows: auto;
    grid-column-gap: 32px;
    

    .buy-description-info-grid {
      display: flex;
      grid-template-columns: minmax(0, 50%) minmax(0, 50%);
      grid-template-rows: auto;

      .table {
        color: $global-font-color-primary;
        float: right;
      }
    }
  }

  .left-column, .right-column {
    z-index: 10;
    width: 100%;

    .buy-heading {
      font-size: 50px;
      white-space: nowrap;
    }

    .placeholder {
      background: transparent;
      cursor: default;
      border: 1px solid rgba(252, 252, 252, 0.5);
      border-radius: 8px;
      min-height: 294px;
      width: 100%;
      position: relative;
      opacity: 1;

      .wallets-grid {
        display: grid;
        height: 100%;
        grid-template-rows: auto;
        grid-row-gap: 32px;
        opacity: 1;
      }
    }
 
    .buy-description-info-grid {
      display: grid;
      grid-template-columns: minmax(0, 50%) minmax(0, 50%);
      grid-template-rows: auto;

      .table {
        color: $global-font-color-primary;
      }
    }
    .networks {
      position: relative;

      .btn-custom {
        width: 100%;
      }

      img {
        width: 24px;
        height: 24px;
        position: relative;
      }

      .network-label {
        width: fit-content;
        padding-left: 32px;
      }
    }

    .networks {
      .btn-custom {
        width: 100%;
      }
    }

    .buy-disclaimer {
      width: 100%;
    }

    .sub-heading {
      position: relative;

      img {
        width: 24px !important;
        height: 24px !important;
        position: relative;
      }
    }

    .buy-range {
      height: 47px;
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 1);
      position: relative;

      .range-content {
        height: 100%;
        background: linear-gradient(90deg, #00D0FF 0%, #00B0FF 47%, #1B64FF 98%);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 165px;
    padding-bottom: 2rem;
    height: 100%;
    .buy-container {
      padding: 0;
      display: grid;
      grid-template-rows: auto auto;
      grid-row-gap: 32px;
      grid-template-columns: min-content;
      justify-content: center !important;
      grid-column-gap: 0;
    }

  }
}
.buy-modal {
  .token-icon {
    border-radius: 100%;
  }

  .right-input-button.input-group-text {
    background: transparent;
    color: $global-font-color-primary;
    border-left: none;
  }
}
