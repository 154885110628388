$global-content-vertical-padding: 4rem;
$global-fa-font: 'Font Awesome 6 Pro';
$global-fa-font-regular: 400;
$global-fa-font-light: 300;
$global-fa-font-thin: 100;
$global-fa-font-solid: 900;

/* Header */

$header-height-value: 85;
$header-height: #{$header-height-value}px;

/* Navigation Bar */

$navigation-bar-height-value: 71;
$navigation-bar-height: #{$navigation-bar-height-value}px;

/* Content */

$content-border-radius: 1rem;

/* Container */
$container-max-width: fn-px2rem(1196px);
$container-min-width: fn-px2rem(994px);

/* Borders */

$global-table-cell-border-radius: fn-px2rem(3px);
$global-table-border-color: rgba(255, 255, 255, 0.1);
$global-table-hover-color: rgba(255, 255, 255, 0.3);

$global-icon-color: rgba(112, 121, 145, 1);
$global-icon-hover-color: rgba(29, 83, 255, 1);
$global-active-badge-color: rgba(255, 0, 0, 1);

$global-transition: all 0.5s ease-in-out;

/*
Theme variables
 */

$global-font-color-primary: var(--main-global-font-color-primary);
$global-font-color-secondary: var(--main-global-font-color-secondary);

$gradient-background: linear-gradient(
  0deg,
  rgba(37, 37, 41, 0.9976365546218487) 0%,
  rgba(114, 111, 126, 1) 100%
);

$background-grayscale: var(--main-background-grayscale);

$navigation-selector-background: var(--main-navigation-selector-background);
$navigation-selector-background-hover: var(--main-navigation-selector-background-hover);
$navigation-selector-background-active: var(--main-navigation-selector-background-active);
$navigation-selector-color: var(--main-navigation-selector-color);
$navigation-selector-color-hover: var(--main-navigation-selector-color-hover);
$navigation-selector-color-active: var(--main-navigation-selector-color-active);

$navigation-background-color: var(--main-navigation-background-color);
$navigation-background-main-color: var(--main-navigation-background-main-color);
$navigation-background-box-shadow: var(--main-navigation-box-shadow);
$navigation-font-color: var(--main-navigation-font-color);
$navigation-font-active-color: var(--main-navigation-font-active-color);
$navigation-font-hover-color: var(--main-navigation-font-hover-color);
$navigation-icon-color: var(--main-navigation-icon-color);
$navigation-icon-active-color: var(--main-navigation-icon-active-color);
$navigation-icon-hover-color: var(--main-navigation-icon-hover-color);

$content-background-color: var(--main-content-background-color);
$header-background-color: var(--main-header-background-color);

$scroll-bar-container-background-color: var(--main-scroll-bar-background-color);
$scroll-bar-handle-background-color: var(--main-scroll-bar-handle-background-color);
$scroll-bar-alter-container-background-color: var(--main-scroll-bar-alter-background-color);
$scroll-bar-alter-handle-background-color: var(--main-scroll-bar-alter-handle-background-color);

$logo-url: var(--main-logo-url);
$logo-divider-color: var(--main-logo-divider-color);

$work-board-logo-url: var(--work-board-logo-url);

$bar-chart-background-color: var(--main-bar-chart-background-color);
$bar-chart-border-color: var(--main-bar-chart-border-color);
$bar-chart-tick-text-color: var(--main-bar-chart-tick-text-color);
$bar-chart-divider-color: var(--main-bar-chart-divider-color);
$bar-chart-dash-line-color: var(--main-bar-chart-dash-line-color);
$bar-chart-circle-border-color: var(--main-bar-chart-circle-border-color);
$bar-chart-footer-border-color: var(--main-bar-chart-footer-border-color);

$balances-frame-color: var(--main-balances-frame-color);
$balances-label-color: var(--main-balances-label-color);
$balances-card-font-color: var(--main-balances-card-font-color);

$bots-widget-border-color: var(--main-bots-widget-border-color);
$bots-widget-background-color: var(--main-bots-widget-background-color);
$bots-widget-controls-title-color: var(--main-bots-widget-controls-title-color);
$bot-card-divider-color: var(--main-bot-card-divider-color);
$bot-card-time-frame-color: var(--main-bot-card-time-frame-color);
$bot-card-background-color: var(--main-bot-card-background-color);
$bot-card-font-main-color: var(--main-bot-card-font-main-color);
$bot-card-font-slave-color: var(--main-bot-card-font-slave-color);

$standard-options-controls-color: var(--main-standard-options-controls-color);
$standard-options-controls-active-color: var(--main-standard-options-controls-active-color);

$standard-options-controls-alter-color: var(--main-standard-options-controls-alter-color);
$standard-options-controls-alter-active-color: var(
  --main-standard-options-controls-alter-active-color
);

$theme-button-background-color: var(--main-theme-button-background-color);
$theme-button-border-color: var(--main-theme-button-border-color);
$theme-button-icon-color: var(--main-theme-button-icon-color);
$theme-button-icon-active-color: var(--main-theme-button-icon-active-color);
$theme-button-icon-active-background-color: var(--main-theme-button-icon-active-background-color);

$icon-color: var(--main-icon-color);
$icon-hover-color: var(--main-icon-hover-color);
$icon-active-color: var(--main-icon-active-color);

$table-widget-background-color: var(--main-table-widget-background-color);
$table-widget-border-color: var(--main-table-widget-border-color);

$donat-circle-color: var(--main-donat-circle-color);
$sorting-active-font-color: var(--main-sorting-font-color);

$dropdown-select-icon-color: var(--main-dropdown-select-icon-color);
$dropdown-select-background-color: var(--main-dropdown-select-background-color);
$dropdown-select-border-color: var(--main-dropdown-select-border-color);
$dropdown-select-value-color: var(--main-dropdown-select-value-color);
$dropdown-select-alter-value-color: var(--main-dropdown-select-alter-value-color);
$dropdown-select-alter-icon-color: var(--main-dropdown-select-alter-icon-color);
$dropdown-select-alter-background-color: var(--main-dropdown-select-alter-background-color);
$dropdown-select-alter-border-color: var(--main-dropdown-select-alter-border-color);

$add-button-label-color: var(--main-add-button-label-color);
$add-button-alter-label-color: var(--main-add-button-alter-label-color);

$info-label-color: var(--main-info-label-color);
$info-empty-state-text-color: var(--main-info-empty-state-text-color);
$info-vertical-divider: var(--main-info-vertical-divider);
$info-background-color: var(--main-info-background-color);
$info-border-color: var(--main-info-border-color);
$info-divider-color: var(--main-info-divider-color);

$global-negative-color: var(--main-negative-color);
$global-positive-color: var(--main-positive-color);

$global-smart-trade-bot-left-panel-background-color: var(
  --main-smart-trade-bot-left-panel-background-color
);

$global-smart-trade-bot-border-color: var(--main-smart-trade-bot-border-color);
$global-smart-trade-bot-background-color: var(--main-smart-trade-background-color);
$global-smart-trade-bot-tab-background-color: var(--main-smart-trade-bot-tab-background-color);
$global-smart-trade-bot-tab-border-color: var(--main-smart-trade-bot-tab-border-color);
$global-smart-trade-bot-glass-background-color: var(--main-smart-trade-bot-glass-background-color);
$global-smart-trade-bot-chart-background-color: var(--main-smart-trade-bot-chart-background-color);

$global-button-color-primary: var(--main-button-color-primary);
$global-button-color-hover-primary: var(--main-button-color-hover-primary);
$global-button-color-active-primary: var(--main-button-color-active-primary);
$global-button-background-color-primary: var(--main-button-background-color-primary);
$global-button-background-color-hover-primary: var(--main-button-background-color-hover-primary);
$global-button-background-color-active-primary: var(--main-button-background-color-active-primary);
$global-button-box-shadow-primary: var(--main-button-box-shadow-primary);
$global-button-box-shadow-hover-primary: var(--main-button-box-shadow-hover-primary);
$global-button-box-shadow-active-primary: var(--main-button-box-shadow-active-primary);
$global-button-border-color-primary: var(--main-button-border-color-primary);
$global-button-border-color-hover-primary: var(--main-button-border-color-hover-primary);
$global-button-border-color-active-primary: var(--main-button-border-color-active-primary);

$global-button-color-success: var(--main-button-color-success);
$global-button-color-hover-success: var(--main-button-color-hover-success);
$global-button-color-active-success: var(--main-button-color-active-success);
$global-button-background-color-success: var(--main-button-background-color-success);
$global-button-background-color-hover-success: var(--main-button-background-color-hover-success);
$global-button-background-color-active-success: var(--main-button-background-color-active-success);
$global-button-box-shadow-success: var(--main-button-box-shadow-success);
$global-button-box-shadow-hover-success: var(--main-button-box-shadow-hover-success);
$global-button-box-shadow-active-success: var(--main-button-box-shadow-active-success);
$global-button-border-color-success: var(--main-button-border-color-success);
$global-button-border-color-hover-success: var(--main-button-border-color-hover-success);
$global-button-border-color-active-success: var(--main-button-border-color-active-success);

$global-button-color-danger: var(--main-button-color-danger);
$global-button-color-hover-danger: var(--main-button-color-hover-danger);
$global-button-color-active-danger: var(--main-button-color-active-danger);
$global-button-background-color-danger: var(--main-button-background-color-danger);
$global-button-background-color-hover-danger: var(--main-button-background-color-hover-danger);
$global-button-background-color-active-danger: var(--main-button-background-color-active-danger);
$global-button-box-shadow-danger: var(--main-button-box-shadow-danger);
$global-button-box-shadow-hover-danger: var(--main-button-box-shadow-hover-danger);
$global-button-box-shadow-active-danger: var(--main-button-box-shadow-active-danger);
$global-button-border-color-danger: var(--main-button-border-color-danger);
$global-button-border-color-hover-danger: var(--main-button-border-color-hover-danger);
$global-button-border-color-active-danger: var(--main-button-border-color-active-danger);

$global-button-color-default: var(--main-button-color-default);
$global-button-color-hover-default: var(--main-button-color-hover-default);
$global-button-color-active-default: var(--main-button-color-active-default);
$global-button-background-color-default: var(--main-button-background-color-default);
$global-button-background-color-hover-default: var(--main-button-background-color-hover-default);
$global-button-background-color-active-default: var(--main-button-background-color-active-default);
$global-button-box-shadow-default: var(--main-button-box-shadow-default);
$global-button-box-shadow-hover-default: var(--main-button-box-shadow-hover-default);
$global-button-box-shadow-active-default: var(--main-button-box-shadow-active-default);
$global-button-border-color-default: var(--main-button-border-color-default);
$global-button-border-color-hover-default: var(--main-button-border-color-hover-default);
$global-button-border-color-active-default: var(--main-button-border-color-active-default);

$global-button-color-disabled: var(--main-button-color-disabled);
$global-button-background-color-disabled: var(--main-button-background-color-disabled);
$global-button-box-shadow-disabled: var(--main-button-box-shadow-disabled);
$global-button-border-color-disabled: var(--main-button-border-color-disabled);

$global-button-color-outline-disabled: var(--main-button-color-outline-disabled);
$global-button-background-color-outline-disabled: var(
  --main-button-background-color-outline-disabled
);
$global-button-box-shadow-outline-disabled: var(--main-button-box-shadow-outline-disabled);
$global-button-border-color-outline-disabled: var(--main-button-border-color-outline-disabled);

$global-button-color-outline-primary: var(--main-button-color-outline-primary);
$global-button-background-color-outline-primary: var(
  --main-button-background-color-outline-primary
);
$global-button-box-shadow-outline-primary: var(--main-button-box-shadow-outline-primary);
$global-button-border-color-outline-primary: var(--main-button-border-color-outline-primary);

$global-button-color-outline-success: var(--main-button-color-outline-success);
$global-button-background-color-outline-success: var(
  --main-button-background-color-outline-success
);
$global-button-box-shadow-outline-success: var(--main-button-box-shadow-outline-success);
$global-button-border-color-outline-success: var(--main-button-border-color-outline-success);

$global-button-color-outline-danger: var(--main-button-color-outline-danger);
$global-button-background-color-outline-danger: var(--main-button-background-color-outline-danger);
$global-button-box-shadow-outline-danger: var(--main-button-box-shadow-outline-danger);
$global-button-border-color-outline-danger: var(--main-button-border-color-outline-danger);

/* NEW theme vars */

$global-colors-background-main: var(--global-colors-background-main);
$global-colors-gradient: var(--global-colors-gradient);
$global-colors-background-panel: var(--global-colors-background-panel);

$global-colors-border-neutral-0: var(--global-colors-border-neutral-0);
$global-colors-border-neutral-1: var(--global-colors-border-neutral-1);
$global-colors-border-neutral-2: var(--global-colors-border-neutral-2);
$global-colors-border-neutral-3: var(--global-colors-border-neutral-3);
$global-colors-border-neutral-4: var(--global-colors-border-neutral-4); // New

$global-colors-font-0: var(--global-colors-font-0);
$global-colors-font-1: var(--global-colors-font-1);
$global-colors-font-2: var(--global-colors-font-2);
$global-colors-font-3: var(--global-colors-font-3);
$global-colors-font-4: var(--global-colors-font-4);

$global-colors-link-default: var(--global-colors-link-default);
$global-colors-link-hover: var(--global-colors-link-hover);
$global-colors-link-active: var(--global-colors-link-active);

$global-colors-table-row-even: var(--global-colors-table-row-even);

$global-colors-button-default: var(--global-colors-button-default);
$global-colors-button-hover: var(--global-colors-button-hover);
$global-colors-button-active: var(--global-colors-button-active);

$global-slider-track-background-color: var(--main-slider-track-background-color);
$global-slider-rail-background-color-disabled: var(--main-slider-rail-background-color-disabled);

$global-modal-border-color: var(--main-modal-border-color);

$global-custom-bots-tab-background-color-active: var(--main-custom-bot-tab-background-color-active);
$global-custom-bots-tab-background-color: var(--main-custom-bot-tab-background-color);
$global-custom-bots-tab-color: var(--main-custom-bot-tab-color);
$global-custom-bots-tab-pairs-color: var(--main-custom-bot-tab-pairs-color);

$global-colors-tab-active: var(--global-colors-tab-active);
$global-colors-background-tab-active: var(--global-colors-background-tab-active);
$global-colors-background-nft-active: var(--global-colors-background-nft-active);

$global-colors-background-ask-price: var(--global-colors-background-ask-price);
$global-colors-background-bid-price: var(--global-colors-background-bid-price);

/*
End theme variables
 */
