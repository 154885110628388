.my-bots-table-content,
.my-deals-table-content,
.best-multi-pair-table-content {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.my-bots-table-content-mobile,
.my-deals-table-content-mobile,
.best-multi-pair-table-content-mobile {
  display: none;

  @media screen and (max-width: 992px) {
    display: block;
  }
}

.custom-table-content {
  &-row {
    display: grid;
    grid-template-columns: minmax(0, 33%) minmax(0, 33%) minmax(0, 33%);
    grid-template-rows: fn-px2rem(56px);
    align-items: center;
    padding: 0 fn-px2rem(24px);
    border-top: 1px solid $global-colors-border-neutral-0;

    &.overall {
      display: flex;
      justify-content: space-between;
      height: fn-px2rem(40px);
    }

    &.multi-pair {
      grid-template-columns: minmax(0, 25%) minmax(0, 15%) minmax(0, 10%) minmax(0, 15%) minmax(0, 15%) minmax(0, 20%);
    }

    &.my-bots {
      grid-template-columns: minmax(0, 5%) minmax(0, 15%) minmax(0, 10%) minmax(0, 10%) minmax(0, 15%) minmax(0, 15%) minmax(0, 10%) minmax(0, 10%) minmax(0, 5%) minmax(0, 5%);
      grid-template-rows: fn-px2rem(73px);

      &.custom-table-content-body {
        grid-template-rows: fn-px2rem(89px);
      }
    }

    &.my-deals {
      grid-template-columns: minmax(0, 2.5%) minmax(0, 15%) minmax(0, 10%) minmax(0, 30%) minmax(0, 10%) minmax(0, 10%) minmax(0, 10%) minmax(0, 10%) minmax(0, 2.5%);
      grid-template-rows: fn-px2rem(73px);

      &:first-of-type {
        border-top: none;
      }

      &.custom-table-content-body {
        grid-template-rows: fn-px2rem(152px);
      }
    }
  }

  &-body {
    &:nth-of-type(odd) {
      background: $global-colors-table-row-even;
    }

    &:last-of-type {
      border-radius: 0 0 fn-px2rem(16px) fn-px2rem(16px);
    }
  }

  .standard-options-more {
    top: -3px;
  }
}

.my-bots-table-content-mobile,
.best-multi-pair-table-content-mobile {
  .custom-table-content-mobile {
    &-row {
      padding: fn-px2rem(22px) fn-px2rem(18px) fn-px2rem(19px) fn-px2rem(16px);
      border-top: 1px solid $global-colors-border-neutral-0;

      &:nth-of-type(even) {
        background: $global-colors-table-row-even;
      }

      &:last-of-type {
        border-radius: 0 0 fn-px2rem(8px) fn-px2rem(8px);
      }
    }

    &-cell {
      padding-bottom: fn-px2rem(10px);
      display: grid;
      grid-template-columns: minmax(0, 30%) minmax(0, 70%);
      grid-template-rows: auto;
    }
  }
}

.my-deals-table-content-mobile {
  .custom-table-content-mobile {
    &-cell {
      padding-bottom: fn-px2rem(20px);
      display: grid;
      grid-template-columns: minmax(0, 30%) minmax(0, 70%);
      grid-template-rows: auto;
    }
  }
}

.custom-table-content,
.custom-table-content-mobile {
  .short,
  .nonstop {
    border-radius: fn-px2rem(8px);
    padding: fn-px2rem(2px) fn-px2rem(4px);
  }

  .short {
    color: $global-negative-color;
    background: rgba(249, 57, 57, 0.1);
  }

  .nonstop {
    color: $global-positive-color;
    background: rgba(0, 168, 89, 0.1);
  }
}

.w-90 {
  width: 90%;
}

.pr-70 {
  padding-right: fn-px2rem(70px);
}