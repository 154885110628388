@import '~react-toggle/style';

.customized-toggle {
  width: fit-content;

  span {
    font-weight: 400;
    white-space: nowrap;
  }

  .react-toggle {
    position: relative;
    top: fn-px2rem(-3px);
    &.react-toggle--checked {
      .react-toggle-thumb {
        left: 15px;
      }
    }
  }

  .react-toggle, .react-toggle:hover, .react-toggle.react-toggle--focus {
    .react-toggle-track {
      background-color: rgba(255, 255, 255, 0.8) !important;
      border: 1px solid rgba(165, 171, 188, 0.8);
      width: 32px;
      height: 18px;
      border-radius: 18px;
    }
  }

  .react-toggle {
    &:hover,  &.react-toggle--checked {
      .react-toggle-track {
        background-color: rgba(49, 85, 198, 0.15) !important;
      }
    }
  }


  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .react-toggle--checked, .react-toggle, .react-toggle:hover, .react-toggle.react-toggle--focus {

    .react-toggle-thumb {
      height: 16px;
      width: 16px;
      background-color: rgba(49, 85, 198, 0.85);
      border-color: rgba(49, 85, 198, 0.85);
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    }
  }
}