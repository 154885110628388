@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}


$dropdown-select-menu-font-color: rgba(112, 121, 145, 1);
$dropdown-select-menu-font-active-color: rgba(28, 82, 254, 1);

.dropdown-selector {
  &.disabled {
    cursor: not-allowed;
  }
  &-toggle {
    &:before, &:after {
      display: none;
    }
    &.btn-link {
      color: inherit;
      text-decoration: none;
    }
    &[disabled], &.disabled, &:disabled {
      pointer-events: none;
      opacity: .5;
    }
  }

  .btn-check:focus + .btn, .btn:focus {
    box-shadow: none;
  }
  .dropdown-selector-icon {
    font-size: fn-px2rem(10px);
    padding: fn-px2rem(3px) fn-px2rem(5px) fn-px2rem(2px) fn-px2rem(5px);
    text-align: center;
    color: $dropdown-select-icon-color;
    border: 1px solid $dropdown-select-border-color;
    border-radius: fn-px2rem(4px);
    background-color: transparent;
    overflow: hidden;
    display: inline-block;
    transition: $global-transition;
    height: fit-content;
    width: fit-content;

    &.open, &:hover {
      background-color: $dropdown-select-background-color;
    }

    &:before {
      position: relative;
    }
  }
  .dropdown-selector-value {
    color: $dropdown-select-value-color;
  }
  .dropdown-menu-right {
    left: fn-px2rem(-4px) !important;
  }

  &-dark {
    .dropdown-selector-icon {
      color: $dropdown-select-alter-icon-color !important;
      border: 1px solid $dropdown-select-alter-border-color !important;

      &.open, &:hover {
        background-color: $dropdown-select-alter-background-color !important;
      }

      &:before {
        position: relative;
      }
    }
    .dropdown-selector-value {
      color: $dropdown-select-alter-value-color !important;
    }
  }
  &, &-dark {
    .dropdown-menu {
      text-align: left;
      min-width: fn-px2rem(97px);

      .dropdown-item {
        padding-left: fn-px2rem(24px);
        color: $dropdown-select-menu-font-color;
        position: relative;
        transition: $global-transition;

        &:hover, &.active {
          color: $dropdown-select-menu-font-active-color;
          background-color: transparent;
        }
        &.active {
          &:before {
            position: absolute;
            content: ' ';
            height: fn-px2rem(8px);
            width: fn-px2rem(8px);
            border-radius: 50%;
            background-color: $dropdown-select-menu-font-active-color;
            top: fn-px2rem(9px);
            left: fn-px2rem(6px);

          }
        }
      }
    }
  }
}
