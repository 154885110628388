@use 'sass:list';
@use 'sass:math';

@mixin table-non-visible-columns($columnBorderLess, $removedColumn, $font, $screen) {
  .main-container.screen-#{$screen} {
    .dashboard {
      &-widget {
        .table-widget-container {
          .table {
            thead {
              tr {
                th:nth-of-type(#{nth($columnBorderLess, 1)}) {
                  border-right: none;
                }
              }
            }

            tbody {
              tr {
                td:nth-of-type(#{nth($columnBorderLess, 1)}) {
                  border-right: none;
                }
              }
            }

            thead {
              tr {
                @for $i from 0 to length($removedColumn) {
                  th:nth-of-type(#{nth($removedColumn, $i + 1)}) {
                    display: none;
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  font-size: $font * 1px !important;
                }

                @for $i from 0 to length($removedColumn) {
                  td:nth-of-type(#{nth($removedColumn, $i + 1)}) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin custom-scroll-handle($scroll-bar-color, $scroll-bar-handle-color, $positioning) {
  .rcs-inner-container {
    padding-right: fn-px2rem(20px);
  }
  .rcs-outer-container {
    height: 100%;
    overflow-y: hidden;
    position: relative;
    cursor: pointer;

    .rcs-positioning {
      position: absolute;
      height: 100%;
      right: $positioning * 1px !important;
    }

    .rcs-custom-scrollbar {
      opacity: 1;
      width: fn-px2rem(8px);
      background-color: $scroll-bar-color;
      border-radius: fn-px2rem(4px);

      .rcs-custom-scroll-handle {
        height: fn-px2rem(122px);

        .rcs-inner-handle {
          background-color: $scroll-bar-handle-color;
          border-radius: fn-px2rem(4px);
          width: fn-px2rem(4px);
          margin-left: fn-px2rem(2px);
        }
      }
    }
  }
}

@mixin fa-icon-transform-color($icon, $size) {
  &-fa-icon {
    text-decoration: none;
    color: $icon-color;
    cursor: pointer;
    &:before {
      font-family: $global-fa-font;
      content: $icon;
      font-size: $size;
      position: relative;
      font-weight: $global-fa-font-thin;
      color: inherit;
    }
    &:hover,
    &:focus {
      color: $icon-hover-color;
      &:before {
        font-weight: $global-fa-font-solid;
      }
    }
    &:active,
    &.active {
      color: $icon-active-color;
      &:before {
        font-weight: $global-fa-font-thin;
      }
    }
  }
}

@mixin row-ratio($value) {
  $width: math.percentage($value);
  .dashboard {
    &-widget {
      &:last-child {
        width: 100 - $width;
      }

      &:first-child {
        width: $width;
        max-width: $width;
      }
    }
  }
}

@mixin font-sizes-generator($start, $end, $nth) {
  @for $i from $start to $end + 1 {
    @if ($i % $nth == 0) {
      &-#{$i} {
        font-size: fn-px2rem($i * 1px) !important;
      }
    }
  }
}

@mixin font-weight-generator($list) {
  @each $name, $value in $list {
    &-#{$name} {
      font-weight: $value;
    }
  }
}
