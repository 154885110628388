@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.add-exchange.modal {
  .modal-content {
    background: $global-colors-background-panel;
    color: $global-font-color-primary;

    input[type='text'],
    input[type='password'],
    select {
      background-color: $global-colors-background-panel !important;
      border: 1px solid $global-colors-border-neutral-0;
      color: $global-font-color-secondary !important;
    }
    .form-control {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
      border-right: none;
      background: inherit;
      color: inherit;

      &:focus {
        border-color: #ced4da;
        box-shadow: none !important;
        z-index: 0;
      }
    }
  }
  .modal-header,
  .modal-footer {
    border: none;
    text-align: center;
  }

  .form-label {
    font-size: fn-px2rem(12px);
    color: $global-font-color-secondary;
    margin-bottom: 0.25rem;
  }
  .cancel-icon {
    position: absolute;
    right: fn-px2rem(4.5px);
    top: fn-px2rem(4.5px);
    color: $global-font-color-secondary;
    transition: $global-transition;

    &:hover {
      color: rgba(29, 83, 255, 1);
    }
  }
}
.chain-modal-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  .chain-modal-icon {
    display: flex;
    align-items: center;
    width: 141px;
    height: 32px;
    left: 687px;
    top: 607px;
    background: #f2f2f2;
    border: 1px solid #e8e8e8;
    border-radius: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #707991;
    margin: 7px;
    gap: 5px;
    padding: 7px;
    cursor: pointer;
    &:hover {
      background-color: #61646f;
      color: white;
    }
  }
}
.ip-addres {
  color: #f0bb00;
}

.button {
  margin: 10px;
}
