@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}



.theme-group-button {
  height: fn-px2rem(25px);
  width: fn-px2rem(46px);
  background-color: $theme-button-background-color;
  border-radius: fn-px2rem(25px);
  border: 1px solid $logo-divider-color;
  position: relative;
  top: fn-px2rem(-1px);

  i {
    height: fn-px2rem(21px);
    width: fn-px2rem(21px);
    font-size: fn-px2rem(10px);
    color: $theme-button-icon-color;
    background-color: $theme-button-background-color;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: 21px;


    &.active {
      background-color: $theme-button-icon-active-background-color;
      color: $theme-button-icon-active-color;
    }
  }
}