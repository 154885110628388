@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.standard-options {
  color: $global-font-color-secondary;
  width: min-content;
  &-refresh {
    transition: $global-transition;
    cursor: pointer;
    &:hover {
      color: $standard-options-controls-active-color;
    }
  }
  &-expand {
    color: inherit;
    position: relative;
    cursor: pointer;
    transition: $global-transition;
    &:hover {
      color: $standard-options-controls-active-color;
    }
  }
  &-more {
    position: relative;
    top: fn-px2rem(3px);
    .dropdown-selector-toggle {
      position: relative;
      transition: $global-transition;
      &:hover {
        color: $standard-options-controls-active-color;
      }
    }
  }

  &-dark {
    color: $standard-options-controls-alter-color;
    .standard-options-refresh {
      color: $standard-options-controls-alter-color;
      cursor: pointer;
      &:hover {
        color: $standard-options-controls-alter-active-color !important;
      }
    }
    .standard-options-expand {
      &-icon {
        color: $standard-options-controls-alter-color;
        &:hover {
          color: $standard-options-controls-alter-active-color !important;
        }
      }
    }
    .standard-options-more {
      color: $standard-options-controls-alter-color !important;
      position: relative;
      top: fn-px2rem(3px);
      .dropdown-selector-toggle {
        transition: $global-transition;
        &:hover {
          color: $standard-options-controls-alter-active-color !important;
        }
      }
    }
  }
 }