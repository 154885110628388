@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$warning-color: rgba(247, 147, 26, 1);

.hodl-bot-layout {
  min-height: 100vh;

  .warning-btn {
    background-color: $warning-color;
    color: white;
  }

  .danger-btn {
    background-color: $global-button-background-color-danger;
  }

  .color-font-0 {
    color: $global-colors-font-0;
  }

  .color-font-2 {
    color: $global-colors-font-2;
  }

  .color-font-3 {
    color: $global-colors-font-3;
  }

  .main-content-layout {
    display: flex;
    flex-direction: row;
    gap: fn-px2rem(25px);

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .bot-settings-content {
    width: 100%;
    max-width: 40%;

    @media screen and (max-width: 1024px) {
      max-width: unset;
    }

    .form-item {
      &.inline {
        display: grid;
        grid-template-columns: minmax(0, 50%) minmax(0, 50%);
        grid-template-rows: auto;
        grid-column-gap: fn-px2rem(16px);

        @media screen and (max-width: 1024px) {
          grid-template-columns: minmax(0, 100%);
        }
      }

      &.inline-3 {
        display: flex;
        gap: fn-px2rem(16px);
        justify-content: space-between;

        @media screen and (max-width: 1400px) {
          flex-direction: column;
          gap: 0;
        }
      }

      .calendar-buttons {
        .label {
          visibility: hidden;
        }

        button {
          color: $global-colors-button-default;
          border-color: $global-colors-button-default;
          height: fn-px2rem(38px);

          @media screen and (max-width: 1400px) {
            width: 100%;
          }
        }

        .today {
          padding: fn-px2rem(8px) fn-px2rem(44px);
        }

        .tomorrow {
          padding: fn-px2rem(8px) fn-px2rem(34px);
        }
      }

      .css-1auj858-control,
      .input-group {
        background: $global-colors-background-panel;
        color: $global-font-color-primary;
      }

      .input-group {
        height: fn-px2rem(38px);

        .form-control {
          border-right: none;
          background: inherit;
          color: inherit;
          font-size: fn-px2rem(14px);

          &:focus {
            border-color: #ced4da;
            box-shadow: none !important;
            z-index: 0;
          }
        }

        .right-input-button,
        .left-input-button {
          background: inherit;
          color: $global-colors-font-0;
          font-weight: 600;
          font-size: fn-px2rem(14px);
        }

        .right-input-button {
          border-top-right-radius: fn-px2rem(4px);
          border-bottom-right-radius: fn-px2rem(4px);
          border-left: none;
        }

        .left-input-button {
          border-top-left-radius: fn-px2rem(4px);
          border-bottom-left-radius: fn-px2rem(4px);
          border-right: none;
          padding: fn-px2rem(6px) fn-px2rem(6px) fn-px2rem(6px) fn-px2rem(12px);
        }

        &.with-left-button {
          .form-control {
            border-left: none;
          }
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
          cursor: pointer;
        }
      }

      &-first {
        @media screen and (max-width: 1024px) {
          padding-bottom: fn-px2rem(16px);
        }
      }

      .btn-custom.btn.btn-success {
        &:disabled {
          color: $global-button-color-success;
          background: $global-button-background-color-success;
        }
      }
    }

    .alert-item {
      background: $global-colors-background-main;
      border: 1px solid $global-colors-border-neutral-0;
      min-height: fn-px2rem(76px);
      border-radius: fn-px2rem(4px);
      padding: fn-px2rem(10px);
    }

    &.bot-running {
      color: $global-colors-font-0;

      .running {
        .circle {
          background: $global-positive-color;
          width: fn-px2rem(10px);
          height: fn-px2rem(10px);
          border-radius: 50%;
          display: inline-block;
          margin-right: fn-px2rem(10px);
        }
      }

      .id {
        color: $bot-card-font-main-color;
      }

      .light-font-color {
        color: $global-colors-font-2;
      }

      .positive-font-color {
        color: $global-positive-color;
      }

      .warning-wrapper {
        color: $warning-color;
        border-bottom: 1px dashed $warning-color;
        font-weight: 500;
      }

      .submit-button {
        display: flex;
        justify-content: space-between;

        button {
          width: 48%;
        }

        .manage {
          background-color: $global-colors-button-default;
          color: $global-button-color-primary;
        }
      }
    }
  }

  .bot-settings-content,
  .how-it-works-content,
  .events-content {
    background: $global-colors-background-panel;
    padding: fn-px2rem(24px);
    border: 1px solid $global-colors-border-neutral-0;
    border-radius: fn-px2rem(16px);
  }

  .how-it-works-content {
    width: 100%;
    max-width: 60%;

    @media screen and (max-width: 1024px) {
      max-width: unset;
    }
  }

  .collapse-header {
    display: grid;
    grid-template-columns: minmax(0, 60%) auto minmax(0, fn-px2rem(20px));
    grid-template-rows: auto;
  }

  .collapse-content {
    color: $global-colors-font-0;

    &-wrapper {
      display: flex;
      padding-top: fn-px2rem(16px);

      @media screen and (max-width: 1024px) {
        display: unset;
      }

      &-right {
        display: flex;
        align-items: center;

        @media screen and (max-width: 1024px) {
          justify-content: center;
        }
      }
    }
  }

  .manage-bot-cards-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, fn-px2rem(326px));
    grid-gap: fn-px2rem(25px);
    justify-content: space-between;

    @media screen and (max-width: 1400px) {
      grid-template-columns: minmax(0, 100%);
      grid-gap: fn-px2rem(10px);
    }

    .card-item {
      background: $global-colors-background-main;
      border: 1px solid $global-colors-border-neutral-0;
      min-height: fn-px2rem(131px);
      border-radius: fn-px2rem(8px);
      padding: fn-px2rem(10px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .smart-trade-wrapper {
    grid-row-gap: fn-px2rem(8px);

    .css-1auj858-control,
    .css-1cim2qq-control {
      background: $global-colors-background-panel;
      color: $global-font-color-primary;
    }
  }
}

.bot-created.modal {
  .modal-content {
    background: $global-colors-background-main;
    color: $global-font-color-primary;
    border-radius: fn-px2rem(16px);

    .modal-header {
      border: none;
      justify-content: right;
      padding-bottom: 0;

      .icon-cancel {
        color: $global-colors-font-0;
      }
    }

    .modal-body {
      padding-top: 0;

      .submit-button {
        display: flex;
        justify-content: space-between;

        button {
          width: 48%;
        }

        .start {
          background-color: $global-colors-button-default;
          color: $global-button-color-primary;
        }

        .not-now {
          background-color: $global-colors-background-main;
          color: $global-colors-button-default;
          border-color: $global-colors-button-default;
        }
      }
    }
  }
}