@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$first-row-height: fn-px2rem(35px);
$second-row-height: fn-px2rem(12px);

.signal {
  &-card {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 77px 41px 38px;
    border-radius: fn-px2rem(5px);
    border: 2px solid rgba(112, 121, 145, .25);
    color: $global-font-color-primary;
    max-width: 100%;

    .-coin-grid {
      display: grid;
      grid-template-columns: 25px auto;
      grid-template-rows: 1fr;
      justify-items: start;
      align-items: center;
      grid-column-gap: fn-px2rem(3px);
    }

    .-column {

      &-1 {
        justify-items: start;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: $first-row-height $second-row-height;
        width: 100%;
        height: 100%;

        .signal-coin-label {
          text-indent: fn-px2rem(2px);
        }
      }

      &-2 {
        justify-items: center;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: $first-row-height $second-row-height;
        width: 100%;
        height: 100%;
      }

      &-3 {
        justify-items: end;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: $first-row-height $second-row-height;
        width: 100%;
        height: 100%;
      }
    }
  }
}