.nav-tabs {
  background: transparent;
  margin-bottom: 0 !important;
  border: none;

  .nav-link {
    background-color: transparent;
    border: none;
    margin-bottom: 0;
    color: $navigation-font-color;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    font-size: fn-px2rem(14px);
    &:hover {
      color: $navigation-font-hover-color;
    }
    &.active {
      background-color: $global-smart-trade-bot-tab-background-color;
      color: $navigation-font-active-color;
    }
  }
}
.tab-content {
  height: calc(100% - 38px);
  background: $global-smart-trade-bot-tab-background-color;
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
  border-top-right-radius: 0;
}