.wrapper {

  --main-positive-color: rgba(0, 168, 89, 1);
  --main-negative-color: rgba(249, 57, 57, 1);

  &.day-theme {
    $background-gradient: linear-gradient(180deg, rgba(254, 253, 255, 1) 24.65%, rgba(234, 231, 238, 1) 89.43%);
    $font-color-primary: (43, 26, 61, 1);
    $font-color-secondary: (43, 26, 61, .8);

    $font-color-hover: (43, 26, 50, 1);
    $font-color-active: (29, 83, 255, 1);

    $font-hover: rgb(43, 26, 50);
    $font-active: rgb(29, 83, 255);

    --main-global-font-color-primary: rgba(#{$font-color-primary});
    --main-global-font-color-secondary: rgba(#{$font-color-secondary});

    --main-background-grayscale: grayscale(0%);

    --main-navigation-selector-background: transparent;
    --main-navigation-selector-background-hover: rgba(85, 126, 255, .1);
    --main-navigation-selector-background-active: rgba(85, 126, 255, .1);
    --main-navigation-selector-color: rgba(#{$font-color-primary});
    --main-navigation-selector-color-hover: rgba(85, 126, 255, 1);
    --main-navigation-selector-color-active: rgba(29, 83, 255, 1);


    --main-navigation-background-color: #{$background-gradient};
    --main-navigation-background-main-color: rgba(255, 255, 255, 1);
    --main-navigation-box-shadow: inset 0px -1px 0px rgba(131, 131, 131, 0.28);
    --main-navigation-font-color: rgba(112, 121, 145, 1);
    --main-navigation-font-hover-color: rgba(85, 126, 255, 1);
    --main-navigation-font-active-color: rgba(49, 83, 255, 1);
    --main-navigation-icon-color: rgba(112, 121, 145, 1);
    --main-navigation-icon-hover-color: rgba(43, 26, 50, 1);
    --main-navigation-icon-active-color: rgba(29, 83, 255, 1);

    --main-header-background-color: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F2F3FC;

    --main-content-background-color: rgba(255, 255, 255, 1);

    --main-scroll-bar-background-color: rgba(255, 255, 255, 1);
    --main-scroll-bar-handle-background-color: rgba(112, 121, 145, 0.5);
    --main-scroll-bar-alter-background-color: rgba(255, 255, 255, 0.1);
    --main-scroll-bar-alter-handle-background-color: rgba(255, 255, 255, 0.5);

    --main-logo-url: url("/public/images/static/logo/light-theme-logo/light-logo-vertical.svg");
    --main-logo-divider-color: rgba(43, 26, 61, 0.1);

    --work-board-logo-url:  url("/public/images/work-board/light-theme-logo/light-vertical.svg");

    --main-bar-chart-background-color: #{$background-gradient};
    --main-bar-chart-border-color: #E8E8E8;
    --main-bar-chart-tick-text-color: rgba(43, 26, 61, 0.8);
    --main-bar-chart-divider-color: rgba(155, 163, 181, 1);
    --main-bar-chart-dash-line-color: rgba(94, 94, 94, 0.2);
    --main-bar-chart-circle-border-color: rgba(196, 200, 208, 1);
    --main-bar-chart-footer-border-color: rgba(112, 121, 145, .25);

    --main-balances-frame-color: rgba(184, 184, 184, .5);
    --main-balances-label-color: rgba(112, 121, 145, 1);
    --main-balances-card-font-color: rgba(43, 26, 61, 1);

    --main-bots-widget-border-color: #E8E8E8;
    --main-bots-widget-background-color: #{$background-gradient};
    --main-bots-widget-controls-title-color: rgba(43, 26, 61, 1);
    --main-bot-card-divider-color: rgba(112, 121, 145, .25);
    --main-bot-card-time-frame-color: rgba(43, 26, 61, .2);
    --main-bot-card-background-color: rgba(255, 255, 248, .51);
    --main-bot-card-font-main-color: rgba(43, 26, 61, 1);
    --main-bot-card-font-slave-color: rgba(43, 26, 61, .8);

    --main-standard-options-controls-color: rgba(43, 26, 61, 0.5);
    --main-standard-options-controls-active-color: rgba(29, 83, 255, 1);

    --main-standard-options-controls-alter-color: rgba(255, 255, 255, 0.7);
    --main-standard-options-controls-alter-active-color: rgba(255, 255, 255, 1);

    --main-theme-button-background-color: rgba(255, 255,255, 1);
    --main-theme-button-border-color: rgba(212, 212, 212, 0);
    --main-theme-button-icon-color: rgba(112, 121, 145, 1);
    --main-theme-button-icon-active-color: rgba(255, 255, 255, 1);
    --main-theme-button-icon-active-background-color: rgba(29, 83, 255, 1);

    --main-icon-color: rgba(112, 121, 145, 1);
    --main-icon-hover-color: rgba(29, 83, 255, 1);
    --main-icon-active-color: rgba(29, 83, 255, 1);

    --main-table-widget-background-color: #{$background-gradient};
    --main-table-widget-border-color: #E8E8E8;

    --main-donat-circle-color: rgba(112, 121, 145, 0.1);
    --main-sorting-font-color: rgba(255, 255, 255, 1);


    --main-dropdown-select-icon-color: rgba(43, 26, 61, 0.5);
    --main-dropdown-select-background-color: rgba(255, 255, 255, 1);
    --main-dropdown-select-border-color: rgba(43, 26, 61, 1);
    --main-dropdown-select-value-color: rgba(43, 26, 61, .8);

    --main-dropdown-select-alter-value-color: rgba(255, 255, 255, .8);
    --main-dropdown-select-alter-icon-color: rgba(255, 255, 255, 1);
    --main-dropdown-select-alter-background-color: rgba(255, 255, 255, .6);
    --main-dropdown-select-alter-border-color: rgba(255, 255, 255, 1);

    --main-add-button-label-color: rgba(43, 26, 61, .8);
    --main-add-button-alter-label-color: rgba(211, 211, 212, 1);

    --main-info-label-color: rgba(43, 26, 61, 1);
    --main-info-empty-state-text-color: rgba(112, 121, 145, 1);
    --main-info-vertical-divider: rgba(112, 121, 145, .25);
    --main-info-background-color: #{$background-gradient};
    --main-info-border-color: #E8E8E8;
    --main-info-divider-color: rgba(112, 121, 145, .25);

    --main-smart-trade-bot-left-panel-background-color: rgba(234, 239, 242, 1);

    --main-smart-trade-background-color: rgb(234, 239, 242, 0.85);
    --main-smart-trade-bot-border-color: rgba(112, 121, 145, 0.25);
    --main-smart-trade-bot-tab-border-color: rgba(112, 121, 145, 0);
    --main-smart-trade-bot-tab-background-color: rgba(227, 231, 237, .85);
    --main-smart-trade-bot-glass-background-color: rgba(255, 255, 255, 1);
    --main-smart-trade-bot-chart-background-color: rgba(255, 255, 255, 1);

    --main-button-color-primary: rgba(255, 255, 255, 1);
    --main-button-color-hover-primary: rgba(255, 255, 255, 1);
    --main-button-color-active-primary: rgba(255, 255, 255, 1);

    --main-button-background-color-primary: rgba(29, 83, 255, 1);
    --main-button-background-color-hover-primary: rgba(85, 126, 255, 1);
    --main-button-background-color-active-primary: rgba(20, 51, 149, 1);

    --main-button-box-shadow-primary: none;
    --main-button-box-shadow-hover-primary: 0px 3px 2px rgba(20, 69, 226, 0.32);
    --main-button-box-shadow-active-primary: none;

    --main-button-border-color-primary: rgba(29, 83, 255, 1);
    --main-button-border-color-hover-primary: rgba(85, 126, 255, 1);
    --main-button-border-color-active-primary: rgba(20, 51, 149, 1);

    --main-button-color-success: rgba(255, 255, 255, 1);
    --main-button-color-hover-success: rgba(255, 255, 255, 1);
    --main-button-color-active-success: rgba(255, 255, 255, 1);

    --main-button-background-color-success: rgba(0, 168, 89, 1);
    --main-button-background-color-hover-success: rgba(70, 205, 142, 1);
    --main-button-background-color-active-success: rgba(7, 133, 73, 1);

    --main-button-box-shadow-success: none;
    --main-button-box-shadow-hover-success: 0px 5px 8px -1px rgba(0, 168, 89, 0.32);
    --main-button-box-shadow-active-success: none;

    --main-button-border-color-success: rgba(0, 168, 89, 1);
    --main-button-border-color-hover-success: rgba(70, 205, 142, 1);
    --main-button-border-color-active-success: rgba(7, 133, 73, 1);

    --main-button-color-danger: rgba(255, 255, 255, 1);
    --main-button-color-hover-danger: rgba(255, 255, 255, 1);
    --main-button-color-active-danger: rgba(255, 255, 255, 1);

    --main-button-background-color-danger: rgba(249, 57, 57, 1);
    --main-button-background-color-hover-danger: rgba(249, 57, 57, 0.32);
    --main-button-background-color-active-danger: rgba(191, 53, 53, 1);

    --main-button-box-shadow-danger: none;
    --main-button-box-shadow-hover-danger: 0px 5px 8px -1px rgba(249, 57, 57, 0.32);
    --main-button-box-shadow-active-danger: none;

    --main-button-border-color-danger: rgba(249, 57, 57, 1);
    --main-button-border-color-hover-danger: rgba(249, 57, 57, 0.32);
    --main-button-border-color-active-danger: rgba(191, 53, 53, 1);

    --main-button-color-default: rgba(112, 121, 145, 1);
    --main-button-color-hover-default: rgba(112, 121, 145, 1);
    --main-button-color-active-default: rgba(112, 121, 145, 1);

    --main-button-background-color-default: rgba(255, 255, 255, 1);
    --main-button-background-color-hover-default: rgba(112, 121, 145, 0.1);
    --main-button-background-color-active-default: rgba(112, 121, 145, 0.3);

    --main-button-box-shadow-default: none;
    --main-button-box-shadow-hover-default: none;
    --main-button-box-shadow-active-default: none;

    --main-button-border-color-default: #707991;
    --main-button-border-color-hover-default: #707991;
    --main-button-border-color-active-default: #707991;

    --main-button-color-disabled: rgba(112, 121, 145, 1);
    --main-button-background-color-disabled: rgba(234, 239, 242, 1);
    --main-button-box-shadow-disabled: none;
    --main-button-border-color-disabled: rgba(234, 239, 242, 1);

    --main-button-color-outline-primary: rgba(29, 83, 255, 1);
    --main-button-background-color-outline-primary: rgba(0, 0, 0, 0);
    --main-button-box-shadow-outline-primary: none;
    --main-button-border-color-outline-primary: rgba(29, 83, 255, 1);

    --main-button-color-outline-success: rgba(0, 168, 89, 1);
    --main-button-background-color-outline-success: rgba(0, 0, 0, 0);
    --main-button-box-shadow-outline-success: none;
    --main-button-border-color-outline-success: rgba(0, 168, 89, 1);

    --main-button-color-outline-danger: rgba(249, 57, 57, 1);
    --main-button-background-color-outline-danger: rgba(0, 0, 0, 0);
    --main-button-box-shadow-outline-danger: none;
    --main-button-border-color-outline-danger: rgba(249, 57, 57, 1);

    --main-button-color-outline-disabled: rgba(112, 121, 145, 0.5);
    --main-button-background-color-outline-disabled: rgba(112, 121, 145, 0);
    --main-button-box-shadow-outline-disabled: none;
    --main-button-border-color-outline-disabled: rgba(112, 121, 145, 0.5);

    --main-slider-track-background-color: #E6E6E6;
    --main-slider-rail-background-color-disabled: rgba(217, 217, 217, 1);

    --main-modal-border-color: rgba(0, 0, 0, .1);

    --main-custom-bot-tab-background-color-active: rgba(255, 255, 255, 255);
    --main-custom-bot-tab-background-color: rgba(232, 232, 232, 1);
    --main-custom-bot-tab-color: rgba(112, 121, 145, 1);
    --main-custom-bot-tab-pairs-color: rgba(112, 121, 145, 1);
  }

  &.night-theme {
    $background-gradient: linear-gradient(0deg, rgba(37,37,41,0.9976365546218487) 0%, rgba(114,111,126,1) 100%);
    --main-global-font-color-primary: rgba(255, 255, 255, 0.8);
    --main-global-font-color-secondary: rgba(211, 211, 212, 1);

    --main-background-grayscale: grayscale(100%);

    --main-navigation-selector-background: transparent;
    --main-navigation-selector-background-hover: rgba(255, 255, 255, 0.1);
    --main-navigation-selector-background-active: rgba(255, 255, 255, 0.2);
    --main-navigation-selector-color: rgba(255, 255, 255, 0.7);
    --main-navigation-selector-color-hover: rgba(255, 255, 255, 0.7);
    --main-navigation-selector-color-active: rgba(255, 255, 255, 0.7);

    --main-navigation-background-color: rgba(39, 37, 48, 1);
    --main-navigation-background-main-color: rgba(39, 37, 48, 1);
    --main-navigation-box-shadow:  0px -1px 0px #373443;
    --main-navigation-font-color: rgba(255, 255, 255, 0.3);
    --main-navigation-font-active-color: rgba(255, 255, 255, 1);
    --main-navigation-font-hover-color: rgba(255, 255, 255, 0.7);
    --main-navigation-icon-color: rgba(255, 255, 255, 0.3);
    --main-navigation-icon-active-color: rgba(255, 255, 255, 1);
    --main-navigation-icon-hover-color: rgba(255, 255, 255, 0.7);

    --main-header-background-color: transparent;

    // --main-content-background-color: rgba(48, 47, 53, 1);
    --main-content-background-color: #0F131C;

    --main-scroll-bar-alter-background-color: rgba(255, 255, 255, 0.1);
    --main-scroll-bar-alter-handle-background-color: rgba(255, 255, 255, 0.5);
    --main-scroll-bar-background-color: rgba(255, 255, 255, 0.1);
    --main-scroll-bar-handle-background-color: rgba(255, 255, 255, 0.5);

    --main-logo-url:  url("/public/images/static/logo/dark-theme-logo/dark-logo-vertical.svg");
    --main-logo-divider-color: rgba(255, 255, 255, 0.1);

    --work-board-logo-url:  url("/public/images/work-board/dark-theme-logo/dark-vertical.svg");

    --main-bar-chart-background-color: #{$background-gradient};
    --main-bar-chart-border-color: rgba(48, 47, 53, 1);
    --main-bar-chart-tick-text-color: rgba(255, 255, 255, 0.8);
    --main-bar-chart-divider-color: rgba(155, 163, 181, 1);
    --main-bar-chart-dash-line-color: rgba(177, 179, 189, 0.2);
    --main-bar-chart-circle-border-color: rgba(196, 200, 208, 1);
    --main-bar-chart-footer-border-color: rgba(112, 121, 145, .25);

    --main-balances-frame-color: rgba(112, 112, 112, 1);
    --main-balances-label-color: rgba(255, 255, 255, 0.8);
    --main-balances-card-font-color: rgba(255, 255, 255, .8);

    --main-bots-widget-border-color: rgba(255, 255, 255, .2);
    --main-bots-widget-background-color: transparent;
    --main-bots-widget-controls-title-color: rgba(255, 255, 255, .8);
    --main-bot-card-divider-color: rgba(112, 121, 145, .25);
    --main-bot-card-time-frame-color: rgba(43, 26, 61, .2);
    --main-bot-card-background-color: #{$background-gradient};
    --main-bot-card-font-main-color: rgba(255, 255, 255, .8);
    --main-bot-card-font-slave-color: rgba(255, 255, 255, .8);

    --main-standard-options-controls-color: rgba(255, 255, 255, 0.5);
    --main-standard-options-controls-active-color: rgba(255, 255, 255, 1);

    --main-standard-options-controls-alter-color: rgba(255, 255, 255, 0.5);
    --main-standard-options-controls-alter-active-color: rgba(255, 255, 255, 1);

    --main-theme-button-background-color: transparent;
    --main-theme-button-border-color: rgba(212, 212, 212, .25);
    --main-theme-button-icon-color: rgba(177, 177, 177, 1);
    --main-theme-button-icon-active-color: rgba(210, 210, 210, 1);
    --main-theme-button-icon-active-background-color: rgba(29, 83, 255, 1);

    --main-icon-color: rgba(255, 255, 255, 0.3);
    --main-icon-hover-color: rgba(255, 255, 255, 1);
    --main-icon-active-color: rgba(255, 255, 255, 1);

    --main-table-widget-background-color: #{$background-gradient};
    --main-table-widget-border-color: rgba(48, 47, 53, 1);
    --main-donat-circle-color: #49484D;
    --main-sorting-font-color: rgba(43, 26, 61, 1);



    --main-dropdown-select-icon-color: rgba(255, 255, 255, 1);
    --main-dropdown-select-background-color: rgba(255, 255, 255, .6);
    --main-dropdown-select-border-color: rgba(255, 255, 255, 1);
    --main-dropdown-select-value-color: rgba(255, 255, 255, .8);

    --main-dropdown-select-alter-value-color: rgba(255, 255, 255, .8);
    --main-dropdown-select-alter-icon-color: rgba(255, 255, 255, 1);
    --main-dropdown-select-alter-background-color: rgba(255, 255, 255, .6);
    --main-dropdown-select-alter-border-color: rgba(255, 255, 255, 1);

    --main-add-button-label-color: rgba(211, 211, 211, 1);
    --main-add-button-alter-label-color: rgba(211, 211, 212, 1);

    --main-info-label-color: rgba(255, 255, 255, .8);
    --main-info-empty-state-text-color: rgba(255, 255, 255, .8);
    --main-info-vertical-divider: rgba(112, 121, 145, .25);
    --main-info-background-color: rgba(37, 37, 41, .5);
    --main-info-border-color: rgba(48, 47, 53, 1);
    --main-info-divider-color: rgba(255, 255, 255, .8);


    --main-smart-trade-bot-left-panel-background-color: rgba(37, 37, 41, 0.5);

    --main-smart-trade-bot-border-color: rgba(255, 255, 255, .2);
    --main-smart-trade-bot-tab-border-color: rgba(255, 255, 255, .2);
    --main-smart-trade-background-color: rgba(48, 47, 53, 0.85);
    --main-smart-trade-bot-tab-background-color: rgba(37, 37, 41, 0.5);
    --main-smart-trade-bot-glass-background-color: linear-gradient(135.89deg, rgba(114, 111, 126, 1) 0%, rgba(37, 37, 41, 1) 106.68%);
    --main-smart-trade-bot-chart-background-color: linear-gradient(180deg, #1A1123 0%, rgba(0, 0, 0, 0) 100%);

    --main-button-color-primary: rgba(29, 83, 255, 1);
    --main-button-color-hover-primary: rgba(29, 83, 255, 1);
    --main-button-color-active-primary: rgba(29, 83, 255, 1);

    --main-button-background-color-primary: rgba(255, 255, 255, 1);
    --main-button-background-color-hover-primary: rgba(219, 219, 219, 1);
    --main-button-background-color-active-primary: rgba(225, 225, 225, 1);

    --main-button-box-shadow-primary: none;
    --main-button-box-shadow-hover-primary: none;
    --main-button-box-shadow-active-primary: none;

    --main-button-border-color-primary: rgba(255, 255, 255, 1);
    --main-button-border-color-hover-primary: rgba(219, 219, 219, 1);
    --main-button-border-color-active-primary: rgba(225, 225, 225, 1);

    --main-button-color-success: rgba(255, 255, 255, 1);
    --main-button-color-hover-success: rgba(255, 255, 255, 1);
    --main-button-color-active-success: rgba(255, 255, 255, 1);

    --main-button-background-color-success: rgba(0, 168, 89, 1);
    --main-button-background-color-hover-success: rgba(70, 205, 142, 1);
    --main-button-background-color-active-success: rgba(7, 133, 73, 1);

    --main-button-box-shadow-success: none;
    --main-button-box-shadow-hover-success: none;
    --main-button-box-shadow-active-success: none;

    --main-button-border-color-success: rgba(0, 168, 89, 1);
    --main-button-border-color-hover-success: rgba(70, 205, 142, 1);
    --main-button-border-color-active-success: rgba(7, 133, 73, 1);

    --main-button-color-danger: rgba(255, 255, 255, 1);
    --main-button-color-hover-danger: rgba(255, 255, 255, 1);
    --main-button-color-active-danger: rgba(255, 255, 255, 1);

    --main-button-background-color-danger: rgba(249, 57, 57, 1);
    --main-button-background-color-hover-danger: rgba(246, 128, 128, 1);
    --main-button-background-color-active-danger: rgba(191, 53, 53, 1);

    --main-button-box-shadow-danger: none;
    --main-button-box-shadow-hover-danger: none;
    --main-button-box-shadow-active-danger: none;

    --main-button-border-color-danger: rgba(249, 57, 57, 1);
    --main-button-border-color-hover-danger: rgba(246, 128, 128, 1);
    --main-button-border-color-active-danger: rgba(191, 53, 53, 1);

    --main-button-color-default: rgba(112, 121, 145, 1);
    --main-button-color-hover-default: rgba(255, 255, 255, 0.7);
    --main-button-color-active-default: rgba(112, 121, 145, 1);

    --main-button-background-color-default: rgba(255, 255, 255, 0);
    --main-button-background-color-hover-default: rgba(112, 121, 145, 0.6);
    --main-button-background-color-active-default: rgba(112, 121, 145, 0.2);

    --main-button-box-shadow-default: none;
    --main-button-box-shadow-hover-default: none;
    --main-button-box-shadow-active-default: none;

    --main-button-border-color-default: rgba(112, 121, 145, 1);
    --main-button-border-color-hover-default: rgba(112, 121, 145, 1);
    --main-button-border-color-active-default: rgba(112, 121, 145, 1);

    --main-button-color-disabled: rgba(255, 255, 255, 0.3);
    --main-button-background-color-disabled: rgba(255, 255, 255, 0.3);
    --main-button-box-shadow-disabled: none;
    --main-button-border-color-disabled: rgba(255, 255, 255, 0.3);

    --main-button-color-outline-primary: rgba(255, 255, 255, 1);
    --main-button-background-color-outline-primary: rgba(0, 0, 0, 0);
    --main-button-box-shadow-outline-primary: none;
    --main-button-border-color-outline-primary: rgba(255, 255, 255, 1);

    --main-button-color-outline-success: rgba(0, 168, 89, 1);
    --main-button-background-color-outline-success: rgba(0, 0, 0, 0);
    --main-button-box-shadow-outline-success: none;
    --main-button-border-color-outline-success: rgba(0, 168, 89, 1);

    --main-button-color-outline-danger: rgba(249, 57, 57, 1);
    --main-button-background-color-outline-danger: rgba(0, 0, 0, 0);
    --main-button-box-shadow-outline-danger: none;
    --main-button-border-color-outline-danger: rgba(249, 57, 57, 1);

    --main-button-color-outline-disabled: rgba(255, 255, 255, 0.3);
    --main-button-background-color-outline-disabled: rgba(255, 255, 255, 0);
    --main-button-box-shadow-outline-disabled: none;
    --main-button-border-color-outline-disabled: rgba(255, 255, 255, 0.3);

    --main-slider-track-background-color: #FFF;
    --main-slider-rail-background-color-disabled: rgba(65, 68, 86, 1);

    --main-modal-border-color: rgba(255, 255, 255, .1);

    --main-custom-bot-tab-background-color-active: rgba(48, 50, 64, 1);
    --main-custom-bot-tab-background-color: rgba(65, 68, 86, 1);
    --main-custom-bot-tab-color: rgba(255, 255, 255, 255);
    --main-custom-bot-tab-pairs-color: rgba(255, 255, 255, 0.5);;
  }
}







