@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.custom-dropdown-stylised {
  font-size: fn-px2rem(10px);
  line-height: fn-px2rem(12px);
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  &-toggle.dropdown-toggle {
    &[disabled], &.disabled, &:disabled {
      pointer-events: none;
      opacity: .5;
    }
    &:after {
      border: none;
      content: none;
      margin: 0;
    }
    &:hover {
      border: 1px solid $navigation-font-hover-color;
      .dropdown-icon {
        color: $navigation-font-hover-color;
      }
    }
    &:active {
      border: 1px solid $navigation-font-active-color;
      .dropdown-icon {
        color: $navigation-font-active-color;
      }
    }

    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 100%;
    border: 1px solid $navigation-font-color;
    border-radius: fn-px2rem(4px);
    padding: 0 fn-px2rem(6px) 0 fn-px2rem(6px);
    position: relative;

    .content {
      text-align: center;
      width: 100%;
      display: grid;
      grid-template-rows: fn-px2rem(20px);
      grid-template-columns: 1fr auto;
      grid-column-gap: fn-px2rem(6px);
      align-items: center;

      .dropdown-icon {
        font-size: fn-px2rem(8.28px);
        width: min-content;
        position: relative;
        top: fn-px2rem(2px);
      }
    }
  }

  &-items-menu.dropdown-menu {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    box-shadow: fn-px2rem(0px) fn-px2rem(2px) fn-px2rem(4px) rgba(0, 0, 0, 0.08);
    border-radius: fn-px2rem(4px);
    font-size: fn-px2rem(10px);
    line-height: fn-px2rem(12px);
    padding: 0;
    height: fn-px2rem(120px);
    cursor: pointer;

    .rcs-positioning {
      padding-top: fn-px2rem(4px);
      height: calc(100% - #{fn-px2rem(4px)});
    }

    .custom-dropdown-stylised-item.dropdown-item {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: fn-px2rem(30px);
      padding: 0 fn-px2rem(6px) 0 fn-px2rem(6px);
      margin: 0;
      color: rgba(43, 26, 61, 1);
      align-items: center;
      cursor: pointer !important;

      &:hover, &.hover {
        background-color: inherit;
        color: rgba(85, 126, 255, 1);
      }
      &:active, &.active {
        background-color: inherit;
        color: rgba(85, 126, 255, 1);
      }
    }
  }
}