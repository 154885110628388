@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$rem-1-value: fn-px2rem(16px);
$font-color-right-button: #e2e3ef;
$danger-color: #f93939;
$success-color: #00a859;

.smart-trade {
  display: grid;
  grid-template-rows: fn-px2rem(64px) fn-px2rem(104px) auto auto auto auto auto;
  grid-row-gap: $rem-1-value;
  height: 100%;

  @media (max-width: 768px) {
    grid-template-rows: auto fn-px2rem(104px) auto auto auto auto auto;
    grid-row-gap: fn-px2rem(24px);
  }

  .icons-select {
    height: $rem-1-value;
    line-height: $rem-1-value;
  }

  .negative-color {
    color: $global-negative-color;
  }
  .positive-color {
    color: $global-positive-color;
  }

  .content-controls {
    padding-left: fn-px2rem(51px);
    height: fn-px2rem(64px);
    border-radius: $rem-1-value;
    background: $global-colors-background-panel;
    align-items: center;
    grid-template-columns: max-content max-content max-content 100%;
    display: grid;
  }
  .open-order-controls {
   padding-left: fn-px2rem(15px);
   border-radius: $rem-1-value;
   background: $global-colors-background-panel;
   align-items: center;
  }
   
  .buttons-controls {
    padding-left: fn-px2rem(40px);
    padding-right: fn-px2rem(50px);
    height: fn-px2rem(40px);
    align-items: center;
    display: flex;
  
  }

  .search-input {
    color: $global-font-color-primary;
    background-color: transparent;
    padding: fn-px2rem(24px);
    
    .custom-btn-search {
      cursor: pointer;
      transition: color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
      background: $global-colors-button-default;
      color: $global-button-color-primary;
      border-color: $global-colors-button-default;
      z-index: 1;

      &:hover {
        border-color: $global-colors-button-hover;
        color: $global-button-color-hover-primary;
        background: $global-colors-button-hover;
      }

      &:active,
      &.active {
        border-color: $global-colors-button-active;
        color: $global-button-color-active-primary;
        background: $global-colors-button-active;
      }
    }

    .clear {
      background: inherit;
      border-right: none;
      color: inherit;
    }
  }

  .btn-link {
    color: #808080;
    text-decoration: none;
    margin-right: fn-px2rem(51px);

    &:hover {
      color: $global-colors-link-hover;
    }

    &:focus,
    &.active,
    &:active {
      color: $global-colors-link-default;
      box-shadow: none !important;
      z-index: 0;
    }
  }

  .customized-toggle .react-toggle {
    top: 0;
  }

  &-controls-container {
    padding-left: $rem-1-value;

    &-content {
      display: grid;
      grid-template-rows: 100%;
      grid-template-columns:
        minmax(fn-px2rem(192px), auto) minmax(fn-px2rem(100px), auto) minmax(fn-px2rem(111px), auto)
        100%;
      grid-column-gap: $rem-1-value;
      height: 100%;
      align-items: center;

      @media (max-width: 520px) {
        display: block;
      }
    }
  }

  &-choice-container {
    border-radius: $rem-1-value;
    border: 1px solid $global-colors-border-neutral-0;
    background: $global-colors-background-panel;
    width: 100% !important;

    .choice-selectors-grid {
      padding-left: $rem-1-value;
      padding-right: $rem-1-value;
      display: grid;
      grid-template-columns: 30% 30% 30%;
      grid-column-gap: $rem-1-value;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
  }

  &-packs-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, fn-px2rem(216px));
    grid-row-gap: $rem-1-value;
    grid-column-gap: $rem-1-value;
    justify-content: start;

    .pack-item {
      height: 96px;
      width: 216px;
      border-radius: $rem-1-value;
      background: $global-colors-background-panel;
      border: 1px solid $global-colors-border-neutral-0;
      cursor: pointer;

      &-content {
        padding: fn-px2rem(14px) $rem-1-value;
        height: 100%;
        width: 100%;
        color: $global-colors-font-2;
      }

      &-grid {
        display: grid;
        grid-template-columns: 60px minmax(50px, 100%);
        grid-template-rows: 30px 24px;
        grid-column-gap: fn-px2rem(28px);
        align-items: self-end;
      }

      &-size {
        line-height: $rem-1-value;
      }

      &-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flow-root;
        overflow: hidden;
        color: $global-colors-font-1;
      }

      &-main-coin {
        height: $rem-1-value;
        line-height: $rem-1-value;
      }

      .link-text {
        color: $global-colors-link-default;
      }
      &:hover {
        border-color: $global-colors-link-hover !important;
        border-style: solid !important;
      }
      &:active,
      &.active {
        border-color: $global-colors-link-active;
      }

      &.-toggle,
      &.-add {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 100%;
        align-items: center;
        justify-items: center;
      }

      &.-add {
        border: 1px dashed $global-colors-link-default;
      }
    }
  }

  &-pack {
    border-radius: $rem-1-value;
    border: 1px solid $global-colors-border-neutral-0;
    background: $global-colors-background-panel;

    &-body {
      padding-bottom: $rem-1-value;
      padding-left: $rem-1-value;
      padding-right: $rem-1-value;
      display: grid;
      grid-template-columns: repeat(auto-fit, fn-px2rem(145px));
      grid-row-gap: $rem-1-value;
      grid-column-gap: $rem-1-value;

      &.-edit {
        grid-template-columns: repeat(auto-fit, fn-px2rem(171px));
      }
    }

    &-controls {
      padding-top: fn-px2rem(14px);
      padding-bottom: $rem-1-value;

      .cancel-select-pack {
        cursor: pointer;
      }

      .pack-edit {
        .edit-mode-links {
          color: $global-colors-link-default;
          cursor: pointer;

          &.-edit {
            span:first-child {
              color: $global-font-color-secondary !important;
            }
            // &:last-child {

            // }
          }
        }
      }
    }

    .pair-item {
      width: fn-px2rem(145px);
      height: fn-px2rem(32px);
      border-radius: $rem-1-value;
      border: 1px solid $global-colors-border-neutral-0;
      display: grid;
      align-items: center;
      text-align: center;
      color: $global-colors-font-2;
      cursor: pointer;

      &.active {
        border-color: $global-colors-link-default !important;
        background: $navigation-selector-background-active;
      }

      &.-edit {
        width: fn-px2rem(171px);
      }

      &.-add-pair {
        width: fn-px2rem(171px);
        border-style: dashed;
        border-color: $global-colors-link-default;
        color: $global-colors-link-default;
        cursor: pointer;
      }

      .pair-content {
        height: $rem-1-value;
        line-height: $rem-1-value;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-chart {
    &-container {
      padding-left: 100px;
      padding-right: 100px;
      height: fit-content;
      width: 100%;

      @media (max-width: 768px) {
        padding-left: fn-px2rem(0px);
        padding-right: fn-px2rem(0px);
      }
    }
    &-content {
      display: grid;
      grid-template-columns: minmax(0, 50%) minmax(0, 50%);
      gap: 40px;
      padding-bottom: fn-px2rem(30px);
      @media (max-width: 1024px) {
        grid-template-columns: minmax(0, 100%);
      }
    }
  }

  &-info-container {
    // background: #706D7C;
    @media (max-width: 768px) {
      padding-bottom: fn-px2rem(60px);
    }
  }

  .smart-trade-chart-container {
    display: grid;
    grid-template-columns: minmax(0, 80%) minmax(0, 20%);
    grid-template-rows: auto;
    column-gap: fn-px2rem(16px);
    padding: 0;

    @media (max-width: 768px) {
      grid-template-columns: minmax(0, 100%);
    }

    .smart-trade-chart {
      flex: 1;
      height: auto !important;

      @media (max-width: 768px) {
        min-height: fn-px2rem(600px);
      }
    }

    .order-book {
      border-radius: $rem-1-value;
      border: 1px solid $global-colors-border-neutral-0;
      background: $global-colors-background-panel;
      padding: fn-px2rem(16px);

      header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sort-buttons {
          display: flex;
          cursor: pointer;

          .sort {
            width: fn-px2rem(24px);
            height: fn-px2rem(24px);
            border-radius: fn-px2rem(2px);
            margin-right: fn-px2rem(8px);
            padding: fn-px2rem(3px);
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: fn-px2rem(3px);
          }

          .left,
          .center,
          .right {
            .left-side {
              border-radius: fn-px2rem(1px);
            }

            .right-side {
              display: grid;
              grid-template-rows: repeat(4, 1fr);
              gap: fn-px2rem(3px);
              border-radius: fn-px2rem(0.5px);

              div {
                background-color: #e2e3ef;
              }
            }
          }

          .left {
            .left-side {
              display: grid;
              grid-template-rows: 1fr 1fr;
              gap: fn-px2rem(3px);

              div {
                border-radius: fn-px2rem(1px);
              }
            }
          }

          .active {
            //border: 0.5px solid #2b1a3d;
            border: 0.5px solid #e2e3ef;

            .red {
              background-color: $danger-color;
            }

            .green {
              background: $success-color;
            }
          }

          .inactive {
            //border: 0.5px solid #e2e3ef;

            .red {
              background: #f7bcbc;
            }

            .green {
              background: #c7efdc;
            }
          }
        }
      }

      main {
        margin-top: fn-px2rem(27px);

        .ask-price {
          .body {
            .column {
              &:nth-child(1) {
                color: $danger-color;
              }
            }

            .background-line {
              background-color: $global-colors-background-ask-price;
            }
          }
        }

        .bid-price {
          .body {
            .column {
              &:nth-child(1) {
                color: $success-color;
              }
            }

            .background-line {
              background-color: $global-colors-background-bid-price;
            }
          }
        }

        .header {
          display: flex;
          align-items: center;
          height: fn-px2rem(24px);
          font-size: fn-px2rem(12px);
          font-weight: 500;

          div {
            flex: 1;
          }
        }

        .body {
          width: 100%;

          .line {
            height: fn-px2rem(24px);
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;

            .column {
              flex: 1;
              height: 100%;
              color: black;
              font-size: fn-px2rem(12px);
              z-index: 2;
              display: flex;
              flex-direction: column;
              justify-content: center;

              &:nth-child(2),
              &:nth-child(3) {
                padding-left: fn-px2rem(5px);
                color: #707991;
              }

              &:nth-child(1) {
                // color: $danger-color;
                padding-left: fn-px2rem(5px);
              }
            }

            .background-line {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              z-index: 1;
            }
          }
        }
      }

      .select {
        z-index: 100;
      }
    }
  }
}

.modal {
  &.add-pair,
  &.add-pack {
    .modal-content {
      border: 1px solid $global-smart-trade-bot-border-color;
      background: $global-colors-background-panel;
    }
    .modal-footer {
      border-top: none;
    }
    label {
      margin: 0;
      font-size: fn-px2rem(12px);
    }
    .pack-create-form {
      display: grid;
      grid-template-rows: auto auto auto;
      grid-row-gap: $rem-1-value;

      .form-control {
        font-size: 14px;
        background: inherit;
        color: inherit;

        &:focus {
          border-color: #ced4da;
          box-shadow: none !important;
          z-index: 0;
        }
      }
    }
    .pair-create-form {
      display: grid;
      grid-template-rows: auto auto auto;
      grid-row-gap: $rem-1-value;
    }
  }
}

.trade-funds.modal {
  .modal-content {
    background: $global-colors-background-main;
    color: $global-font-color-primary;
    border-radius: fn-px2rem(16px);

    .modal-header {
      justify-content: end;
      border: none;
      padding-bottom: 0;

      &-border {
        justify-content: space-between;
        border-bottom: 1px solid rgba(222, 226, 230, 1);
        padding: fn-px2rem(31px) fn-px2rem(24px) fn-px2rem(24px);

        .title {
          font-size: fn-px2rem(16px);
          font-weight: 500;
          margin: 0;
        }
      }

      .cancel-icon {
        color: $global-font-color-secondary;
        transition: $global-transition;

        &:hover {
          color: rgba(29, 83, 255, 1);
        }
      }
    }

    .modal-body {
      padding: fn-px2rem(24px);

      .title {
        font-size: fn-px2rem(18px);
        font-weight: 500;
      }

      ul,
      p,
      .label {
        font-size: fn-px2rem(14px);
      }

      .label {
        color: $global-colors-font-2;
        margin: 0;

        .error {
          color: $global-negative-color;
        }

        .text {
          color: $global-colors-font-0;
        }
      }

      .dont-show-again {
        color: $info-label-color;
      }

      .submit-button {
        display: flex;
        justify-content: space-between;

        button {
          width: 48%;
        }

        .confirm {
          background-color: $global-colors-button-default;
          color: $global-button-color-primary;
        }

        .back {
          background-color: $global-colors-background-main;
          color: $global-colors-button-default;
          border-color: $global-colors-button-default;
        }

        .discard {
          background-color: $global-button-background-color-danger;
          border-color: $global-button-border-color-outline-danger;
        }
      }

      .label-wrapper {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: minmax(0, 35%) minmax(0, 65%);
        align-items: center;
      }

      .form-item {
        .form-control {
          border-right: none;
          background: inherit;
          color: inherit;
        }

        .right-input-button {
          border-top-right-radius: fn-px2rem(4px);
          border-bottom-right-radius: fn-px2rem(4px);
          background: inherit;
          border-left: none;
          color: $font-color-right-button;
        }

        .slider {
          width: 65%;

          &-input-group {
            width: 35%;
          }
        }

        .multiple-selector {
          width: 40%;

          .css-1auj858-control {
            background-color: $global-colors-button-default;

            .css-14el2xx-placeholder {
              color: $global-button-color-primary;
            }

            .css-19keue8-indicatorContainer,
            .css-1bglyb8-singleValue,
            .css-6j8wv5-Input,
            .css-1pndypt-Input {
              color: $global-button-color-primary;
            }
          }
        }

        &-input-group {
          width: 60%;
        }
      }
    }
  }
}
