@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

// $font-path-roboto: "/public/fonts/roboto";

.navigation {
  &-horizontal {
    &-bar {
      background: $navigation-background-color;
      box-shadow: $navigation-background-box-shadow;
      width: 100%;
      min-height: $navigation-bar-height;
      max-height: $navigation-bar-height;
      position: relative;
      border-bottom: 1px solid $logo-divider-color;

      .navigation-bar-container {
        width: 100%;
        max-width: fn-px2rem(1920px);
        display: grid;
        grid-template-columns: auto 1fr minmax(0, 200px) auto auto;
        @media(max-width: 767px){
          grid-template-columns: 1fr minmax(0, 210px) auto;
        }
        @media(min-width: 767px) and (max-width: 1199px){
          grid-template-columns: 1fr minmax(0, 210px) auto auto;
        }


        grid-template-rows: 1fr;

        .controls-container {
          width: fit-content;
          .header {
            &-information {
              @include fa-icon-transform-color('\f059', fn-px2rem(24px));
            }
            &-language {
              @include fa-icon-transform-color('\f0ac', fn-px2rem(24px));
            }
          }
        }
      }

      .navigation-container-left-part {
        width: 100%;
        max-width: fn-px2rem(800px);
      }

      .menu-bars-container {
        width: fn-px2rem(72px);
        height: 100%;
        border-right: 1px solid $logo-divider-color;
      }
      .menu-bars {
        @include fa-icon-transform-color('\f0c9', fn-px2rem(20px));
      }
    }

    &-log-out {
      border-left: 1px solid $logo-divider-color;
      cursor: pointer;

      .logout {
        @include fa-icon-transform-color('\f08b', fn-px2rem(20px));
      }
    }

    &-logo {
      border-right: 1px solid $logo-divider-color;
      cursor: pointer;
      height: 100%;
      padding-right: fn-px2rem(16px);
      padding-left: fn-px2rem(16px);

      &-icon {
        background-image: $logo-url;
        background-repeat: no-repeat;
        background-size: 100% auto;
        height: fn-px2rem(65px);
        width: fn-px2rem(76px);
      }
    }
  }


  &-vertical {
    &-bar {
      background: $navigation-background-color;
      height: 100vh;
      width: 0;
      position: absolute;
      z-index: 100;
      left: 0;
      top: 0;
      display: none;
      overflow: hidden;

      &.show {
        width: fn-px2rem(288.76px);
        display: block;
      }

      .xmark {
        @include fa-icon-transform-color('\f00d', fn-px2rem(20px));
      }
    }
    &-logo {
      &-container {
        height: $navigation-bar-height;
        padding-right: fn-px2rem(16px);
        padding-left: fn-px2rem(16px);
        cursor: pointer;
      }
      &-icon {
        background-image: $logo-url;
        background-repeat: no-repeat;
        background-size: 100% auto;
        height: fn-px2rem(65px);
        width: fn-px2rem(76px);
      }
    }
  }
}


.icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  i { 
    font-size: fn-px2rem(20px);
    background-color: $theme-button-background-color;
    cursor: pointer;
    color: #989898;
}
}

