@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.wrapper {
  .main-container {
    .header {
      .balance {
        &-card {
          height: fn-px2rem(53px);
          position: relative;
          width: fn-px2rem(400px);
          color: $balances-card-font-color;

          @media(max-width: 730px){
            width: fn-px2rem(200px);
          }

          .balance {

            &-value {
              white-space: nowrap;
            }

            &-column {
              height: 100%;
              position: relative;
              img {
                width: fn-px2rem(32px);
                position: relative;
                top: fn-px2rem(2px);
              }

              &.values-column {
                width: 7rem;
              }

              &.date-column {
                padding-left: fn-px2rem(12px);
                width: fn-px2rem(82px);
                position: relative;
                top: fn-px2rem(2px);
              }

              &.vertical-divider {
                border-right: 1px solid $balances-frame-color !important;
                width: 0;
                margin-top: fn-px2rem(4px);
                height: calc(100% - 6px);
              }

              .vertical-divider, .test img, .test div{
                display: none;

                @media(min-width: 730px){
                  display: block;
                }
              }
            }
          }

        }
        &-controls {
          border: 1px solid $balances-frame-color;
          border-radius: fn-px2rem(4px);
          height: fn-px2rem(47px);
          position: relative;
          top: fn-px2rem(6px);
          margin-left: 0.25rem;
          width: fn-px2rem(16px);

          .standard-options-content {
            position: relative;
            top: fn-px2rem(5px);
          }

          .icon-more.standard-options-more {
            left: 0;
            position: relative;
          }
        }
      }
    }
  }
}