@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.custom-messages-popover {
  max-width: fn-px2rem(325px);
  min-width: fn-px2rem(325px);

  .message {
    &-content {
      height: fn-px2rem(300px);
    }
    &-scroll-container {
      height: calc(100% - 1.5rem);
    }
    &-card {
      border-radius: fn-px2rem(4px);
      position: relative;
      overflow: hidden;

      &-divider {
        height: 0;
        border-top: 1px solid rgba(165, 171, 188, .5);
      }

      .message-card-controls {
        height: 0;
        opacity: 0;
        transition: $global-transition;
      }

      &:hover {
        background-color: rgba(165, 171, 188, .2);
        &, &+div {
          .message-card-divider {
            border-top: 1px solid rgba(165, 171, 188, 0);
          }
        }
        .message-card-controls {
          height: fn-px2rem(30px);
          opacity: 1;
        }
      }
      .sign {
        color: rgba(165, 171, 188, 1);
        &.active {
          color: rgba(139, 210, 68, 1);
        }
        &.u-active {
          color: $global-active-badge-color;
        }
      }
    }
  }
}

.messages {
  position: relative;
  @include fa-icon-transform-color('\f0f3', fn-px2rem(24px));
  &-fa-icon {
    span {
      top: fn-px2rem(4px) !important;
      left: fn-px2rem(10px) !important;
      padding: fn-px2rem(5.5px) !important;
      background-color: transparent;
    }
    &.new {
      span {
        background-color: $global-active-badge-color;
      }
    }
  }
}