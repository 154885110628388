@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.feed {
  &-content {
    color: $global-font-color-primary;
  }
  &-message {
    white-space: pre-line;
    overflow: hidden;
  }

  &-icon {
    img {
      width: fn-px2rem(25px);
      height: fn-px2rem(25px);
      border-radius: 50%;
    }
  }

  &-divider {
    height: 0;
    border-top: 1px solid $info-divider-color;
  }

  &-time-stamp {
    opacity: .5;
  }

}
.url-link {
  display: inline-block;
  max-width: fn-px2rem(350px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}