@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}


.docs-page {
    padding-top: fn-px2rem(200px);
    padding-left: fn-px2rem(98px);
    padding-right: fn-px2rem(98px);
    background-color: #060f19;
    display: flex;
    font-family: 'Unbounded';

    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
        padding-left: fn-px2rem(10px);
        padding-right: fn-px2rem(10px);
    }

    aside {
        width: fn-px2rem(300px);
        border-right: 1px solid white;
        margin-top: fn-px2rem(100px);

        @media(max-width: 768px){
            border-right: none;
            width: 100%;
        }

        ul {
            list-style: none;
        }

        .list {
            padding-left: fn-px2rem(0px);
            cursor: pointer;
        }

        .menu-item, .submenu-item {
            padding: fn-px2rem(10px);

            @media(max-width: 768px){
                padding: fn-px2rem(5px);
            }
            
            &:hover {
                background-color: orange;
            }
        }
    }

    main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: fn-px2rem(10px);

        header {
            text-align: center;
        }

        img {
            @media(max-width: 768px){
                width: 90%;
                display: flex;
                justify-content: center;
            }
        }

        .btn-article {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width:  fn-px2rem(250px);
            height:  fn-px2rem(120px);
            border: 1px dashed white;
            color: white;
            padding-bottom: 0;
            border-radius:  fn-px2rem(5px);
            font-size: fn-px2rem(14px);
            padding: 0 10px;
            cursor: pointer;

            span {
                font-size:  fn-px2rem(14px);
            }

            &:hover {
                background-color: white;
                color: black
            }
        }
    }
}

.isLoading {
    padding-bottom: 300px;
}