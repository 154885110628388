@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}


.feed-message-wraper {
    margin: 0 5% 0 5%;
    height: 90%;
    padding: 1%;
    border-style: outset;
    border-radius: 2%;
    border-color: $global-table-border-color;
    display: flex;
    flex: 1;
}
.scroll-widget {
    width: 100%;
}
.feed-message-show-data {
    padding: 1%;
    height: 90%;
    width: 100%;
    overflow: auto; 
}
.feed-message-text {
   display: block;
   height: fn-px2rem(30px);
   max-width: fn-px2rem(400px);;
   word-wrap: break-word;
   overflow: hidden;
   text-overflow: ellipsis;
   cursor: pointer;
}

