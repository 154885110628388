@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.highlights-body {
  padding-left: 5%;
  padding-right: 1%;
  height: 100%;
  padding-bottom: 2%;
  .highlights-card {
    display: flex;
    flex-direction: column;
    height: fn-px2rem(388px);
    margin-bottom: fn-px2rem(30px);
    border: 1px solid var(--global-colors-border-neutral-0);
    background-color: --global-colors-background-panel;
    border-radius: fn-px2rem(10px);

    .highlights-card-type {
      display: flex;
      justify-content: space-between;
      width: 10%;
      height: fn-px2rem(20px);
      align-items: center;
      padding: 1%;
      border-radius: fn-px2rem(0px) fn-px2rem(10px) fn-px2rem(10px) fn-px2rem(0px);
    }

    .type-title {
      width: fn-px2rem(50px);
      font-family: 'Inter';
      font-weight: 400;
      font-size: fn-px2rem(12px);
    }

    .horizontal-divider {
      margin-top: fn-px2rem(10px);
      border-top: fn-px2rem(1px) solid;
      border-color: $bot-card-divider-color;
      width: 100%;
      height: 0;
    }

    @media (max-width: 768px) {
      .highlights-card {
        height: fn-px2rem(200px);
      }

      .highlights-card-type {
        width: 20%;
      }
    }
  }

  .highlights-card-data {
    display: flex;
    flex-direction: column;
    .highlights-card-body {
      display: flex;
      padding-left: fn-px2rem(10px);
      align-items: center;
      height: fn-px2rem(50px);
      justify-content: space-between;
      width: 90%;
      .coin-icon {
        padding-top: fn-px2rem(30px);
      }

      .title-coin {
        padding-left: fn-px2rem(10px);
        padding-top: fn-px2rem(30px);
      }

      .date-coin {
        padding-left: fn-px2rem(100px);
        font-family: 'Inter';
        font-weight: 500;
        font-size: fn-px2rem(14px);
        line-height: fn-px2rem(17px);
      }

      .notif-icons {
        padding-left: fn-px2rem(20px);

        > svg {
          margin-right: fn-px2rem(5px);
        }
      }

      .views-wrapper,
      .votes-wrapper {
        font-family: 'Inter';
        font-weight: 500;
        font-size: fn-px2rem(24px);
        line-height: fn-px2rem(30px);
        padding-right: fn-px2rem(10px);
      }

      .views-wrapper {
        padding-left: fn-px2rem(220px);
        padding-top: fn-px2rem(30px);
      }

      .votes-wrapper {
        padding-left: fn-px2rem(75px);
        padding-top: fn-px2rem(30px);
      }

      .views-description {
        padding-top: fn-px2rem(20px);
      }

      .votes-description {
        padding-top: fn-px2rem(30px);
      }

      @media (max-width: 768px) {
        .views-wrapper,
        .votes-wrapper {
          padding-left: fn-px2rem(10px);
        }
      }
    }
  }

  .card-text {
    padding-left: fn-px2rem(200px);
    font-family: 'Inter';
    font-weight: 500;
    font-size: fn-px2rem(24px);
    line-height: fn-px2rem(29px);
  }

  .description-icons {
    padding-left: fn-px2rem(20px);

    > svg {
      margin-right: fn-px2rem(5px);
    }
  }

  @media (max-width: 768px) {
    padding-left: 2%;

    .highlights-card {
      width: 90%;
      height: fn-px2rem(300px);
      .card-type {
        width: 15%;
        height: fn-px2rem(20px);

        .type-title,
        .question-icon {
          padding-left: fn-px2rem(2px);
        }
      }
    }

    .card-body {
      padding-left: fn-px2rem(5px);

      .title-coin,
      .notif-icons,
      .description-icons {
        padding-left: fn-px2rem(5px);
      }

      .date-coin {
        padding-left: fn-px2rem(50px);
      }
    }

    .card-text {
      padding-left: fn-px2rem(20px);
      text-align: center;
    }
  }
  .icons-like {
    padding-left: fn-px2rem(100px);
    padding-top: fn-px2rem(30px);
    .like-description {
      padding-left: fn-px2rem(10px);
      font-family: 'Inter';
      font-weight: 500;
      font-size: fn-px2rem(14px);
      line-height: fn-px2rem(17px);
    }
    @media (max-width: 768px) {
      padding-left: fn-px2rem(10px);
      padding-top: fn-px2rem(20px);

      .like-description {
        padding-left: fn-px2rem(5px);
      }
    }
  }
  .icons-unlike {
    padding-left: fn-px2rem(50px);
    padding-top: fn-px2rem(40px);

    .unlike-description {
      padding-left: fn-px2rem(10px);
      font-family: 'Inter';
      font-weight: 500;
      font-size: fn-px2rem(14px);
      line-height: fn-px2rem(17px);
    }

    @media (max-width: 768px) {
      padding-left: fn-px2rem(10px);
      padding-top: fn-px2rem(30px);

      .unlike-description {
        padding-left: fn-px2rem(5px);
      }
    }
  }
}
