$font-path-proto-sans: "/public/fonts/protosans";

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: ProtoSans;
  src: url('#{$font-path-proto-sans}/ProtoSans12.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: ProtoSans;
  src: url('#{$font-path-proto-sans}/ProtoSans23.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: ProtoSans;
  src: url('#{$font-path-proto-sans}/ProtoSans44.ttf') format('truetype');
}