@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}



.add-button-action {
  cursor: pointer;
  position: relative;
  top: -1px;

  button.btn {
    &.btn-ssm {
      font-size: fn-px2rem(10px);
      padding: fn-px2rem(2.5px) fn-px2rem(2.5px);
      background-color: rgba(29, 83, 255, 1);
      border-color: rgba(29, 83, 255, 1);
      border-radius: fn-px2rem(3px);
    }
    &.btn-llg {
      font-size: fn-px2rem(16px);
      padding: fn-px2rem(12px);
      background-color: rgba(29, 83, 255, 1);
      border-color: rgba(29, 83, 255, 1);
      border-radius: fn-px2rem(3px);
    }
  }

  .add-button-label {
    color: $add-button-label-color;
  }

  &.alter {
    .add-button-label {
      color: $add-button-alter-label-color;
    }
  }

}