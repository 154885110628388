@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$font-color: rgba(255, 255, 255, 0.8);
// $background-color: rgba(39, 37, 48, 1);
$background-nav: rgba(5, 13, 22, 0.8);
$background: rgba(6, 15, 25, 1);
$footer-border-bottom-color: rgba(184, 182, 182, 1);
$footer-menu-heading-color: rgba(255, 255, 255, 0.5);
$footer-menu-hover-color: linear-gradient(94.22deg, #3688FF 11.02%, #0BAFFF 43.69%, #05CDF9 79.94%);
$active-link: rgba(15, 65, 170, 1);
$card-bg: rgba(30, 31, 35, 1);
$card-footer-bg: linear-gradient(94.22deg, #105DCD 11.02%, #0D298C 118.56%), #000000;

.plans-page {
  padding-top: 207px;
  padding-bottom: 50px;
  
  .plans-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(fn-px2rem(300px), 400px));

    p {
      margin: 0;
    }

    .plans-column {
      margin-bottom: 50px;
    }

    .expert-plan-card {
      margin-left: 15px;
      margin-right: 15px;
      .card-body {
        padding: 0;
      }

      background-color: $background;
      background-image: url(/public/images/dashboard-library/home-page-images/expertCircle.svg), url(/public/images/dashboard-library/home-page-images/beginnerBg.svg);
      background-size: cover;
      border: none;
      border-radius: 15px;

      .card-header {
        background-size: cover;
        padding-top: 37px;
        padding-left: 14px;
        padding-right: 32px;
        border-bottom: none;
        padding-bottom: 20px;
        background: linear-gradient(94.22deg, #F7931A 11.02%, #F3BA2F 118.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &-topic {
          font-weight: 600;
          line-height: 38px;
          margin-bottom: 0;
        }
      }

      .card-content {
        padding-left: 16px;
        padding-bottom: 22px;
        padding-right: 16px;
        &-row {
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: minmax(0, 50%) minmax(0, 50%);
          padding-top: 22px;

          &-left {
            color: rgba(188, 188, 188, 1);
          }

          &-right {
            text-align: right;
          }
        }
      }

      .card-footer {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background: $card-footer-bg;
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }

    .advanced-plan-card {
      margin-left: 15px;
      margin-right: 15px;
      .card-body {
        padding: 0;
      }

      background-color: $background;
      background-image: url(/public/images/dashboard-library/home-page-images/advancedCircle.svg), url(/public/images/dashboard-library/home-page-images/beginnerBg.svg);
      background-size: cover;
      border: none;
      border-radius: 15px;

      .card-header {
        background-size: cover;
        padding-top: 37px;
        padding-left: 14px;
        padding-right: 32px;
        border-bottom: none;
        padding-bottom: 20px;
        background: linear-gradient(94.22deg, #8CC554 11.02%, #A8CF45 125.09%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &-topic {
          font-weight: 600;
          line-height: 38px;
          margin-bottom: 0;
        }
      }

      .card-content {
        padding-left: 16px;
        padding-bottom: 22px;
        padding-right: 16px;
        &-row {
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: minmax(0, 50%) minmax(0, 50%);
          padding-top: 22px;

          &-left {
            color: rgba(188, 188, 188, 1);
          }

          &-right {
            text-align: right;
          }
        }
      }

      .card-footer {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background: $card-footer-bg;
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }

    .beginner-plan-card {
      margin-left: 15px;
      margin-right: 15px;
      .card-body {
        padding: 0;
      }

      background-color: $background;
      background-image: url(/public/images/dashboard-library/home-page-images/beginnerCircle.svg), url(/public/images/dashboard-library/home-page-images/beginnerBg.svg);
      background-size: cover;
      border: none;
      border-radius: 15px;

      .card-header {
        background-size: cover;
        padding-top: 37px;
        padding-left: 14px;
        padding-right: 32px;
        border-bottom: none;
        padding-bottom: 20px;
        background: $footer-menu-hover-color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &-topic {
          font-weight: 600;
          line-height: 38px;
          margin-bottom: 0;
        }
      }

      .card-content {
        padding-left: 16px;
        padding-bottom: 22px;
        padding-right: 16px;
        &-row {
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: minmax(0, 50%) minmax(0, 50%);
          padding-top: 22px;

          &-left {
            color: rgba(188, 188, 188, 1);
          }

          &-right {
            text-align: right;
          }
        }
      }

      .card-footer {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background: $card-footer-bg;
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }

    .trial-plan-card {
      margin-left: 15px;
      margin-right: 15px;
      .card-body {
        padding: 0;
      }

      background-color: $background;
      background-image: url(/public/images/dashboard-library/home-page-images/plansCardBg.svg);
      background-size: cover;
      border: none;
      border-radius: 15px;

      .card-header {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: minmax(0, 50%) minmax(0, 50%);
        color: rgba(35, 35, 35, 1);
        background-image: url(/public/images/dashboard-library/home-page-images/trialTopCap.svg);
        background-size: cover;
        padding-top: 37px;
        padding-left: 32px;
        padding-right: 32px;
        border-bottom: none;
        padding-bottom: 20px;

        &-topic {
          font-weight: 600;
          line-height: 25px;
          margin-bottom: 0;
          white-space: nowrap;
        }
        .description {
          line-height: 18px;
          font-size: 12px;
        }
      }

      .card-content {
        padding-left: 32px;
        padding-bottom: 22px;
        &-row {
          padding-top: 22px;
          font-style: italic;
          color: rgba(188, 188, 188, 1);
        }
      }
    }

    .card-footer {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      background: $card-footer-bg;
    }
  }
  
  .rcs-inner-container {
    padding: 0;
  }

  .content {
    align-items: center;
  }
  .navigation-vertical-logo-icon {
    background-image: $logo-url;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: fn-px2rem(250px);
    width: fn-px2rem(250px);
  }

  a {
    text-decoration: none;
  }

  .application-link {
    color: $font-color;

  }
}