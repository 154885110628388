@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.defi-multichain-dashboard-layout {
    .heading-link {
        color: $global-font-color-secondary;
        cursor: pointer;
        text-decoration: none;
    }

    .top-content-layout {
        border-radius: fn-px2rem(16px);
        border: 1px solid $global-colors-border-neutral-0;
        background: $global-colors-background-panel;
        margin-bottom: 16px;

        .top-content-header {
            padding: 23px 0 22px 51px;
            width: 100%;
            border-bottom: 1px solid $global-colors-border-neutral-0;

            .heading-link {
                color: $global-font-color-secondary;
                cursor: pointer;
            }

            .active {
                color: $global-colors-link-default;
            }
        }

        .top-content-chains {
            padding: 24px 0 34px 49px;

            .chain-item {
                display: flex;
                align-items: center;
                font-size: 14px;
                border: 1px solid $global-colors-border-neutral-0;
                border-radius: fn-px2rem(16px);
                line-height: 16px;
                text-align: center;
                color: $global-colors-font-2;
                cursor: pointer;
                height: 32px;
                padding-left: 16px;
                padding-right: 16px;

                &.active {
                    border-color: $global-colors-link-default  !important;
                    background: $navigation-selector-background-active;
                }

                i {
                    color: $global-colors-link-default;
                }
            }
        }

        .top-content-numbers {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 245px));
            grid-template-rows: auto;
            padding-left: 49px;
            padding-bottom: 29px;

            .number-item {
                margin-right: 100px;

                .number {
                    font-size: 32px;
                }

                .title {
                    color: $global-font-color-secondary;
                    font-size: 16px;
                }
            }
        }
    }

    .analytics-content {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 33%));
        grid-template-rows: auto;
        column-gap: 16px;

        .analytics-item {
            border-radius: fn-px2rem(16px);
            border: 1px solid $global-colors-border-neutral-0;
            background: $global-colors-background-panel;
            padding: 24px 36px 64px 24px;
            margin-bottom: 16px;

            &-header {
                display: grid;
                grid-template-columns: minmax(0, 60%) minmax(0, 40%);
                grid-template-rows: auto;
                padding-bottom: 29px;

                .heading {
                    text-align: left;
                }

                .show-all {
                    text-align: right;
                    color: $global-colors-link-default;
                    cursor: pointer;
                }
            }

            &-content {
                display: grid;
                grid-template-columns: minmax(0, 40%) minmax(0, 60%);
                grid-template-rows: auto;
                column-gap: 24px;

                .analytics-chart-legend {
                    padding-top: 8px;

                    .legend-item {
                        display: grid;
                        grid-template-columns: minmax(0, 80%) minmax(0, 20%);
                        grid-template-rows: auto;

                        .protocol-value {
                            text-align: right;
                        }

                        .title-wrapper {
                            padding-bottom: 8px;

                            .color-circle {
                                border-radius: 100%;
                                width: 16px;
                                height: 16px;
                            }

                            .legend-value {
                                white-space: nowrap;
                            }

                            .legend-value {
                                color: $global-font-color-secondary;
                            }
                        }
                    }
                }
            }
        }
     
    }

    .heading-wrapper  {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .select-account {
            margin-right: fn-px2rem(30px);
        }
    } 
    
    .precent {
        font-weight: bold;
        color:  var(--main-positive-color);
        padding-top: fn-px2rem(10px);
        font-size: fn-px2rem(58px);
    }

    .precent-description {
        font-size: fn-px2rem(10px);
    }

    .values-wrapper {
        padding-top: fn-px2rem(40px);
        display: flex;
        justify-content: space-between;
        padding-right: fn-px2rem(40px);
        align-items: center;
        width: 100%;
        
    }

    .value-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .value {
            font-size: fn-px2rem(35px);
        }

        .description {
            font-size: fn-px2rem(12px);
        }
    }

    .harvest-all-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: fn-px2rem(30px);
        .button-widget {
            width: fn-px2rem(209px);
            height: fn-px2rem(32px);
        }
        
    }
    
    .header-data {
        color: blue;
    }

    .screens-header {
        height: 20%;
    }

    .pools-content {
        margin-bottom: 16px;

        .pools-section {
            border-radius: fn-px2rem(16px);
            border: 1px solid $global-colors-border-neutral-0;
            background: $global-colors-background-panel;

            &-header {
                display: grid;
                grid-template-columns: minmax(0, 60%) auto minmax(0, 160px) minmax(0, 20px);
                grid-template-rows: auto;
                height: 71px;
                padding: 16px 58px 16px 21px;
            }

           
            &-content {
                padding: 0rem 1rem 1rem 1rem;

                .rcs-inner-container {
                    padding: 0;
                    
                }

                .custom-table-wrapper {
                    height: 500px;
                }

                .custom-table {
                    &-row {
                        grid-template-columns:minmax(0, 5%) minmax(0, 10%) minmax(0, 15%) minmax(0, 35%) minmax(0, 30%) minmax(0, 10%) ;
                    
                        column-gap: 0;

                        &.table-body {
                            .custom-table-body-cell {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;

                                .token-info {
                                    cursor: pointer;
                                    font-size: 23px;
                                }

                                .token {
                                    .address-wrapped {
                                        white-space: nowrap;
                                        width: 100%;
                                        overflow: hidden;
                                        text-overflow: clip ellipsis clip 0 3ch;
                                    }
                                }
                            }
                        }
                    }
                }

                
            }
        }
    }

    .farms-content {
        margin-bottom: 16px;

        .farms-section {
            border-radius: fn-px2rem(16px);
            border: 1px solid $global-colors-border-neutral-0;
            background: $global-colors-background-panel;

            &-header {
                display: grid;
                grid-template-columns: minmax(0, 60%) auto minmax(0, 160px) minmax(0, 20px);
                grid-template-rows: auto;
                height: 71px;
                padding: 16px 58px 16px 21px;
            }

            &-content {
                padding: 0rem 1rem 1rem 1rem;

                .rcs-inner-container {
                    padding: 0;
                }

                .custom-table-wrapper {
                    height: 500px;
                }

                .custom-table {
                    &-row {
                        grid-template-columns:minmax(0, 3%) minmax(0, 12%) minmax(0, 25%) minmax(0, 35%) minmax(0, 20%) minmax(0, 10%);
                        column-gap: 0;

                        &.table-body {
                            .custom-table-body-cell {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;

                                .token-info {
                                    cursor: pointer;
                                    font-size: 23px;
                                }

                                .token {
                                    .address-wrapped {
                                        white-space: nowrap;
                                        width: 100%;
                                        overflow: hidden;
                                        text-overflow: clip ellipsis clip 0 3ch;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .venus-content {
        margin-bottom: 16px;

        .venus-section {
            border-radius: fn-px2rem(16px);
            border: 1px solid $global-colors-border-neutral-0;
            background: $global-colors-background-panel;

            &-header {
                display: grid;
                grid-template-columns: minmax(0, 60%) auto minmax(0, 160px) minmax(0, 20px);
                grid-template-rows: auto;
                height: 71px;
                padding: 16px 58px 16px 21px;
            }

            &-content {
                padding: 0rem 1rem 1rem 1rem;

                .rcs-inner-container {
                    padding: 0;
                }

                .custom-table-wrapper {
                    height: 500px;
                }

                .custom-table {
                    &-row {
                        grid-template-columns: minmax(0, 33%) minmax(0, 33%) minmax(0, 24%) minmax(0, 10);
                        column-gap: 0;

                        &.table-body {
                            .custom-table-body-cell {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;

                                .token-info {
                                    cursor: pointer;
                                    font-size: 23px;
                                }

                                .token {
                                    .address-wrapped {
                                        white-space: nowrap;
                                        width: 100%;
                                        overflow: hidden;
                                        text-overflow: clip ellipsis clip 0 3ch;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .compound-content {
        margin-bottom: 16px;

        .compound-section {
            border-radius: fn-px2rem(16px);
            border: 1px solid $global-colors-border-neutral-0;
            background: $global-colors-background-panel;

            &-header {
                height: 71px;
                display: grid;
                height: 71px;
                grid-template-columns: minmax(0, 60%) auto minmax(0, 160px) minmax(0, 20px);
                grid-template-rows: auto;
                padding: 16px 58px 16px 21px;
            }

            &-content {
                padding: 0rem 1rem 1rem 1rem;

                .rcs-inner-container {
                    padding: 0;
                }

                .custom-table-wrapper {
                    height: 500px;
                }

                .custom-table {
                    &-row {
                        grid-template-columns: minmax(0, 33%) minmax(0, 33%) minmax(0, 24%) minmax(0, 10);
                        column-gap: 0;

                        &.table-body {
                            .custom-table-body-cell {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;

                                .token-info {
                                    cursor: pointer;
                                    font-size: 23px;
                                }

                                .token {
                                    .address-wrapped {
                                        white-space: nowrap;
                                        width: 100%;
                                        overflow: hidden;
                                        text-overflow: clip ellipsis clip 0 3ch;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .wallet-content {
        margin-bottom: 16px;

        .wallet-section {
            border-radius: fn-px2rem(16px);
            border: 1px solid $global-colors-border-neutral-0;
            background: $global-colors-background-panel;

            &-header {
                display: flex;
                justify-content: space-between;
                padding: 16px 58px 16px 21px;

                .wallet-icon {
                    color: $global-colors-link-default;
                }

                .wallet-balance {
                    white-space: nowrap;
                    margin-left: auto;
                    .balance-claim {
                        font-size: 14px;
                        color: $global-font-color-secondary;
                    }
                }
            }

            &-content {
                padding: 0rem 1rem 1rem 1rem;

                .custom-table-wrapper {
                    height: 500px;
                }

                .custom-table {
                    &-row {
                       // grid-template-columns: minmax(0, 30%) minmax(0, 30%) minmax(0, 20%) minmax(0, 10%) minmax(0, 5%) minmax(0, 5%);
                       grid-template-columns:minmax(0, 5%) minmax(0, 22%) minmax(0, 24%) minmax(0, 20%) minmax(0, 10%) minmax(0, 7%) minmax(0, 6%) minmax(0, 6%);
                        column-gap: 0;

                        &.table-body {
                            .custom-table-body-cell {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;

                                .token-info {
                                    cursor: pointer;
                                    font-size: 23px;
                                }

                                .token {
                                    .address-wrapped {
                                        white-space: nowrap;
                                        width: 100%;
                                        overflow: hidden;
                                        text-overflow: clip ellipsis clip 0 3ch;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .table-toggler {
        cursor: pointer;
    }

    .rcs-inner-container {
        padding: 0;
    }

    @media screen and (max-width: 1024px) {
        .analytics-content {
            display: grid;
            grid-template-columns: minmax(0, 100%);
            grid-template-rows: auto;
            column-gap: 0;
            row-gap: fn-px2rem(16px);
        }
    }
}

.defi-blacklist {
    .small-custom-table-row {
        // grid-template-columns: minmax(0, 20%) minmax(0, 30%) minmax(0, 50%) !important;
        .asset-icon {
            width: 22px;
            height: 22px;
            border-radius: 100%;
            margin-right: 4px;
        }    

        &.table-header {
            padding-right: 0 !important;
        }
    }
}

.add-token-modal {
    .modal-body {
        padding: 0 !important;
        border: 1px solid $global-colors-border-neutral-0;
        border-radius: 4px;
    }

    .modal-main-header {
        padding: 1rem;
        border-bottom: 1px solid $global-colors-border-neutral-0;

        .modal-description {
            color: $global-font-color-secondary;
        }
    }

    .modal-token-main-content {
        padding: 1rem;

        .add-token-input {
            padding-bottom: 1rem;

            .form-control {
                border: 1px solid $global-colors-border-neutral-3;
            }
        }

        .add-token-protocols {
            display: grid;
            grid-template-columns: minmax(0, 25%) minmax(0, 25%) minmax(0, 25%) minmax(0, 25%);
            grid-template-rows: auto;
            row-gap: 2rem;
            column-gap: 1rem;

            .add-token-protocols-item {
                padding-top: 8px;
                padding-bottom: 8px;
                cursor: pointer;
                border: 1px solid transparent;
                
                .protocol-title {
                    white-space: nowrap;
                }

                &:hover {
                    border-radius: 1rem;
                    border: 1px solid $global-colors-link-default;
                }

                &.active {
                    border-radius: 1rem;
                    border: 1px solid $global-colors-link-default;
                }
            }
        }
    }
}

.token-info-modal {
    .asset-icon {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        margin-right: 8px;
    }
    
    .modal-body {
        padding: 0 !important;
        border: 1px solid $global-colors-border-neutral-0;
        border-radius: 4px;
    }

    .modal-main-header {
        padding: 1rem !important;
    }

    .modal-token-main-content {
        .modal-token-tabs {
            padding: 15px 0 14px 24px;
            border-bottom: 1px solid $global-colors-border-neutral-0;
            border-top: 1px solid $global-colors-border-neutral-0;

            .modal-token-tab {
                cursor: pointer;
            }
        }

        .price-chart-content {
            padding: 1rem;

            &-chart {
                margin-bottom: 1rem;
            }

            &-header {
                .number-item {
                    .number {
                        font-size: 32px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        line-height: 1;
                    }

                    .title {
                        color: $global-font-color-secondary;
                        font-size: 16px;
                    }
                }
            }
        }

        .modal-token-info {
            display: grid;
            grid-template-columns: minmax(0, 40%) minmax(0, 40%);
            grid-template-rows: auto;
            row-gap: 24px;
            border-bottom: 1px solid $global-colors-border-neutral-0;
            padding: 1rem;

            .number {
                font-size: 32px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                line-height: 1;
            }

            .copy-button {
                cursor: pointer;
            }

            .title {
                color: $global-font-color-secondary;
                font-size: 16px;
            }
        }

        .modal-token-explorers {
            padding: 1rem;

            &-header {
                padding-bottom: 1rem;

                .explorer-title {
                    color: rgba(112, 121, 145, 1);
                    background: #E2E3EF;
                    padding: 0 7px;
                    border-radius: 20px;
                    font-weight: 300;
                    font-size: 14px;
                }
            }

            .explorer-content {
                .explorer-item {
                    text-decoration: none;

                    &-title {
                        color: $global-font-color-secondary;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .active {
        color: $global-colors-link-default;
    }
}

.analytics-modal {
    .rcs-inner-container {
        padding: 0;
    }

    .custom-table-wrapper {
        height: 500px;
    }
    .modal-content {
        .modal-body {
            border: 1px solid $global-colors-border-neutral-0;
            border-radius: 4px;
            padding: 0;

            .modal-main-header {
                padding: 16px;
                font-size: 16px;
                font-weight: 500;
            }

            .modal-main-content {
                &-row {
                    .custom-table {
                        grid-template-columns: minmax(0, 40%) minmax(0, 25%) minmax(0, 25%) minmax(0, 10%);
                        cursor: default;
                    }
                }
            }
        }
    }

    .progress {
        height: 4px;
    }
}

.search-input {
    color: $global-font-color-primary;
    background-color: transparent;
    .custom-btn-search {
        cursor: pointer;
        transition: color .15s ease-in-out,
            background-color .15s ease-in-out,
            border-color .15s ease-in-out,
            box-shadow .15s ease-in-out;
        background: $global-colors-button-default;
        color: $global-button-color-primary;
        border-color: $global-colors-button-default;
        z-index: 1;

        &:hover {
            border-color: $global-colors-button-hover;
            color: $global-button-color-hover-primary;
            background: $global-colors-button-hover;
        }

        &:active,
        &.active {
            border-color: $global-colors-button-active;
            color: $global-button-color-active-primary;
            background: $global-colors-button-active;
        }

    }

    .clear {
        background: inherit;
        border-right: none;
        color: inherit;
    }

    .form-control {
        font-size: 14px;
        background: inherit;
        border-left: none;
        color: inherit;
        border-radius: 4px;
        border-right: none;

        &:focus {
            border-color: #ced4da;
            box-shadow: none !important;
            z-index: 0;
        }
    }
}