@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.smart-contract-allowance-layout {
  &.content {
    display: grid;
    grid-template-rows: auto minmax(0, 100%);
    grid-template-columns: 1fr;
    grid-row-gap: fn-px2rem(16px);
    max-height: 100%;
    padding-bottom: fn-px2rem(16px);
    padding-top: fn-px2rem(16px);
    height: 100%;
  }

  .controls {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, fn-px2rem(240px)) minmax(0, fn-px2rem(16px)) minmax(0, fn-px2rem(240px)) auto minmax(0, fn-px2rem(600px));

    .search-input {
      color: $global-font-color-primary;
      background-color: transparent;

      .custom-btn-search {
        cursor: pointer;
        transition: color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
        background: $global-colors-button-default;
        color: $global-button-color-primary;
        border-color: $global-colors-button-default;
        z-index: 1;

        &:hover {
          border-color: $global-colors-button-hover;
          color: $global-button-color-hover-primary;
          background: $global-colors-button-hover;
        }

        &:active, &.active {
          border-color: $global-colors-button-active;
          color: $global-button-color-active-primary;
          background: $global-colors-button-active;
        }

      }

      .clear {
        background: inherit;
        border-right: none;
        color: inherit;
      }

      .form-control {
        font-size: 14px;
        background: inherit;
        border-left: none;
        color: inherit;
        border-radius: 4px;
        border-right: none;

        &:focus {
          border-color: #ced4da;
          box-shadow: none !important;
          z-index: 0;
        }
      }
    }
  }

  .table-content {
    border-radius: fn-px2rem(16px);
    background: $global-colors-background-panel;
    border: 1px solid $global-colors-border-neutral-0;
    padding: fn-px2rem(16px);
  }

  .custom-table {
    display: grid;
    grid-template-columns: minmax(0, 100%);
    grid-template-rows: minmax(50px, auto) minmax(0, 100%);
    max-height: 100%;
    color: $global-colors-font-1;

    .scroll-container {
      overflow: auto;
    }

    &-row {
      display: grid;
      grid-template-columns: minmax(0, 13%) minmax(0, 10%) minmax(0, 30%) minmax(0, 20%) minmax(0, 10%) minmax(0, 7%) minmax(155px, 10%);
      grid-template-rows: minmax(60px, auto);
      align-items: center;
      padding-left: fn-px2rem(14px);
      padding-right: fn-px2rem(14px);
      cursor: default;
      border-radius: fn-px2rem(4px);


      &.table-body {
        .custom-table-body-cell {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .valign-middle {
          align-items: center;
        }

        &:nth-of-type(odd) {
          background: $global-colors-table-row-even;
          //color: $global-font-color-secondary;
        }

        &:hover {
          //background-color: $global-table-hover-color;
          //color: $global-font-color-primary;
        }
      }

      &.table-header {
        padding-left: fn-px2rem(14px);
        padding-right: fn-px2rem(14px);
        width: calc(100% - 20px);
        border-bottom: 1px solid $global-colors-border-neutral-3;
        border-radius: 0;
        white-space: nowrap;
      }
    }
  }
}