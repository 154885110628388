@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$warning-color: rgba(247, 147, 26, 1);

.hodl-bot-layout {
  @import "_tab_styles";
  @import "_tables_styles.scss";

  .how-it-works {
    color: $global-colors-link-default;
    font-size: fn-px2rem(14px);
    font-weight: 300;
  }

  .grid-rows {
    display: grid;
    grid-template-rows: auto;
    column-gap: fn-px2rem(16px);
  }

  .grid-template-columns-70-30 {
    grid-template-columns: minmax(0, 70%) minmax(0, 30%);

    @media(max-width: 1024px){
      grid-template-columns: minmax(0, 100%);
    }
  }

  .grid-template-columns-33-33-33 {
    grid-template-columns: repeat(3, minmax(0, 33%));

    @media(max-width: 1024px){
      grid-template-columns: minmax(0, 100%);
    }
  }

  .grid-template-columns-50-50 {
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);

    @media(max-width: 1024px){
      grid-template-columns: minmax(0, 100%);
    }
  }

  .grid-template-columns-32-32-32-2 {
    grid-template-columns: repeat(3, minmax(0, 32.5%)) minmax(0, 2.5%);

    @media(max-width: 1024px){
      grid-template-columns: minmax(0, 100%);
    }
  }

  .grid-template-columns-25-25-25-25 {
    grid-template-columns: repeat(4, minmax(0, 25%));

    @media(max-width: 1024px){
      grid-template-columns: repeat(2, 50%);
    }
  }

  .grid-template-columns-30-70 {
    grid-template-columns: minmax(0, 30%) minmax(0, 70%);

    @media(max-width: 1024px){
      grid-template-columns: minmax(0, 100%);
    }
  }

  .grid-template-columns-40-60 {
    grid-template-columns: minmax(0, 40%) minmax(0, 60%);

    @media(max-width: 1024px){
      grid-template-columns: minmax(0, 100%);
    }
  }

  .display-none-1024 {
    @media(max-width: 1024px){
      display: none !important;
    }
  }

  .panel-wrapper {
    background: $global-colors-background-panel;
    border: 1px solid $global-colors-border-neutral-0;
    border-radius: fn-px2rem(16px);
  }

  .form-content {
    padding: fn-px2rem(24px ) fn-px2rem(16px) fn-px2rem(18px) fn-px2rem(16px);
  }

  .w-fit-content {
    width: fit-content;
  }

  .h-fit-content {
    height: fit-content;
  }

  .global-colors-font-2 {
    color: $global-colors-font-2;
  }

  .global-colors-link-default {
    color: $global-colors-link-default;
  }

  .global-negative-color {
    color: $global-negative-color;
  }

  .global-colors-font-0 {
    color: $global-colors-font-0;
  }

  .global-positive-color {
    color: $global-positive-color;
  }

  .btn-default {
    background: $global-colors-button-default;
    color: $global-button-color-primary;
    border-color: $global-colors-button-default;
  }

  .btn-transparent {
    background-color: $global-colors-background-main;
    color: $global-colors-button-default;
    border-color: $global-colors-button-default;
  }

  .btn-steps-sm {
    width: 100%;
    max-width: fn-px2rem(134px);
  }

  .btn-steps-lg {
    width: 100%;
    max-width: fn-px2rem(695px);
  }

  .form-control,
  .css-1auj858-control {
    font-size: fn-px2rem(14px);
    background: $global-colors-background-panel;
    color: $global-font-color-primary;
    height: fn-px2rem(38px);

    &:focus {
      border-color: #ced4da;
      box-shadow: none !important;
      z-index: 0;
    }
  }

  .more-info {
    color: $global-colors-link-default;
    font-size: 16px;
    cursor: pointer;

    .popover-overflow {
      overflow: unset;
    }
  }

  .create-bot-form-content {
    button {
      width: 25%;

      @media(max-width: 1024px){
        width: 100%;
      }
    }
  }

  .selector-btn-default-color {
    .css-1auj858-control {
      background-color: $global-colors-button-default;

      .css-14el2xx-placeholder {
        color: $global-button-color-primary;
      }

      .css-19keue8-indicatorContainer,
      .css-1bglyb8-singleValue,
      .css-6j8wv5-Input,
      .css-1pndypt-Input {
        color: $global-button-color-primary;
      }
    }
  }

  .slider {
    &.-success {
      .rc-slider-track {
        height: 2px;
        background: $global-positive-color;
      }
      .rc-slider-handle {
        border: $global-positive-color;
        background: $global-positive-color;
        transform: none !important;
      }
    }
  }

  .form-item {
    .input-group {
      .border-right-0 {
        border-right: none;
      }
      .right-input-button {
        border-top-right-radius: fn-px2rem(4px);
        border-bottom-right-radius: fn-px2rem(4px);
        background: inherit;
        border-left: none;
        color: $global-colors-font-2;
      }
    }
  }

  .customized-toggle {
    .react-toggle {
      top: 0;
    }
  }

  .w-50-8px {
    width: calc(50% - #{fn-px2rem(8px)});
    @media(max-width: 1024px){
      width: 100%;
    }
  }

  .w-15 {
    width: 15%;
  }

  .w-85 {
    width: 85%;
  }

  .main-sidebar {
    height: fit-content;

    @media(max-width: 1024px){
      margin-top: fn-px2rem(24px);
    }

    &-content {
      padding: fn-px2rem(19px);

      .warning-container {
        background: rgba(250, 240, 228, 1);
        border: 1px solid $warning-color;
        color: $warning-color;
        border-radius: fn-px2rem(8px);
        padding: fn-px2rem(10px);
        line-height: fn-px2rem(17px);
      }
    }
  }

  .cards-content {
    .card-item {
      padding: fn-px2rem(24px);
      margin-bottom: fn-px2rem(16px);
      height: fit-content;

      .left-content {
        width: 40%;

        .coin-icon {
          margin-left: fn-px2rem(-5px);
        }
      }

      .right-content {
        width: 60%;

        .line-img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }

  .filters-content {
    padding: fn-px2rem(14px);

    .collapse-header {
      button {
        width: fn-px2rem(143px);
      }
    }

    .collapse-content {
      .collapse-content-wrapper {
        grid-template-columns: minmax(0, 25%) minmax(0, 25%) minmax(0, 40%);

        @media(max-width: 1024px) {
          grid-template-columns: minmax(0, 100%);
        }

        .form-item {
          .rc-slider {
            width: 70%;
          }

          .input-group {
            width: 15%;
          }
        }
      }
    }
  }

  .my-bots-content {
    &-stats {
      .content-left {
        .sidebar-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px solid $global-colors-border-neutral-0;
          border-radius: fn-px2rem(8px);
          height: fn-px2rem(131px);
          padding: fn-px2rem(20px);
        }
      }

      .content-right {
        &-header {
          padding: fn-px2rem(15px);
          width: 100%;
          max-width: fn-px2rem(473px);
          margin-left: auto;
        }
      }
    }
  }

  .pairs-black-list-content {
    .collapse-header {
      padding: fn-px2rem(24px);
    }

    .collapse-content {
      padding: fn-px2rem(20px) fn-px2rem(24px);
      border-top: 1px solid $global-colors-border-neutral-0;

      &-item {
        width: 100%;
        max-width: fn-px2rem(580px);
      }

      .btn-clear-all {
        width: 100%;
        max-width: fn-px2rem(143px);
      }
    }
  }

  .my-deals-content-list {
    &-cards {
      .deals-history-bt-lg {
        @media(max-width: 600px) {
          display: none;
        }
      }

      .deals-history-bt-sm {
        display: none;

        @media(max-width: 600px) {
          display: block;
        }
      }

      .overall-items {
        display: grid;
        grid-template-rows: auto;
        column-gap: fn-px2rem(16px);
        grid-template-columns: minmax(0, 50%) minmax(0, 50%);

        @media(max-width: 600px) {
          display: flex;
          justify-content: space-between;
        }
      }

      .balances-items {
        display: grid;
        grid-template-rows: auto;
        column-gap: fn-px2rem(16px);
        grid-template-columns: repeat(3, minmax(0, 33%));
        border-bottom: 1px solid $global-colors-border-neutral-3;
      }
    }
  }
}
@import "_modal_styles";