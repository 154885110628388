@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.dashboard-visual {
  &-content {
    background: rgb(48, 47, 53);
    height: 100%;
    &-top {
      position: relative;
      z-index: 3;
      .images-background-grid {
        display: grid;
        grid-template-columns: 35% 30% 35%;
        width: 100%;

        .image-left-part {
          width: 100%;
          position: relative;
          top: fn-px2rem(-16px);
        }
        .image-middle-part {
          width: 100%;
        }
        .image-right-part {
          width: 100%;
        }
      }
    }
    &-bottom {
      background: transparent;
      height: 100%;
      position: relative;
      padding-left: fn-px2rem(24px);
      padding-right: fn-px2rem(24px);
      top: -18vw;
      z-index: 20;

      .bottom-content-grid {
        background: transparent;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: minmax( auto, 400px) minmax(auto, 500px);
        grid-row-gap: fn-px2rem(16px);
        grid-column-gap: fn-px2rem(16px);

        .bottom-grid-item {
          background: $bot-card-background-color;
          border: 1px solid rgba(58, 59, 62, 1);
          border-radius: fn-px2rem(16px);
          overflow: hidden;
          padding-bottom: fn-px2rem(16px);
          padding-left: fn-px2rem(16px);
          padding-right: fn-px2rem(16px);

          &-custom {
            position: relative;
            //top: fn-px2rem(-10px);
            .image-item {
              height: 45%;
              text-align: center;
              svg {
                max-height: 100%;
                width: auto;
                display: inline-block;
              }
              &:last-of-type {
                position: relative;
                //top: fn-px2rem(-14px);
              }
            }
          }

          &.-no-border {
            border: none;
          }
          &.-no-padding {
            padding-left: 0;
            padding-right: 0;
          }

          .yields-grid, .vaults-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, fn-px2rem(209px));
            grid-column-gap: fn-px2rem(16px);
            grid-row-gap: fn-px2rem(16px);
          }
          .wallet-balances {
            height: 100%;

            &-table {
              padding-top: fn-px2rem(16px);
              height: calc(100% - 90px);
            }
          }

          .available-assets {
            height: 100%;
            .assets-grid {
              display: grid;
              grid-row-gap: fn-px2rem(8px);
            }

            .assets-header-grid {
              display: grid;
              width: calc(100% - 20px);
              grid-template-columns:fn-px2rem(100px) fn-px2rem(100px) minmax(0, 50%) minmax(0, 50%);
              padding: fn-px2rem(8px);
            }

            .assets-item-grid {
              display: grid;
              width: 100%;
              grid-template-columns:fn-px2rem(100px) fn-px2rem(100px) minmax(0, 50%) minmax(0, 50%);
              white-space: nowrap;
              align-items: center;
              background: $bot-card-background-color;
              padding: fn-px2rem(8px);
              border-radius: fn-px2rem(8px);

              .value-decoration {
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}