@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$font-color: rgba(255, 255, 255, 0.8);
$negatiive-color: rgba(249, 57, 57, 1);

.App {
  color: #FFF;
  // background-color: rgba(48, 47, 53, 1);
  background-color: rgba(6, 15, 25, 1);
  //   height: 100vh;

  .logo-monoceros {
    cursor: pointer;
  }

  .login-content {
    border-radius: 27px;
    width: 546px;
    border: 1px solid rgba(11, 175, 255, 0.53);

    @media(max-width: 768px){
        width: 100%;
    }

    .login-body {
        border-radius: 25px;
    }

    .login-main-header {
        box-shadow: inset 0px -1px 0px rgba(131, 131, 131, 0.28);
    }
    .login-block {
        background-color: rgba(18, 23, 28, 1);
        border-radius: 25px;
        padding: 0;

        .login-header {
            padding: 23px 22px;
            border-bottom: 1px solid rgba(131, 131, 131, 0.28);
            background: linear-gradient(102.83deg, rgba(0, 78, 191, 0) 39.06%, rgba(11, 175, 255, 0.53) 70.26%, #05CDF9 104.86%, #01E9BB 141.72%);
            display: grid;
            grid-template-rows: 20px;
            grid-template-columns: minmax(0, 50%) minmax(0, 50%);
            border-radius: 20px 20px 0 0;

            .close-mark {
                cursor: pointer;

                &:hover {
                    color: rgba(18, 23, 28, 1);
                }
            }
        }

        .login-main-content {
            .wallet-row {
                padding: 20px 25px;
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: minmax(0, 50%) minmax(0, 50%);
                border-bottom: 1px solid rgba(131, 131, 131, 0.28);
                transition: all ease .2s;

                &:last-child {
                    border-bottom: none;
                    border-radius: 0 0 25px 25px;

                    .wallet-icon {
                        margin-bottom: 8px;
                    }
                }

                &:hover {
                    background-color: rgb(24, 31, 37);
                }

                .error-message {
                    font-size: 12px;
                    color: $negatiive-color;
                }

                .wallet-label {
                    margin-bottom: 8px;
                    color: $font-color;
                }
    
                .wallet-text {
                    margin: 0;
                    font-size: 12px;
                    color: rgba(119, 119, 119, 1);
                }
            }

            .checkboxTerm {
                padding-left: fn-px2rem(40px);
                padding-top: fn-px2rem(40px);

                p{
                    margin-top: fn-px2rem(2px);
                    font-size: fn-px2rem(14px);
                    font-family: 'Inter';
                }

                span{
                    border-bottom: 1px solid white;
                    padding-bottom: fn-px2rem(1px);
                    cursor: pointer;
                }
            }
        }
    }
  }
}