@use "mixins";
@import "~react-custom-scroll/dist/customScroll.css";
@import "theme";

.custom-scroll-bar {
  @include custom-scroll-handle($scroll-bar-container-background-color, $scroll-bar-handle-background-color, -2);
  &-multi-select {
    @include custom-scroll-handle(rgba(112, 121, 145, .15), rgba(112, 121, 145, .5), 2);
  }
  &-dark {
    @include custom-scroll-handle($scroll-bar-alter-container-background-color, $scroll-bar-alter-handle-background-color, -2);
  }
  &-basic {
    @include custom-scroll-handle(rgba(112, 121, 145, .15), rgba(112, 121, 145, .5), 0);
  }
}






