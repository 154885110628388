@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$rem-1-value: fn-px2rem(16px);

.defi-liquidator-layout {
    &.content {
        display: grid;
        grid-template-rows: 64px auto auto minmax(0, 100%);
        grid-template-columns: 1fr;
        grid-row-gap: fn-px2rem(16px);
        max-height: 100%;
        padding-bottom: fn-px2rem(16px);
        padding-top: fn-px2rem(16px);
        height: 100%;
    }

    .protocols-layout {
        .protocols {
            .protocols-header {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: minmax(0, 180px) minmax(0, 20px);
            }

            .customized-toggle .react-toggle {
                top: 0;
            }

            &-row {
                padding: 24px 24px;
                border-radius: fn-px2rem(16px);
                background: $global-colors-background-panel;
                border: 1px solid $global-colors-border-neutral-0;

                .cbox-wrapper {
                    .select-all-cbox {
                        width: 16px;
                        height: 16px;
                    }
                }

                .active {
                    border: 1px solid $global-colors-border-neutral-0;
                }

                .protocols-item {
                    width: fn-px2rem(145px);
                    height: fn-px2rem(32px);
                    border-radius: $rem-1-value;
                    border: 1px solid $global-colors-border-neutral-0;
                    display: grid;
                    align-items: center;
                    text-align: center;
                    color: $global-colors-font-2;
                    cursor: pointer;

                    &-content {
                        padding-left: 8px;
                        height: $rem-1-value;
                        line-height: $rem-1-value;
                        align-items: center;
                    }
        
                    &.active {
                        border-color: $global-colors-link-default  !important;
                        background: $navigation-selector-background-active;
                    }
        
                    &.-edit {
                        width: fn-px2rem(171px);
                    }
        
                    &.-add-pair {
                        width: fn-px2rem(171px);
                        border-style: dashed;
                        border-color: $global-colors-link-default;
                        color: $global-colors-link-default;
                        cursor: pointer;
                    }
        
                    .pair-content {
                        height: $rem-1-value;
                        line-height: $rem-1-value;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .wallet-settings {
        align-items: center;
        border-radius: fn-px2rem(16px);
        background: $global-colors-background-panel;
        border: 1px solid $global-colors-border-neutral-0;
        padding: fn-px2rem(22px) fn-px2rem(52px);

        .tab {
            color: $global-font-color-secondary;
            cursor: pointer;
            margin-right: 94px;
        }

        .active {
            color: $global-colors-link-default;
        }
    }

    .img-selector {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
    }

    .wallet-controls {
        color: $global-font-color-secondary;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: minmax(0, 496px) minmax(0, 1376px);

        &-health,
        &-liquidator {
            border-radius: fn-px2rem(16px);
            background: $global-colors-background-panel;
            border: 1px solid $global-colors-border-neutral-0;
            padding: fn-px2rem(24px);
        }

        &-liquidator {
            .repay-in {
                margin-bottom: 20px;
            }

            .repay-amount {
                .form-control {
                    color: $global-font-color-primary;
                    background-color: transparent;
                    border-right: none;

                    &:focus {
                        border-color: #ced4da;
                        box-shadow: none !important;
                        z-index: 0;
                    }
                }

                .wallet-balance {
                    padding-left: 15px;
                }

                .max-btn {
                    color: $global-colors-link-default;
                    background-color: transparent;
                    border-left: none;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .button-row {
                margin-bottom: 80px;
            }
        }

        &-health {
            .account-grid {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: minmax(0, 20%) minmax(0, 80%);

                .account-id {
                    text-align: right;
                }
            }

            .grid-row-health {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: minmax(0, 60%) minmax(0, 40%) auto;
                align-items: center;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .key-health {
                    justify-content: center;
                }
                
                .price-usd {
                    text-align: right;
                }

                .health-value {
                    text-align: end;
                }
            }
        }
    }

    .controls {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: minmax(0, 332px) auto minmax(0, 594px);

        .search-input {
            color: $global-font-color-primary;
            background-color: transparent;

            .custom-btn-search {
                cursor: pointer;
                transition: color .15s ease-in-out,
                    background-color .15s ease-in-out,
                    border-color .15s ease-in-out,
                    box-shadow .15s ease-in-out;
                background: $global-colors-button-default;
                color: $global-button-color-primary;
                border-color: $global-colors-button-default;
                z-index: 1;

                &:hover {
                    border-color: $global-colors-button-hover;
                    color: $global-button-color-hover-primary;
                    background: $global-colors-button-hover;
                }

                &:active,
                &.active {
                    border-color: $global-colors-button-active;
                    color: $global-button-color-active-primary;
                    background: $global-colors-button-active;
                }

            }

            .clear {
                background: inherit;
                border-right: none;
                color: inherit;
            }

            .form-control {
                font-size: 14px;
                background: inherit;
                border-left: none;
                color: inherit;
                border-radius: 4px;
                border-right: none;

                &:focus {
                    border-color: #ced4da;
                    box-shadow: none !important;
                    z-index: 0;
                }
            }
        }
    }

    .table-content {
        border-radius: fn-px2rem(16px);
        background: $global-colors-background-panel;
        border: 1px solid $global-colors-border-neutral-0;
        padding: fn-px2rem(16px);
    }

    .custom-table {
        display: grid;
        grid-template-columns: minmax(0, 100%);
        grid-template-rows: minmax(50px, auto) minmax(0, 100%);
        max-height: 100%;
        color: $global-colors-font-1;

        .scroll-container {
            overflow: auto;
        }

        .table-value-row:hover {
            cursor: pointer;
            background-color: rgba(139, 139, 226, 0.397) !important;
        }

        .active {
            background-color: rgba(139, 139, 226, 0.397) !important;
        }

        &-row {
            display: grid;
            grid-template-columns: minmax(0, 10%) minmax(0, 30%) minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 15%);
            grid-template-rows: minmax(60px, auto);
            align-items: center;
            padding-left: fn-px2rem(14px);
            padding-right: fn-px2rem(14px);
            cursor: default;
            border-radius: fn-px2rem(4px);

            &.table-body {
                .negative-color {
                    color: $global-negative-color;
                }

                .positive-color {
                    color: $global-positive-color;
                }

                .custom-table-body-cell {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .valign-middle {
                    align-items: center;
                }

                &:nth-of-type(odd) {
                    background: $global-colors-table-row-even;
                    //color: $global-font-color-secondary;
                }

                &:hover {
                    //background-color: $global-table-hover-color;
                    //color: $global-font-color-primary;
                }
            }

            &.table-header {
                width: calc(100% - 20px);
                border-bottom: 1px solid $global-colors-border-neutral-3;
                padding-left: fn-px2rem(14px);
                padding-right: fn-px2rem(14px);
                border-radius: 0;
                white-space: nowrap;
            }
        }
    }

    .address-link {
        color: $global-colors-link-default;
    }

    .negative-color {
        color: $global-negative-color;
    }

    .positive-color {
        color: $global-positive-color;
    }

    .chain-img-wrapper img {
        width: 22px;
        height: 22px;
    }
}