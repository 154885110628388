@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$font-color: rgba(255, 255, 255, 0.8);
$background-color: rgba(39, 37, 48, 1);

.component-loader {
  color: $standard-options-controls-color;
  &.loading-loader {
    color: $global-font-color-primary;
    background: $content-background-color;
  }
  &.authorisation-loader {
    color: $font-color;
    background: $background-color;
  }
  &.transparent-loader {
    background: transparent;
    color: $font-color;
  }

  &.alter {
    color: $standard-options-controls-alter-color;
  }
}