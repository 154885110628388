@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$border-radius-10: fn-px2rem(10px);

.wrapper {
  .main-container {
    .dashboard {
      &-content {
        height: 100%;
        width: 100%;
        position: relative;

        &.-grid {
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: minmax(346px, 50%) minmax(300px, 50%);
          grid-row-gap: 16px;
        }
        &.-column {

        }
      }

      &-row {
        position: relative;
        width: 100%;
        overflow: hidden;
      }

      &-widget {
        position: relative;
        width: 100%;
        max-height: 100%;

        &-body {
          height: 100%;
          position: relative;
          margin: 0;
          padding: 0;

          &.widget-table {
            background: $table-widget-background-color;
            border-radius: $border-radius-10;
            border: 1px solid $table-widget-border-color;
          }

          &.widget-bar-chart {
            background: $bar-chart-background-color;
            border: 1px solid $bar-chart-border-color;
            border-radius: $border-radius-10;
          }

          &.widget-bots {
            background: $bots-widget-background-color;
            border-radius: $border-radius-10;
            border: 1px solid $bots-widget-border-color;
          }

          &.widget-info {
            background: $info-background-color;
            border-radius: $border-radius-10;
            border: 1px solid $info-border-color;
          }
        }
      }
    }
  }
}

@import "dashboard-breakpoints";