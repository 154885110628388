$font-path-roboto: "/public/fonts/roboto";

@font-face {
  font-style: normal;
  font-weight: 100;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-style: italic;
  font-weight: 100;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-style: italic;
  font-weight: 300;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-style: italic;
  font-weight: 500;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-style: italic;
  font-weight: 700;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-style: italic;
  font-weight: 900;
  font-family: Roboto;
  src: url('#{$font-path-roboto}/Roboto-BlackItalic.ttf') format('truetype');
}