@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$font-color: rgba(255, 255, 255, 0.8);
$active-border-color: rgba(15, 65, 170, 1);

.bordered-button {
    position: relative;
    background-image: url(/public/images/dashboard-library/home-page-images/borderImg.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 195px;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 70px;
    color: $font-color;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background-image: none;
        border: 2px solid #FFFFFF;
        filter: drop-shadow(0px 0px 5px #FFFFFF);

        .beta {
            top: -7px;
            right: -12px;
            box-shadow: none;
            border-radius: 2px;
        }
    }

    &:active, &:focus {
        background-image: none;
        border-color: rgba(15, 65, 170, 1);
        filter: none;
    }
}