.tabs {
  align-items: center;
  border-radius: fn-px2rem(8px);
  background: $global-custom-bots-tab-background-color;
  padding: 0 fn-px2rem(4px);
  height: fn-px2rem(40px);

  &.transparent {
    background: transparent;

    .tab {
      &.active {
        border-radius: unset;
        border-bottom: 1px solid $global-colors-link-default;
        background: unset;
      }
    }
  }

  &.tabs-inline-flex {
    display: inline-flex;
    width: 100%;
    max-width: fn-px2rem(631px);

    .tab {
      width: 100%;
    }
  }

  .tab {
    color: $global-custom-bots-tab-color;
    font-size: fn-px2rem(14px);
    align-items: center;
    padding: 0 fn-px2rem(15px);
    justify-content: center;

    &.active {
      color: $global-colors-link-default;
      background: $global-custom-bots-tab-background-color-active;
      box-shadow: 0 2px 4px rgba(29, 83, 255, 0.06);
      border-radius: fn-px2rem(6px);
      height: fn-px2rem(34px);
    }
  }
}

.form-tabs {
  .tab {
    background: $global-colors-background-panel;
    border: 1px solid $global-colors-border-neutral-2;
    border-radius: fn-px2rem(8px);
    padding: fn-px2rem(15px);
    position: relative;

    &.active {
      border-color: $global-positive-color;
    }
  }
}

.pairs-tabs {
  .tab {
    border: 1px solid $global-custom-bots-tab-pairs-color;
    width: 100%;
    max-width: fn-px2rem(116px);
    height: fn-px2rem(37px);
    border-radius: fn-px2rem(4px);
    align-items: center;
    justify-content: center;

    &.active {
      border-color: $global-colors-link-default;
    }
  }
}

.my-bots-tabs {
  padding: fn-px2rem(15px) 0;

  .tab {
    align-items: center;
    font-size: fn-px2rem(14px);
    border: 1px solid $global-colors-border-neutral-0;
    border-radius: fn-px2rem(16px);
    color: $global-colors-font-2;
    height: fn-px2rem(32px);
    padding: 0 fn-px2rem(16px);

    @media screen and (max-width: 600px) {
      padding: 0 fn-px2rem(8px);
    }

    &.active {
      border-color: $global-colors-link-default;
      color: $global-colors-link-default;
      background: $navigation-selector-background-active;
    }
  }
}

.tabs,
.form-tabs,
.pairs-tabs,
.my-bots-tabs {
  .tab {
    display: flex;
    user-select: none;
    cursor: pointer;
  }
}