@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.modal-content {
  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
    border-radius: fn-px2rem(27px);
  }
}

.modal-90w {
  width: 80%;
  max-width: none !important;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
  }

  .modal-content {
    background: #12171c;
    color: white;
    border-radius: fn-px2rem(20px);
    border: 1px solid rgba(11, 175, 255, 0.53);
  }

  .terms {
    font-family: Roboto;
    height: fn-px2rem(550px);
    display: flex;
    flex-direction: column;

    // @media(max-width: 768px){
    //     height: fn-px2rem(800px);
    // }

    .rcs-custom-scroll {
      padding-top: fn-px2rem(80px);
    }
    h2 {
      text-align: center;
      font-weight: 400;

      @media (max-width: 768px) {
        margin-bottom: fn-px2rem(30px);
      }
    }
    h3 {
      font-size: fn-px2rem(24px);
      margin-bottom: fn-px2rem(24px);
    }
    h4 {
      font-size: fn-px2rem(20px);
      margin-bottom: fn-px2rem(10px);
    }
    article {
      margin-bottom: fn-px2rem(50px);
      ul {
        li {
          margin-bottom: fn-px2rem(20px);
        }
      }
    }
    .text {
      padding-left: fn-px2rem(50px);
      padding-right: fn-px2rem(50px);
      flex: 1;

      @media (max-width: 768px) {
        padding-left: fn-px2rem(10px);
        padding-right: fn-px2rem(10px);
      }
    }
    .footer {
      height: fn-px2rem(135px);
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: fn-px2rem(10px);

      a {
        text-decoration: none;
      }
    }
  }
}
