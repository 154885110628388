@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$success-color: rgba(0, 168, 89, 1);
$danger-color: rgba(249, 57, 57, 1);

.dashboard-lending-layout {
    .img-selector {
        img {
            border-radius: 100%;
        }
    }

    .token-img {
        border-radius: 100%;
    }

    .slider {
        &.rc-slider-disabled {
            padding: 0;
            height: 0;
        }

        &.-primary {
            .rc-slider-track {
                height: 8px;
                background: $global-slider-rail-background-color-disabled;
            }

            .rc-slider-rail {
                height: 8px;
                background: $global-slider-rail-background-color-disabled;
            }

            .rc-slider-mark-text {
                color: $global-colors-link-default;
            }

            .rc-slider-dot {
                border-color: rgba(70, 116, 182, 1);
            }

            .rc-slider-handle {
                background-color: rgba(70, 116, 182, 1);
                border-radius: 0;
                width: 4px;
                height: 8px;
                margin-top: 0;
                cursor: default;
                border: none;
            }
        }

        &.-danger {
            .rc-slider-track {
                height: 2px;
                background: $global-negative-color;
            }

            .rc-slider-rail {
                height: 2px;
            }

            .rc-slider-mark-text {
                color: $global-negative-color;
            }

            .rc-slider-dot {
                border-color: $global-negative-color;
            }

            .rc-slider-handle {
                border: 1px solid #ffffff;
                background: $global-negative-color;
            }
        }
    }

    &-controls {
        display: grid;
        grid-template-columns: minmax(0, fn-px2rem(122px)) minmax(0, fn-px2rem(256px)) minmax(0, fn-px2rem(122px)) minmax(0, fn-px2rem(256px));
        grid-template-rows: auto;
        align-items: center;
        column-gap: fn-px2rem(16px);
        padding-bottom: fn-px2rem(16px);
        white-space: nowrap;
    }

    &-analytics-controls {
        display: grid;
      //  grid-template-columns: minmax(0, fn-px2rem(122px)) minmax(0, fn-px2rem(256px)) minmax(0, fn-px2rem(122px)) minmax(0, fn-px2rem(256px));
        grid-template-rows: auto;
        align-items: center;
        column-gap: fn-px2rem(208px);
        padding-bottom: fn-px2rem(16px);
        padding-top: fn-px2rem(16px);
        white-space: nowrap;
    }
    &-content {
        display: grid;
        grid-template-columns: minmax(0, 50%) minmax(0, 50%);
        grid-template-rows: auto;
        column-gap: fn-px2rem(16px);
        row-gap: fn-px2rem(16px);

        .success-color {
            color: $success-color;
        }

        .danger-color {
            color: $danger-color;
        }

        .content-header {
            font-size: fn-px2rem(16px);
            font-weight: 500;
        }

        .account-data {
            display: grid;
            grid-template-columns: minmax(0, 100%);
            grid-template-rows: auto;
            row-gap: fn-px2rem(33px);
            padding: fn-px2rem(24px);
            background-color: $global-colors-background-panel;
            border: 1px solid $global-colors-border-neutral-0;
            border-radius: fn-px2rem(16px);

            .net-apy {
                &-value {
                    font-size: fn-px2rem(48px);
                    font-weight: 500;
                }

                &-info {
                    color: $global-font-color-secondary;
                    font-size: fn-px2rem(16px);
                    font-weight: 400;
                }
            }

            .stats {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 15%));
                grid-template-rows: auto;
                column-gap: fn-px2rem(80px);

                &-item {
                    &-value {
                        font-size: fn-px2rem(32px);
                        font-weight: 500;
                    }

                    &-title {
                        color: $global-font-color-secondary;
                        font-size: fn-px2rem(16px);
                        font-weight: 400;
                    }
                }
            }

            .borrow-limit {
                &-header {
                    display: grid;
                    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
                    grid-template-rows: auto;
                    color: $global-font-color-secondary;

                    div>span {
                        color: $global-font-color-primary;
                    }
                }

                &-footer {
                    div>span {
                        color: $global-font-color-primary;
                    }

                    div>i {
                        color: $global-font-color-secondary;
                    }
                }
            }
        }

        .protocols-data {
            display: grid;
            grid-template-columns: minmax(0, 100%);
            grid-template-rows: fn-px2rem(37px) auto fn-px2rem(37px);
            row-gap: fn-px2rem(33px);
            padding: fn-px2rem(24px);
            background-color: $global-colors-background-panel;
            border: 1px solid $global-colors-border-neutral-0;
            border-radius: fn-px2rem(16px);

            &-controls {
                display: grid;
                grid-template-columns: minmax(0, fn-px2rem(97px)) minmax(0, fn-px2rem(256px));
                grid-template-rows: auto;
                column-gap: fn-px2rem(16px);
                white-space: nowrap;
            }

            &-placeholder {
                display: grid;
                grid-template-columns: minmax(0, 100%);
                grid-template-rows: auto;
                row-gap: fn-px2rem(16px);
                font-size: fn-px2rem(32px);
                padding-left: fn-px2rem(150px);
                padding-right: fn-px2rem(150px);
            }
        }

        .supply-layout {
            &-content {
                background-color: $global-colors-background-panel;
                border: 1px solid $global-colors-border-neutral-0;
                border-radius: fn-px2rem(16px);

                .supply-user-table {
                    .borrow-user-table-headers {
                        display: grid;
                        white-space: nowrap;
                        grid-template-columns: minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 30%);
                        grid-template-rows: auto;
                        justify-content: space-between;
                        padding: fn-px2rem(22px) fn-px2rem(24px);
                        border-top: 1px solid $global-colors-border-neutral-0;
                        border-bottom: 1px solid $global-colors-border-neutral-0;

                        .header-item {
                            font-size: fn-px2rem(14px);
                        }
                    }

                    .borrow-user-table-content {
                        .custom-table {
                            &-row {
                                display: grid;
                                white-space: nowrap;
                                grid-template-columns: minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 30%);
                                grid-template-rows: auto;
                                justify-content: space-between;
                                padding: fn-px2rem(16px) fn-px2rem(24px);
                            }
                        }
                    }

                    .borrow-market-table-headers {
                        display: grid;
                        white-space: nowrap;
                        grid-template-columns: minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 7%);
                        grid-template-rows: auto;
                        justify-content: space-between;
                        padding: fn-px2rem(22px) fn-px2rem(24px);
                        border-top: 1px solid $global-colors-border-neutral-0;
                        border-bottom: 1px solid $global-colors-border-neutral-0;

                        .header-item {
                            font-size: fn-px2rem(14px);
                        }
                    }

                    .borrow-market-table-content {
                        .custom-table {
                            &-row {
                                display: grid;
                                white-space: nowrap;
                                grid-template-columns: minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 10%);
                                grid-template-rows: auto;
                                justify-content: space-between;
                                padding: fn-px2rem(16px) fn-px2rem(24px);
                            }
                        }
                    }

                    .supply-market-table-title {
                        font-size: fn-px2rem(16px);
                        font-weight: 500;
                        padding: fn-px2rem(20px) fn-px2rem(22px) fn-px2rem(20px) fn-px2rem(24px);
                    }

                    .supply-market-table-headers {
                        display: grid;
                        white-space: nowrap;
                        grid-template-columns: minmax(0, 20%) minmax(0, 20%) minmax(0, 20%) minmax(0, 20%) minmax(0, 8%);
                        grid-template-rows: auto;
                        justify-content: space-between;
                        padding: fn-px2rem(22px) fn-px2rem(24px);
                        border-top: 1px solid $global-colors-border-neutral-0;
                        border-bottom: 1px solid $global-colors-border-neutral-0;

                        .header-item {
                            font-size: fn-px2rem(14px);
                        }
                    }

                    .supply-market-table-content {
                        .custom-table {
                            &-row {
                                display: grid;
                                white-space: nowrap;
                                grid-template-columns: minmax(0, 20%) minmax(0, 20%) minmax(0, 20%) minmax(0, 20%) minmax(0, 8%);
                                grid-template-rows: auto;
                                justify-content: space-between;
                                padding: fn-px2rem(16px) fn-px2rem(24px);
                            }
                        }
                    }

                    &-title {
                        font-size: fn-px2rem(16px);
                        font-weight: 500;
                        padding: fn-px2rem(20px) fn-px2rem(22px) fn-px2rem(20px) fn-px2rem(24px);
                    }

                    &-headers {
                        display: grid;
                        white-space: nowrap;
                        grid-template-columns: minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 7%);
                        grid-template-rows: auto;
                        justify-content: space-between;
                        padding: fn-px2rem(22px) fn-px2rem(24px);
                        border-top: 1px solid $global-colors-border-neutral-0;
                        border-bottom: 1px solid $global-colors-border-neutral-0;

                        .header-item {
                            font-size: fn-px2rem(14px);
                        }
                    }

                    &-content {
                        .custom-table {
                            &-row {
                                display: grid;
                                white-space: nowrap;
                                grid-template-columns: minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 15%) minmax(0, 7%);
                                grid-template-rows: auto;
                                justify-content: space-between;
                                padding: fn-px2rem(16px) fn-px2rem(24px);
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-table {
        display: grid;
        grid-template-columns: minmax(0, 100%);
        grid-template-rows: minmax(73px, auto);
        max-height: 100%;
        padding-bottom: fn-px2rem(24px);

        .usd-price {
            color: $global-font-color-primary;
        }

        .token-amount {
            color: $global-font-color-secondary;
        }

        &-row {
            cursor: pointer;
            transition: all ease .3s;

            &:nth-of-type(even) {
                background: $global-colors-table-row-even;
            }

            &:hover {

                .custom-table-body-cell,
                .custom-table-body-cell>div {
                    color: rgb(231, 241, 255);
                }

                background-color: rgba(70, 116, 182, 1);
            }

            &.table-body {
                .negative-color {
                    color: $global-negative-color;
                }

                .custom-table-body-cell {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    img {
                        border-radius: 100px;
                    }

                    .react-toggle {
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

.withdraw-supply-enable-modal {
    .success-color {
        color: $success-color;
    }

    .danger-color {
        color: $danger-color;
    }

    .slider {
        &.rc-slider-disabled {
            padding: 0;
            height: 0;
        }

        &.-primary {
            .rc-slider-track {
                height: 8px;
                background: $global-slider-rail-background-color-disabled;
            }

            .rc-slider-rail {
                height: 8px;
                background: $global-slider-rail-background-color-disabled;
            }

            .rc-slider-mark-text {
                color: $global-colors-link-default;
            }

            .rc-slider-dot {
                border-color: rgba(70, 116, 182, 1);
            }

            .rc-slider-handle {
                background-color: rgba(70, 116, 182, 1);
                border-radius: 0;
                width: 4px;
                height: 8px;
                margin-top: 0;
                cursor: default;
                border: none;
            }
        }

        &.-danger {
            .rc-slider-track {
                height: 2px;
                background: $global-negative-color;
            }

            .rc-slider-rail {
                height: 2px;
            }

            .rc-slider-mark-text {
                color: $global-negative-color;
            }

            .rc-slider-dot {
                border-color: $global-negative-color;
            }

            .rc-slider-handle {
                border: 1px solid #ffffff;
                background: $global-negative-color;
            }
        }
    }

    .modal-body {
        padding: 0;
        border: 1px solid $global-modal-border-color;
        border-radius: 8px;
    }

    .token-img {
        border-radius: 100%;
    }

    .modal-main-header {
        display: grid;
        grid-template-columns: minmax(0, 50%) minmax(0, 50%);
        grid-template-rows: auto;
        row-gap: fn-px2rem(16px);
        padding: fn-px2rem(16px) fn-px2rem(22px);
        border-bottom: 1px solid $global-modal-border-color;

        .cancel-button {
            transition: all ease 0.3s;
            cursor: pointer;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .modal-main-content {
        display: grid;
        grid-template-columns: minmax(0, 100%);
        grid-template-rows: auto;
        row-gap: fn-px2rem(24px);
        padding: fn-px2rem(16px);

        .borrow-limit-section {
            padding-top: fn-px2rem(24px);
            padding-bottom: fn-px2rem(24px);
            border-top: 1px solid $global-table-border-color;
            border-bottom: 1px solid $global-table-border-color;

            .limit-bar-header {
                display: grid;
                grid-template-columns: minmax(0, 50%) minmax(0, 50%);
                grid-template-rows: auto;
                color: $global-font-color-secondary;

                div > span {
                    color: $global-font-color-primary;
                }
            }

            &-footer {
                display: grid;
                grid-template-columns: minmax(0, 50%) minmax(0, 50%);
                grid-template-rows: auto;

                .title {
                    color: $global-font-color-secondary;
                }
            }
        }

        .apys-section {
            display: grid;
            grid-template-columns: minmax(100%);
            grid-template-rows: auto;
            row-gap: fn-px2rem(16px);
            
            .token-apy, .distribution-apy {
                display: grid;
                grid-template-columns: minmax(0, 50%) minmax(0, 50%);
                grid-template-rows: auto;
            }
        }

        .withdraw-amount {
            color: $global-font-color-primary;
            background-color: transparent;

            .clear {
                background: inherit;
                border-right: none;
                color: inherit;
                padding-right: 0;
            }

            .form-control {
                font-size: 14px;
                background: inherit;
                border-left: none;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                color: inherit;
                border-radius: 4px;
                border-right: none;

                &:focus {
                    border-color: #ced4da;
                    box-shadow: none !important;
                    z-index: 0;
                }
            }

            .custom-btn-search {
                cursor: pointer;
                transition: color .15s ease-in-out,
                    background-color .15s ease-in-out,
                    border-color .15s ease-in-out,
                    box-shadow .15s ease-in-out;
                background: $global-colors-button-default;
                color: $global-button-color-primary;
                border-color: $global-colors-button-default;
                z-index: 1;

                &:hover {
                    border-color: $global-colors-button-hover;
                    color: $global-button-color-hover-primary;
                    background: $global-colors-button-hover;
                }

                &:active,
                &.active {
                    border-color: $global-colors-button-active;
                    color: $global-button-color-active-primary;
                    background: $global-colors-button-active;
                }

            }
            
            &-value {
                font-size: fn-px2rem(12px);
            }

            &-value > span {
                color: $global-font-color-secondary;
            }
        }
    }
}