@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$font-color: rgba(255, 255, 255, 255);

.plans-page-layout {
  @media screen and (max-width: 1398px) {
    padding-left: fn-px2rem(40px) !important;
    padding-right: fn-px2rem(40px) !important;
  }

  &-heading {
    text-align: center;
    padding-bottom: fn-px2rem(60px);

    .title {
      font-size: fn-px2rem(50px);
      font-weight: 700;
      font-family: 'Unbounded', serif;
      padding-bottom: fn-px2rem(26px);
    }

    .switchers {
      display: flex;
      margin: 0 auto;
      border-radius: fn-px2rem(60px);
      width: fn-px2rem(327px);
      height: fn-px2rem(60px);
      background: rgba(30, 31, 35, 1);
      font-size: fn-px2rem(22px);
      color: rgba(87, 93, 107, 1);

      div {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.active {
          background: linear-gradient(93.96deg, #1877FF -11.65%, #05D0F3 84.27%, #98E2FF 125.84%), #FFFFFF;
          border-radius: fn-px2rem(60px);
          color: $font-color;
          font-weight: 600;
        }
      }
    }
  }

  &-cards {
    &-first-row,
    &-second-row {
      display: flex;
      gap: fn-px2rem(40px);
      padding-bottom: fn-px2rem(40px);
      justify-content: center;

      @media screen  and (max-width: 1398px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 50%));
      }

      @media screen and (max-width: 714px) {
        grid-template-columns: minmax(0, 100%);
      }

      div {
        width: 33%;

        @media screen  and (max-width: 1398px) {
          width: auto;
        }
      }
    }
  }

  &-disclaimer {
    background: rgba(30, 31, 35, 1);
    border-radius: fn-px2rem(6px);
    padding: fn-px2rem(20px);
    color: #BCBCBC;
  }
}
