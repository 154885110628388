@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.build-page {
    padding-top: 155px;
    .build-start-content {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: minmax(0, 40%) minmax(0, 60%);
        padding-left: 155px;
        margin-bottom: 250px;

        .build-start-heading {
            padding-top: 220px;
            font-size: 50px;
        }

        .build-start-description {
            margin-bottom: 33px;
            width: 500px;
        }
    }

    .building {
        &-heading {
            font-size: 50px;
        }

        &-description {
            margin-bottom: 38px;
            font-size: 20px;
        }

        &-content {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: minmax(0, 100%);
            text-align: center;
            margin-bottom: 300px;
        }
    }

    .liquid-launch {
        &-heading {
            font-size: 50px;
        }

        &-description {
            width: 600px;
            margin-bottom: 38px;
            font-size: 20px;
        }

        &-content {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: minmax(0, 100%);
            text-align: center;
            margin-bottom: 300px;
        }
    }
}