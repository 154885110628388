@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.academy-page {
  padding-top: fn-px2rem(200px);
  padding-left: fn-px2rem(98px);
  padding-right: fn-px2rem(98px);
  padding-bottom: fn-px2rem(40px);
  background-color: #060f19;
  font-family: 'Unbounded';
  min-height: fn-px2rem(500px);

  @media (max-width: 768px) {
    padding-left: fn-px2rem(10px);
    padding-right: fn-px2rem(10px);
  }

  header {
    .header-top {
      display: flex;
      justify-content: space-between;
      align-items: end;

      .header-left {
        flex: 1;
        display: flex;
        align-items: center;
        gap: fn-px2rem(35px);

        h2 {
          font-size: fn-px2rem(48px);
          margin-bottom: fn-px2rem(0px);

          @media (max-width: 768px) {
            font-size: fn-px2rem(29px);
          }
        }

        @media (max-width: 768px) {
          justify-content: space-between;
          width: 100%;
        }
      }

      .filter {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: end;
        position: relative;

        .select-dropdown {
          width: fn-px2rem(332px);

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        button {
          width: fn-px2rem(110px);
          height: fn-px2rem(44px);
          display: flex;
          align-items: center;
          justify-content: center;
          gap: fn-px2rem(5px);
          border-radius: fn-px2rem(4px);
          margin-left: fn-px2rem(16px);
        }

        .filter-list {
          width: fn-px2rem(700px);
          border-radius: fn-px2rem(20px);
          background-color: #182028;
          padding: fn-px2rem(25px);
          display: block;
          position: absolute;
          right: fn-px2rem(0px);
          top: fn-px2rem(60px);
          font-size: fn-px2rem(14px);

          h2 {
            font-size: fn-px2rem(24px);
          }

          h3 {
            font-size: fn-px2rem(16px);
            color: white;
            opacity: 40%;
            margin-bottom: fn-px2rem(16px);
          }

          .tags {
            ul {
              list-style: none;
              display: flex;
              flex-wrap: wrap;
              gap: fn-px2rem(16px);
              padding-left: fn-px2rem(0px);
            }

            .tag {
              font-size: fn-px2rem(14px);
              padding: 4px 16px;
              border: 1px solid #d1d2d4;
              color: #d1d2d4;
              border-radius: fn-px2rem(4px);
              cursor: pointer;
            }

            .unselect-tag {
              background-color: #182028;
            }

            .select-tag {
              background-color: white;
              opacity: 80%;
              color: #34353f;
            }
          }

          .type {
            .type-elements {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              column-gap: fn-px2rem(40px);
              row-gap: fn-px2rem(10px);
            }

            div {
              display: flex;
              align-items: center;
              gap: fn-px2rem(10px);

              .signal {
                width: fn-px2rem(12px);
                height: fn-px2rem(12px);
                border-radius: 50%;
              }
            }
          }

          .time {
            margin-bottom: fn-px2rem(40px);

            .change-time {
              display: flex;
              flex-wrap: wrap;
              column-gap: fn-px2rem(40px);
              row-gap: fn-px2rem(16px);

              div {
                display: flex;
                align-items: center;
                gap: fn-px2rem(16px);
              }
            }
          }

          .tags,
          .type {
            margin-bottom: fn-px2rem(30px);
          }

          footer {
            display: flex;
            justify-content: end;
            gap: fn-px2rem(14px);
            border-top: 1px solid #d1d2d4;
            padding-top: fn-px2rem(24px);

            div {
              padding: 13px 30px;
              border: 1px solid white;
              border-radius: fn-px2rem(4px);
              font-size: fn-px2rem(14px);

              &:hover {
                background-color: white;
                color: #4674b6;
                cursor: pointer;
              }
            }
          }

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .admin {
          display: flex;
          flex-direction: column;
          align-items: end;

          .admin-filter {
            display: flex;
            align-items: end;
            margin-top: fn-px2rem(20px);
            gap: fn-px2rem(16px);

            .filterSearch {
              flex: 1;

              input {
                height: fn-px2rem(38px);
                border-radius: fn-px2rem(4px);
                border: 1px solid white;
                background-color: transparent;
                color: white;
                padding-left: fn-px2rem(10px);
                width: 100%;

                &::placeholder {
                  color: rgba(255, 255, 255, 0.5);
                }
              }
            }

            .select-dropdown {
              flex: 1;
            }
          }

          @media (max-width: 768px) {
            margin-top: fn-px2rem(20px);
          }
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
      }
    }

    .header-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: fn-px2rem(40px);

      .numArticles-and-tags {
        display: flex;
        align-items: center;
        gap: fn-px2rem(20px);

        .number-of-article {
          font-size: fn-px2rem(24px);
        }

        .selected-tags {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: fn-px2rem(16px);

          .selected-tag {
            color: #34353f;
            background-color: white;
            opacity: 70%;
            border-radius: 4%;
            font-family: 'Inter';
            padding: 2px 16px;

            .icon {
              margin-left: fn-px2rem(6px);
              cursor: pointer;
            }
          }

          .selectedTag {
            display: flex;
            align-items: center;
            gap: fn-px2rem(16px);

            .badge {
              background-color: #cdcfd1;
              color: #34353f;
              border-radius: fn-px2rem(4px);
              font-weight: 400;
              padding: 6px 16px;
            }

            .close {
              background-color: #cdcfd1;
              cursor: pointer;
            }
          }

          .btn-clear {
            border: 1px solid white;
            border-radius: fn-px2rem(4px);
            padding: 13px 32px;
            font-size: fn-px2rem(14px);
            cursor: pointer;

            @media (max-width: 768px) {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: start;
          width: 100%;
        }
      }

      .sort {
        display: flex;
        align-items: center;
        gap: fn-px2rem(8px);

        button {
          background-color: #060f19;
          color: white;
          padding: 13px 16px;
          border-radius: fn-px2rem(4px);
          display: flex;
          align-items: center;
          border: none;

          .icon {
            margin-right: fn-px2rem(16px);
          }
        }

        .sort-style {
          background-color: #1e2730;
        }

        @media (max-width: 768px) {
          display: none;
        }
      }

      @media (max-width: 768px) {
        margin-top: fn-px2rem(20px);
      }
    }

    .btn-back {
      float: right;
    }
  }

  main {
    .articles {
      margin-top: fn-px2rem(24px);
      width: 100%;
      display: grid;

      article {
        display: flex;

        .article-img {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .article-text {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-family: 'Inter';

          .title {
            display: flex;
            align-items: start;
            gap: fn-px2rem(16px);

            .signal {
              width: fn-px2rem(12px);
              height: fn-px2rem(12px);
              border-radius: 50%;
              margin-top: fn-px2rem(10px);
            }

            h2 {
              font-family: 'Unbounded';
              font-weight: 700;
              font-size: fn-px2rem(28px);
              cursor: pointer;
              flex: 1;
            }
          }

          .tags {
            display: flex;
            flex-wrap: wrap;
            gap: fn-px2rem(16px);
            margin-top: fn-px2rem(9px);
            margin-bottom: fn-px2rem(27px);

            .tag {
              color: #34353f;
              background-color: #cdcfd1;
              padding: 2px 8px;
              border-radius: fn-px2rem(4px);
              font-size: fn-px2rem(12px);
              cursor: pointer;
            }
          }

          .excerpt {
            @media (max-width: 768px) {
              display: none;
            }
          }

          .data-and-buttons {
            .date-time {
              display: flex;
              gap: fn-px2rem(16px);

              .date,
              .time {
                display: flex;
                align-items: center;
                gap: fn-px2rem(5px);

                div {
                  margin-top: fn-px2rem(5px);
                }
              }
            }

            .article-buttons {
              display: flex;
              gap: fn-px2rem(10px);
            }
          }

          @media (max-width: 768px) {
            background-color: #191919;
            padding: fn-px2rem(16px);
            border-bottom-left-radius: fn-px2rem(16px);
            border-bottom-right-radius: fn-px2rem(16px);
          }
        }
      }
    }

    .sort-list {
      article {
        gap: fn-px2rem(40px);
        margin-bottom: fn-px2rem(24px);

        @media (max-width: 1000px) {
          flex-direction: column;
          gap: 0;
        }

        .article-img {
          width: fn-px2rem(550px);
          height: fn-px2rem(270px);

          img {
            border-radius: fn-px2rem(16px);

            @media (max-width: 768px) {
              border-bottom-left-radius: fn-px2rem(0px);
              border-bottom-right-radius: fn-px2rem(0px);
            }
          }

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .data-and-buttons {
          display: flex;
          justify-content: space-between;
          align-items: end;

          @media (max-width: 768px) {
            flex-direction: column;
            align-items: start;
          }

          .article-buttons {
            @media (max-width: 768px) {
              align-self: end;
              margin-top: fn-px2rem(10px);
            }
          }
        }
      }
    }

    .sort-gallery {
      grid-template-columns: repeat(3, 1fr);
      gap: fn-px2rem(40px);

      @media (max-width: 1400px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 900px) {
        grid-template-columns: repeat(1, 1fr);
      }

      article {
        flex-direction: column;
        height: fn-px2rem(560px);

        .article-img {
          width: 100%;
          height: fn-px2rem(272px);

          img {
            border-radius: fn-px2rem(16px);
            border-bottom-left-radius: fn-px2rem(0px);
            border-bottom-right-radius: fn-px2rem(0px);
          }

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .article-text {
          flex: 1;
          background-color: #1e2730;
          padding: fn-px2rem(16px);
          border-bottom-left-radius: fn-px2rem(16px);
          border-bottom-right-radius: fn-px2rem(16px);

          h2 {
            font-size: fn-px2rem(20px);
          }

          .excerpt {
            display: none;
          }

          .data-and-buttons {
            display: flex;
            flex-direction: column;

            .article-buttons {
              align-self: flex-end;
              margin-top: fn-px2rem(10px);
            }
          }
        }
      }
    }

    .load-more {
      height: fn-px2rem(43px);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid white;
      margin-top: fn-px2rem(50px);
      cursor: pointer;
    }

    .update-filters {
      ul {
        list-style: none;
        padding-left: fn-px2rem(0px);

        li {
          width: 50%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: fn-px2rem(10px);

          div {
            margin-right: fn-px2rem(16px);
          }

          span {
            margin-right: auto;

            @media (max-width: 768px) {
              width: 100%;
              margin-bottom: fn-px2rem(5px);
            }
          }

          @media (max-width: 768px) {
            width: 100%;
            margin-bottom: fn-px2rem(20px);
          }
        }
      }

      .tags {
        margin-top: fn-px2rem(30px);
      }

      form {
        display: flex;
        gap: fn-px2rem(16px);
        align-items: end;
        margin-top: fn-px2rem(30px);

        input {
          height: fn-px2rem(36px);
        }

        .close-btn {
          align-self: flex-start;
          cursor: pointer;
        }
      }

      div {
        position: relative;

        .alert {
          width: 60%;
          position: absolute;
          top: 20%;
          right: 0;

          .btn-close {
            position: absolute;
            top: fn-px2rem(10px);
            right: fn-px2rem(10px);
          }
        }
      }
    }

    .new-article {
      form {
        margin-top: fn-px2rem(30px);
        width: 50%;

        label {
          display: block;
        }

        input,
        textarea {
          width: 100%;
          background-color: transparent;
          color: white;
          border: 1px solid white;

          &::placeholder {
            color: white;
          }
        }

        textarea {
          height: fn-px2rem(100px);
        }

        @media (max-width: 768px) {
          width: 100%;
        }

        .select {
          .select__option {
            position: relative;
            z-index: 2;
          }
        }

        .tox-tinymce {
          position: relative;
          z-index: 0;
        }
      }
    }
  }
}

.isLoading {
  padding-bottom: fn-px2rem(300px);
}
