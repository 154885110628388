@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}


.balances-favorites-container {

  .remove-sign {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    width: 1rem;
    height: 1rem;
    z-index: 10;
    color: $balances-card-font-color;
    background: $balances-frame-color;

    svg {
      position: absolute;
      top: 3px;
      left: 2px;
      width: 70%;
      height: 70%;
    }

    &.edit-mode {
      display: block;
      opacity: 0.7;

      &.mark-for-delete {
        color: $global-negative-color;
      }

      &:hover {
        color: $global-negative-color;
      }
    }
  }

  .remove-sign-container {
    top: 3px;
    right: 0;
    position: absolute;
  }

  .favorites {
    &-controls {
      border: 1px solid $balances-frame-color;
      border-radius: fn-px2rem(4px);
      height: fn-px2rem(47px);
      position: relative;
      top: fn-px2rem(6px);
      margin-left: 0.25rem;
      width: fn-px2rem(16px);

      .standard-options-content {
        position: relative;
        top: fn-px2rem(5px);
      }
    }
    &-action-button {
      border-color: $dropdown-select-icon-color;
      border-radius: 0.25rem;
      font-size: fn-px2rem(12px);

      &:focus {
        box-shadow: none;
      }
    }
    &-toggle-button {
      background: $balances-frame-color;
      position: relative;
      top: fn-px2rem(1px);
      width: fn-px2rem(16px);
      border-bottom-left-radius: fn-px2rem(4px);
      border-bottom-right-radius: fn-px2rem(4px);
      color: $dropdown-select-icon-color;

      .favorites-close-collapse {
      }

    }

    .collapse-content {
      &.collapse, &.collapsing {
        position: absolute !important;
        width: calc(100% + 2px);
        z-index: 20;
        margin-top: fn-px2rem(3px);
        left: -1px;
        background-color: $content-background-color;
        border: 1px solid $balances-frame-color;
        border-radius: fn-px2rem(4px);
      }
    }
    &-grid-container {
      position: relative;
      height: 100%;
      width: 100%;
      display: grid;
      grid-gap: 0;
      row-gap: 0;
      grid-template-columns: repeat(auto-fit, fn-px2rem(128px));


      &.favorites-card {
        height: 100%;

        .favorites-cell {

        }


        .favorites-grid-element {
          border-bottom: 1px solid $balances-frame-color;
          border-right: 1px solid $balances-frame-color;
          &.border-right-none {
            border-right: none;
          }
        }
      }
    }
  }

  .favorites-card {
    height: fn-px2rem(53px);
    position: relative;
    min-width: 100%;
    color: $balances-card-font-color;
    white-space: nowrap;

    .vertical-divider {
      border-right: 1px solid $balances-frame-color !important;
      width: 0;
      margin-top: fn-px2rem(-8px);
      height: calc(100% + 14px)
    }

    .opacity-80 {
      opacity: 0.8;
    }

    .favorites {

      &-cell {
        width: fn-px2rem(128px);
      }

      &-card-body {
        top: fn-px2rem(2px);
      }

      &-img {
        margin-left: fn-px2rem(9px);
        margin-right: fn-px2rem(6px);
        width: 2rem;

        &:first-child {
          margin-left: fn-px2rem(12px);
        }
      }

      &-column {
        height: 100%;
        position: relative;

        .changes {
          color: $balances-card-font-color;

          &.positive {
            color: $global-positive-color;
          }

          &.negative {
            color: $global-negative-color;
          }
        }
      }
    }
  }
}

.add-fovorites.modal {
  background:  $content-background-color;
  .favorites-add-asset {
    .btn.btn-link {
      text-decoration: none;
    }
    .form-control {
      &:focus {
        box-shadow: none;
      }
    }
  }
}
