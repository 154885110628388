.signal {
  &-column {
    display: grid;
    grid-template-rows: min-content min-content auto;
    grid-template-columns: 1fr;
    grid-row-gap: 0;
    height: 100%;
    padding-left: fn-px2rem(12px);
    padding-right: fn-px2rem(8px);
    position: relative;
  }
  &-control-panel {
    padding-top: fn-px2rem(12px);
    padding-bottom: fn-px2rem(10px);
  }
  &-search-element {
    padding-bottom: fn-px2rem(4px);
    padding-left: fn-px2rem(2px);
    padding-right: fn-px2rem(2px);

    .reset-icon {
      color: $navigation-font-color;
      &:hover {
        color: $navigation-font-hover-color;
      }
    }

    span {
      background-color: $navigation-background-color;
      border-color: $bots-widget-border-color;
    }

    .form-control {
      border: 1px solid $info-vertical-divider;
      background: transparent;
      color: $global-font-color-primary;
      &:focus, &:active {
        box-shadow: none;
      }
    }
  }
  &-content {
    overflow: hidden;
    height: calc(100% - 13px);
  }
}