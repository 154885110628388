.wrapper {
  .main-container {
    &.#{BP(1200)}, &.#{BP(1500)} {
      @include row-ratio(.65);
    }

    &.#{BP(1280)}, &.#{BP(1366)}, &.#{BP(1368)}, &.#{BP(1400)}, &.#{BP(1536)}, &.#{BP(1600)}, &.#{BP(1680)}, &.#{BP(1800)}, &.#{BP(1920)}, &.#{BP(2520)} {
      @include row-ratio(.6);
    }

    &.#{BP(0)}, &.#{BP(992)} {
      .dashboard {
        &-widget {
          &-body {
            height: fn-px2rem(346px);
          }
        }
      }
    }
  }
}

