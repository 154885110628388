@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$positive-color: rgba(0, 168, 89, 1);
$negative-color: rgba(168, 0, 0, 1);
$font-color: rgba(255, 255, 255, 255);

.share-order-result.modal {
  .modal-content {
    .main-positive-bg {
      background: radial-gradient(
        118.38% 79.57% at 75.65% 51.44%,
        #103e28 0%,
        #062f1c 35.94%,
        #032012 100%
      );
    }

    .main-negative-bg {
      background: radial-gradient(
        118.38% 79.57% at 75.65% 51.44%,
        #3e1010 0%,
        #220404 35.94%,
        #220303 100%
      );
    }

    .modal-header {
    }

    .modal-body {
      p {
        margin: 0;
      }

      &-heading {
        display: flex;
        align-items: center;
        padding: fn-px2rem(42px) 0 0 fn-px2rem(40px);

        @media (max-width: 992px) {
          padding: fn-px2rem(12px) 0 0 fn-px2rem(20px);
        }

        img {
          margin-right: fn-px2rem(20px);
          width: fn-px2rem(73px);
          height: fn-px2rem(66px);
        }

        .monoceros {
          font-size: fn-px2rem(24px);
        }

        .futures {
          font-size: fn-px2rem(32px);
          font-weight: 700;
        }
      }

      &-content {
        display: flex;
        color: $font-color;

        @media (max-width: 992px) {
          flex-direction: column;
        }

        .left-half {
          flex: 1;
          padding: fn-px2rem(42px) 0 fn-px2rem(75px) fn-px2rem(40px);

          @media (max-width: 992px) {
            padding: fn-px2rem(20px) 0 0 fn-px2rem(20px);
          }

          &-statistic {
            display: flex;
            align-items: center;
            padding: 0 0 fn-px2rem(53px) 0;
            font-size: fn-px2rem(24px);

            @media (max-width: 992px) {
              padding: 0 0 fn-px2rem(20px) 0;
              font-size: fn-px2rem(22px);
            }

            > * {
              margin-right: fn-px2rem(22px);

              @media (max-width: 992px) {
                margin-right: fn-px2rem(18px);
              }
            }

            .circle {
              width: fn-px2rem(10px);
              height: fn-px2rem(10px);
              background: $font-color;
              opacity: 0.3;
              border-radius: 50%;
            }
          }

          &-percentage {
            border-radius: 0 fn-px2rem(100px) fn-px2rem(100px) 0;
            padding: fn-px2rem(20px) fn-px2rem(20px) fn-px2rem(20px) fn-px2rem(40px);
            display: flex;
            margin-left: fn-px2rem(-40px);

            @media (max-width: 992px) {
              border-radius: 0;
              margin-left: fn-px2rem(-20px);
              justify-content: center;
            }

            p {
              font-size: fn-px2rem(54px);
              font-weight: 500;
            }
          }

          &-price {
            padding-top: fn-px2rem(26px);
            line-height: normal;

            @media (max-width: 992px) {
              padding-top: fn-px2rem(13px);
              display: flex;
            }

            .entry-price {
              padding-bottom: fn-px2rem(40px);

              @media (max-width: 992px) {
                padding-right: fn-px2rem(100px);
              }
            }

            .last-price {
            }
          }
        }

        .right-half {
          flex: 1;

          .logo-img {
            @media (max-width: 992px) {
              width: fn-px2rem(393px);
            }
          }

          &-qr {
            display: flex;
            justify-content: end;
            align-items: end;
            padding: 0 fn-px2rem(43px) fn-px2rem(38px) 0;

            @media (max-width: 992px) {
              padding: 0 0 fn-px2rem(38px) fn-px2rem(20px);
              flex-direction: row-reverse;
              justify-content: left;
            }

            .link-container {
              padding-right: fn-px2rem(20px);

              @media (max-width: 992px) {
                padding-left: fn-px2rem(20px);
              }

              .url {
                opacity: 0.5;
              }
            }
          }
        }

        .positive-color {
          color: $positive-color;
        }

        .negative-color {
          color: $negative-color;
        }

        .positive-bg {
          background: $positive-color;
        }

        .negative-bg {
          background: $negative-color;
        }
      }
    }

    .submit-button {
      display: flex;
      justify-content: space-evenly;
      padding: fn-px2rem(20px);

      button {
        width: 30%;
      }

      .share {
        background-color: $global-colors-button-default;
        color: $global-button-color-primary;
      }

      .close {
        background-color: $global-colors-background-main;
        color: $global-colors-button-default;
        border-color: $global-colors-button-default;
      }
    }
  }
}
