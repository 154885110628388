$btn-border-radius: fn-px2rem(4px);
$btn-border-width: fn-px2rem(1px);
$btn-border-style: solid;

$btn-custom-theme-colors: (
        primary: (
                background: (
                        static: $global-colors-button-default,
                        hover: $global-colors-button-hover,
                        active: $global-colors-button-active,
                        disabled: $global-button-background-color-disabled
                ),
                box-shadow: (
                        static: $global-button-box-shadow-primary,
                        hover: $global-button-box-shadow-hover-primary,
                        active: $global-button-box-shadow-active-primary,
                        disabled: $global-button-box-shadow-disabled
                ),
                color: (
                        static: $global-button-color-primary,
                        hover: $global-button-color-hover-primary,
                        active: $global-button-color-active-primary,
                        disabled: $global-button-color-disabled
                ),
                border-color: (
                        static: $global-colors-button-default,
                        hover: $global-colors-button-hover,
                        active: $global-colors-button-active,
                        disabled: $global-button-border-color-disabled
                )
        ),
        success:  (
                background: (
                        static: $global-button-background-color-success,
                        hover: $global-button-background-color-hover-success,
                        active: $global-button-background-color-active-success,
                        disabled: $global-button-background-color-disabled
                ),
                box-shadow: (
                        static: $global-button-box-shadow-success,
                        hover: $global-button-box-shadow-hover-success,
                        active: $global-button-box-shadow-active-success,
                        disabled: $global-button-box-shadow-disabled
                ),
                color: (
                        static: $global-button-color-success,
                        hover: $global-button-color-hover-success,
                        active: $global-button-color-active-success,
                        disabled: $global-button-color-disabled

                ),
                border-color: (
                        static: $global-button-border-color-success,
                        hover: $global-button-border-color-hover-success,
                        active: $global-button-border-color-active-success,
                        disabled: $global-button-border-color-disabled
                )
        ),
        danger:  (
                background: (
                        static: $global-button-background-color-danger,
                        hover: $global-button-background-color-hover-danger,
                        active: $global-button-background-color-active-danger,
                        disabled: $global-button-background-color-disabled
                ),
                box-shadow: (
                        static: $global-button-box-shadow-danger,
                        hover: $global-button-box-shadow-hover-danger,
                        active: $global-button-box-shadow-active-danger,
                        disabled: $global-button-box-shadow-disabled
                ),
                color: (
                        static: $global-button-color-danger,
                        hover: $global-button-color-hover-danger,
                        active: $global-button-color-active-danger,
                        disabled: $global-button-color-disabled
                ),
                border-color: (
                        static: $global-button-border-color-danger,
                        hover: $global-button-border-color-hover-danger,
                        active: $global-button-border-color-active-danger,
                        disabled: $global-button-border-color-disabled
                )
        ),
        default: (
                background: (
                        static: $global-button-background-color-default,
                        hover: $global-button-background-color-hover-default,
                        active: $global-button-background-color-active-default,
                        disabled: $global-button-background-color-disabled
                ),
                box-shadow: (
                        static: $global-button-box-shadow-default,
                        hover: $global-button-box-shadow-hover-default,
                        active: $global-button-box-shadow-active-default,
                        disabled: $global-button-box-shadow-disabled
                ),
                color: (
                        static: $global-button-color-default,
                        hover: $global-button-color-hover-default,
                        active: $global-button-color-active-default,
                        disabled: $global-button-color-disabled
                ),
                border-color: (
                        static: $global-button-border-color-default,
                        hover: $global-button-border-color-hover-default,
                        active: $global-button-border-color-active-default,
                        disabled: $global-button-border-color-disabled
                )
        ),
        outline-primary: (
                background: (
                        static: $global-button-background-color-outline-primary,
                        hover: $global-button-background-color-outline-primary,
                        active: $global-button-background-color-outline-primary,
                        disabled: $global-button-background-color-outline-disabled
                ),
                box-shadow: (
                        static: $global-button-box-shadow-outline-primary,
                        hover: $global-button-box-shadow-outline-primary,
                        active: $global-button-box-shadow-outline-primary,
                        disabled: $global-button-box-shadow-outline-disabled
                ),
                color: (
                        static: $global-button-color-outline-primary,
                        hover: $global-button-color-outline-primary,
                        active: $global-button-color-outline-primary,
                        disabled: $global-button-color-outline-disabled
                ),
                border-color: (
                        static: $global-button-border-color-outline-primary,
                        hover: $global-button-border-color-outline-primary,
                        active: $global-button-border-color-outline-primary,
                        disabled: $global-button-border-color-outline-disabled
                )
        ),
        outline-success: (
                background: (
                        static: $global-button-background-color-outline-success,
                        hover: $global-button-background-color-outline-success,
                        active: $global-button-background-color-outline-success,
                        disabled: $global-button-background-color-outline-disabled
                ),
                box-shadow: (
                        static: $global-button-box-shadow-outline-success,
                        hover: $global-button-box-shadow-outline-success,
                        active: $global-button-box-shadow-outline-success,
                        disabled: $global-button-box-shadow-outline-disabled
                ),
                color: (
                        static: $global-button-color-outline-success,
                        hover: $global-button-color-outline-success,
                        active: $global-button-color-outline-success,
                        disabled: $global-button-color-outline-disabled
                ),
                border-color: (
                        static: $global-button-border-color-outline-success,
                        hover: $global-button-border-color-outline-success,
                        active: $global-button-border-color-outline-success,
                        disabled: $global-button-border-color-outline-disabled
                )
        ),
        outline-danger: (
                background: (
                        static: $global-button-background-color-outline-danger,
                        hover: $global-button-background-color-outline-danger,
                        active: $global-button-background-color-outline-danger,
                        disabled: $global-button-background-color-outline-disabled
                ),
                box-shadow: (
                        static: $global-button-box-shadow-outline-danger,
                        hover: $global-button-box-shadow-outline-danger,
                        active: $global-button-box-shadow-outline-danger,
                        disabled: $global-button-box-shadow-outline-disabled
                ),
                color: (
                        static: $global-button-color-outline-danger,
                        hover: $global-button-color-outline-danger,
                        active: $global-button-color-outline-danger,
                        disabled: $global-button-color-outline-disabled
                ),
                border-color: (
                        static: $global-button-border-color-outline-danger,
                        hover: $global-button-border-color-outline-danger,
                        active: $global-button-border-color-outline-danger,
                        disabled: $global-button-border-color-outline-disabled
                )
        ),

);