@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.profile-page {
  .exchanges-content {
    border: 1px solid $global-colors-border-neutral-0;
    border-radius: fn-px2rem(16px);
    width: 50%;

    @media screen and (max-width: 1415px) {
      width: 100%;
    }

    .exchanges-table {
      height: fn-px2rem(400px);
    }
    .table-content {
      border-radius: fn-px2rem(16px);
      background: $global-colors-background-panel;
      border: 1px solid $global-colors-border-neutral-0;
      padding: fn-px2rem(16px);
    }

    .custom-table {
      display: grid;
      grid-template-columns: minmax(0, 100%);
      grid-template-rows: minmax(50px, auto) minmax(0, 100%);
      max-height: 100%;
      color: $global-colors-font-1;

      .scroll-container {
        overflow: auto;
      }

      &-row {
        display: grid;
        grid-template-columns: minmax(0, 45%) minmax(0, 35%) minmax(0, 10%) minmax(
            160px,
            10%
          );
        grid-template-rows: minmax(60px, auto);
        align-items: center;
        padding-left: fn-px2rem(14px);
        padding-right: fn-px2rem(14px);
        cursor: default;
        border-radius: fn-px2rem(4px);

        @media screen and (max-width: 714px) {
          grid-template-columns: minmax(0, 50%) minmax(0, 40%) minmax(0, 10%);
        }

        &.table-body {
          @media screen and (max-width: 714px) {
            padding-bottom: fn-px2rem(20px);
          }

          .custom-table-body-cell {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:last-child {
              overflow: unset;
              text-align: right;

              @media screen and (max-width: 714px) {
                text-align: unset;
              }
            }
          }

          &:nth-of-type(odd) {
            background: $global-colors-table-row-even;
            //color: $global-font-color-secondary;
          }

          &:hover {
            //background-color: $global-table-hover-color;
            //color: $global-font-color-primary;
          }
        }

        &.table-header {
          padding-left: fn-px2rem(14px);
          padding-right: fn-px2rem(14px);
          width: calc(100% - 20px);
          border-bottom: 1px solid $global-colors-border-neutral-3;
          border-radius: 0;
          white-space: nowrap;
        }
      }
    }
  }
}
