@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$empty-state-icon-light: url('/public/images/empty-state/empty-state-light.svg');
$empty-state-icon-dark: url('/public/images/empty-state/empty-state-dark.svg');
$icon-width: fn-px2rem(230px);
$icon-height: fn-px2rem(235px);

.topControls {
    display: flex;
    align-items: center;
    margin-top: fn-px2rem(30px);

    label {
        display: flex;

        span {
            margin-right: fn-px2rem(24px);
        }

        &:nth-child(1){
            margin-right: fn-px2rem(56px);
        }
  
        .react-toggle-thumb {
            border: none;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px rgba(113, 113, 113, 0.24);
        }

        .react-toggle--checked {
            .react-toggle-thumb {
                background: linear-gradient(180deg, #4674B6 0%, #2E62AC 100%);
                box-shadow: 0px 2px 4px rgba(24, 58, 106, 0.24);
            }
        }
    }
}

.top-elements {
    display: grid;
    grid-template-columns: 628px 1fr;
    grid-gap: fn-px2rem(16px);   
    margin-top: fn-px2rem(38px); 
    margin-bottom: fn-px2rem(16px);

    .left-side {
        border-radius: fn-px2rem(16px);
        padding: fn-px2rem(24px);

        h2 {
            font-size: fn-px2rem(16px);
            font-weight: 500;
            font-family: 'Roboto';
            margin-bottom: fn-px2rem(20px);
            text-transform: uppercase;
        }

        h4 {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: fn-px2rem(14px);
            color: #2B1A3D;
        }

        .selectOption {
            display: flex;
            align-items: center;

            .selectText {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .currency {
                    font-weight: 600;
                }
            }
        }

        img {
            margin-right: fn-px2rem(11px);
            width: fn-px2rem(18px);
            height: fn-px2rem(18px)
        }

        label {
            display: block;
            margin-bottom: 6px;
        }

    
        .divide {
            border: 1px solid #D4CEDF;
            margin: 40px 0;
        }

        .strategy {
            height: fn-px2rem(32px);
            display: flex;
            border-radius: fn-px2rem(8px);
            padding: fn-px2rem(2px);
            margin-top: fn-px2rem(26px);
            margin-bottom: fn-px2rem(22px);

            div {
                width: 50%;
                height: 100%;
                position: relative;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    cursor: pointer;
                }

                .btn {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    text-align: center;
                    background: white;
                    border-radius: 6px;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .hide-btn {
                    display: none;
                }
            }
        }

        .percentage {
            height: fn-px2rem(40px);
            width: 100%;
            margin-top: fn-px2rem(33px);
            margin-bottom: fn-px2rem(46px);
            position: relative;
            font-family: 'Inter';
            font-weight: 500;

            .line {
                height: fn-px2rem(18px);
                width: fn-px2rem(560px);
                display: flex;
                align-items: center;
                position: relative;
    
                .gray-line {
                    height: fn-px2rem(2px);
                    width: fn-px2rem(560px);
                    background: #B8B8B8;
                    border-radius: fn-px2rem(4px);
    
                    .blue-line {
                        width: fn-px2rem(112px);
                        height: 100%;
                        background: #4674B6;
                    }
                }
                .gray-circle {
                    width: fn-px2rem(10px);
                    height: fn-px2rem(10px);
                    background: #B8B8B8;
                    border-radius: 100%;
                    // border: 2px solid #F2F2F2;
                    position: absolute;
                }
                .gray-circle-five-percent {
                    left: fn-px2rem(112px);
                    // position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .gray-circle-ten-percent {
                    left: fn-px2rem(224px);
                    // position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .gray-circle-twenty-five-percent {
                    left: fn-px2rem(346px);
                    // position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .gray-circle-fifty-percent {
                    left: fn-px2rem(448px);
                    // position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .gray-circle-one-hundred {
                    left: fn-px2rem(560px);
                    // position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .blue-circle {
                    height: fn-px2rem(18px);
                    width: fn-px2rem(18px);
                    background: #4674B6;
                    border-radius: 100%;
                    position: absolute;
                    top: 0;
                    left: fn-px2rem(108px);
                    z-index: 10;
                    box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(29, 83, 255, 0.06), 0px 2px 4px rgba(25, 67, 200, 0.24);
                }
            }

            .percentage-text {
                .five-percent-text {
                    position: absolute;
                    left: fn-px2rem(109px);
                    bottom: 0;
                    font-size: fn-px2rem(12px);
                    color: #B8B8B8;
                }
    
                .ten-percent-text {
                    position: absolute;
                    left: fn-px2rem(216px);
                    bottom: 0;
                    font-size: fn-px2rem(12px);
                    color: #B8B8B8;
                }
    
                .twenty-five-percent-text {
                    position:  absolute;
                    left: fn-px2rem(338px);
                    bottom: 0;
                    font-size: fn-px2rem(12px);
                    color: #B8B8B8;
                }
    
                .fifty-percent-text {
                    position: absolute;
                    left: fn-px2rem(440px);
                    bottom: 0;
                    font-size: fn-px2rem(12px);
                    color: #B8B8B8;
                }
    
                .one-hundred-text {
                    position: absolute;
                    right: fn-px2rem(-5px);
                    bottom: 0;
                    font-size: fn-px2rem(12px);
                    color: #B8B8B8;
                } 
            }
        }

       
        .orders {
            border-top: 1px solid #D4CEDF;
            border-bottom: 1px solid #D4CEDF;
            padding-top: fn-px2rem(14px);

            h4 {
                margin-bottom: fn-px2rem(8px);
                font-weight: 500;
            }
        }

        .buy-orders {
            padding-top: fn-px2rem(14px);
        }

        .btn-create {
            background: #21B06E;
            color: white;
            width: fn-px2rem(580px);
            height: fn-px2rem(37px);
            font-size: fn-px2rem(14px);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: fn-px2rem(4px);
        }

        .upper-fild {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: fn-px2rem(24px);
            margin-bottom: fn-px2rem(20px);

            .upper-stop-loose {
                label {
                    display: flex;
                    margin-bottom: fn-px2rem(8px);

                    // .react-toggle-track {
                    //     background: #E2E3EF;
                    // }

                    .react-toggle-thumb {
                        border: none;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 4px rgba(113, 113, 113, 0.24);
                    }

                    .react-toggle--checked {
                        .react-toggle-thumb {
                            background: linear-gradient(180deg, #4674B6 0%, #2E62AC 100%);
                            box-shadow: 0px 2px 4px rgba(24, 58, 106, 0.24);
                        }
                    }
                }

                span {
                    margin-right: fn-px2rem(10px);
                }
            }

            .input-text {
                position: relative;

                .currency {
                    position: absolute;
                    top: 50%;
                    right: fn-px2rem(10px);
                    transform: translateY(-50%);
                    color: #E2E3EF;
                }
            }
        }

        .lower-limit {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: fn-px2rem(24px);
            margin-bottom: fn-px2rem(20px);

            .input-text {
                position: relative;

                .currency {
                    position: absolute;
                    top: 50%;
                    right: fn-px2rem(10px);
                    transform: translateY(-50%);
                    color: #E2E3EF;
                }
            }    
            
            .lower-limit-loss {
                label {
                    display: flex;
                    margin-bottom: fn-px2rem(8px);

                    span {
                        margin-right: fn-px2rem(10px);
                    }

                    .react-toggle-thumb {
                        border: none;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 4px rgba(113, 113, 113, 0.24);
                    }

                    .react-toggle--checked {
                        .react-toggle-thumb {
                            background: linear-gradient(180deg, #4674B6 0%, #2E62AC 100%);
                            box-shadow: 0px 2px 4px rgba(24, 58, 106, 0.24);
                        }
                    }
                }
            }
        }
        .grid-quantity {
            margin-bottom: fn-px2rem(20px);
        }
        .active-orders {
            margin-bottom: fn-px2rem(20px);
        }
        .quantity-per-grid {
            .quantity-per-grid-fields {
                display: grid;
                grid-template-columns: 104px 1fr;
                gap: fn-px2rem(15px);
                margin-bottom: fn-px2rem(16px);
            }
        }
        .amount {
            .input-text {
                position: relative;

                .currency {
                    position: absolute;
                    top: 50%;
                    right: fn-px2rem(10px);
                    transform: translateY(-50%);
                    color: #E2E3EF;
                }
            }
        }
        .balance {
            .balanceValue {
                color: #4674B6;
            }
        }
    }

    .right-side {
        border-top-left-radius: fn-px2rem(16px);
        border-top-right-radius: fn-px2rem(16px);
        display: flex;
        flex-direction: column;
        
        .chart-header {
            height: fn-px2rem(86px);
            border-top-left-radius: fn-px2rem(16px);
            border-top-right-radius: fn-px2rem(16px);
            display: flex;
            align-items: center;
            font-family: 'Inter';
            font-size: fn-px2rem(12px);
            box-shadow: inset 0px -1px 0px rgba(131, 131, 131, 0.28);

            div {
                margin-right: fn-px2rem(30px);

                p {
                    margin-bottom: fn-px2rem(7px);

                    &:last-child {
                        font-weight: 500;
                        // font-size: fn-px2rem(14px);
                        margin-bottom: 0;
                    }
                }

                &:first-child {
                    margin-left: fn-px2rem(24px);
                }
            }

            .height {
                color: #00A859;
            }
        }
        .chart {
            flex: 1;
        }
    }
}

.bottom-elements {
    display: flex;
    grid-template-columns: 2fr 1fr;
    grid-gap: fn-px2rem(16px);
    margin-bottom: fn-px2rem(80px);

    .left-side {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: fn-px2rem(16px);

        .search-by {
            height: fn-px2rem(125px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: fn-px2rem(23px);
            padding-bottom: fn-px2rem(16px);
            
            .links {
                padding-left: fn-px2rem(51px);

                a {
                    text-decoration: none;
                    margin-right: fn-px2rem(80px);
                    color: #707991;
                }
            }

            .fields {
                display: flex;
                align-items: center;
                padding: 0 24px;

                input[type='text']{
                    margin-right: fn-px2rem(24px);
                    flex: 1;
                    height: fn-px2rem(37px);
                    padding-left: fn-px2rem(11px);
                }

                input::placeholder {
                    font-size: fn-px2rem(14px);
                }

                button {
                    width: fn-px2rem(152px);
                    height: fn-px2rem(37px);
                    color: white;
                    font-size: fn-px2rem(14px);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #F93939;
                    border-radius: fn-px2rem(4px);
                }
            }
        }

        .display-results {
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .empty-search-state-icon {
                background-image: $empty-state-icon-light;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                height: $icon-height;
                width: $icon-width;
            }

            .results {
                height: 100%;
                display: flex;
                flex-direction: column;

                .exchange {
                    width: 80%;
                    display: grid;
                    grid-template-columns: 2fr 1fr 1.5fr 1fr 1fr 2fr 2fr 1fr; 
                    align-items: center;
                    height: fn-px2rem(73px);
                    padding-left: fn-px2rem(24px);
 
                    div {
                        a {
                            text-decoration: none;
                            font-size: fn-px2rem(14px);
                            width: 100%;

                            i {
                                margin-left: fn-px2rem(10px);
                            }
                        }
                    }
                }

                .values {
                    display: flex;
                    justify-content: space-between;
                    height: fn-px2rem(74px);
                    align-items: center;
                    padding-left: fn-px2rem(24px);
                
                    .pt-3 {
                        padding-top: 12px !important;
                    }

                    .left-side {
                        width: 80%;
                        height: 100%;
                        display: grid;
                        grid-template-columns: 2fr 1fr 1.5fr 1fr 1fr 2fr 2fr 1fr;  
                        border: none;                      

                        div {
                            a {
                                text-decoration: none;
                                font-size: fn-px2rem(14px);
                            }
                        }

                    }

                    .groupButtons {
                        border-radius: fn-px2rem(4px);
                        margin-right: fn-px2rem(24px);

                        a {
                            width: fn-px2rem(35px);
                            height: fn-px2rem(35px);
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            text-decoration: none;

                            &:nth-child(1){
                                border-top-left-radius: fn-px2rem(4px);
                                border-bottom-left-radius: fn-px2rem(4px);
                            }
                            &:nth-child(5){
                                border-top-right-radius: fn-px2rem(4px);
                                border-bottom-right-radius: fn-px2rem(4px);

                                .fa-play {
                                    color: #00A859;
                                }
                            }
                        }
                    }
                }

                .display {
                    flex: 1;
                    display: grid;
                    gap: fn-px2rem(70px);
                    font-size: fn-px2rem(14px);
                    font-family: 'Inter';
                    margin-left: fn-px2rem(24px);
                    margin-right: fn-px2rem(24px);
                    margin-bottom: fn-px2rem(30px);
                    padding-top: 10px;
                  
                    .left-column {
                        padding-left: fn-px2rem(15px);
                        padding-top: fn-px2rem(10px);
                        
                        div {
                            margin-bottom: fn-px2rem(16px);
                            
                            h5 {
                                margin-bottom: fn-px2rem(4px);
                                font-weight: 500;
                                font-size: fn-px2rem(14px);
                            }
                            p{
                                margin-top: fn-px2rem(0px);
                                font-size: 12px;
                            }
                            a {
                                text-decoration: none;
                                color: #4674B6;
                                font-size: 12px;
                            }
                        }
                    }

                    .center-column {
                        padding-top: fn-px2rem(10px);

                        .buttons {
                            height: fn-px2rem(32px);
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            border-radius: 8px;
                         
        
                            div {
                                height: 100%;
                                padding: fn-px2rem(2px);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                cursor: pointer;

                                button {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: fn-px2rem(6px);
                                    box-shadow: 0px 2px 4px rgba(29, 83, 255, 0.06);
                                    position: absolute;
                                    display: none;
                                }

                                .showBtn {
                                    display: block;
                                }
                            }
                        }

                        .current-line {
                            height: fn-px2rem(10px);
                            width: 100%;
                            display: flex;
                            align-items: center;
                            margin-top: fn-px2rem(54px);
                            position: relative;

                            .line {
                                border: 1px solid #34353F;
                                width: 100%;
                            }
        
                            .current {
                                height: 100%;
                                width: 2px;
                                border-left: 2px solid red;
                                position: absolute;
                                top: 0;
                                left: 0;

                                .text {
                                    display: flex;
                                    position: absolute;
                                    top: -20px;
                                    left: -20px;
                                    font-size: 12px;
                                    color: #00A859;

                                    span {
                                        margin-left: 6px;
                                        font-weight: 400;
                                    }
                                }
                            } 
                            .sl {
                                height: 100%;
                                width: fn-px2rem(2px);
                                border-left: 2px solid red;
                                position: absolute;
                                top: 0;
                                left: 80%;

                                .text {
                                    display: flex;
                                    position: absolute;
                                    top: fn-px2rem(-20px);
                                    left: fn-px2rem(-40px);
                                    font-size: fn-px2rem(12px);
                                    color: #F93939;
                                    font-weight: 700;

                                    span {
                                        margin-left: fn-px2rem(6px);
                                        font-weight: 400;
                                    }
                                }
                            }  
                            .tp {
                                height: 100%;
                                width: fn-px2rem(2px);
                                border-left: 2px solid red;
                                position: absolute;
                                top: 0;
                                right: 0;
                                font-weight: 700;

                                .text {
                                    display: flex;
                                    position: absolute;
                                    top: -20px;
                                    left: -40px;
                                    font-size: fn-px2rem(12px);
                                    color: #F93939;
                                    font-weight: 400;

                                    span {
                                        margin-left: fn-px2rem(6px);
                                    }
                                }
                            }                     
                        }
                    }

                    .right-column {
                        padding-top: fn-px2rem(10px);
                        padding-right: fn-px2rem(15px);

                        
                        h5 {
                            font-size: fn-px2rem(14px);
                            margin-bottom: fn-px2rem(4px);
                        }
                        p {
                            font-size: fn-px2rem(14px);
                            margin-bottom: fn-px2rem(4px);
                            display: flex;
                            justify-content: space-between;

                            .valute {
                                color: #00A859;
                                margin-left: fn-px2rem(11px);
                            }
                        }

                        button {
                            width: 100%;
                            height: fn-px2rem(37px);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: white;
                            background: #21B06E;
                            margin-top: fn-px2rem(11px);
                        }
                    }

                }

                .display-with {
                    grid-template-columns: 1.5fr 3fr 300px;
                }

                .display-with-full {
                    grid-template-columns: 200px 3fr 300px;
                }
            }
        }
    }

    
    .right-side {
        flex: 1;
        height: 100%;
        border: 1px solid #E8E8E8;
        border-radius: fn-px2rem(16px);
        background: #FAFAFA;
        padding: fn-px2rem(24px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        h3 {
            text-transform: uppercase;
            position: absolute;
            top: fn-px2rem(24px);
            left: fn-px2rem(24px);
            font-size: fn-px2rem(16px);
        }

        .empty-search-state-icon {
            background-image: $empty-state-icon-light;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: $icon-height;
            width: $icon-width;
        }

        p {
            color: #C3C3C3;
            font-size: fn-px2rem(14px);
            margin-top: fn-px2rem(27px);
            margin-bottom: 0;
        }
    }

    .upper-limit-color {
        color: #00A859;
    }

    .down-limit-color {
        color: #F93939;
    }

    .selected-color {
        color: #4674B6 !important;
    }

    .text-color {
        color: #2B1A3D;
    }
}

.day-theme {
    .topControls {
        .react-toggle-track {
            background: #E2E3EF;
        }

        .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
            background: #E2E3EF;
        }
    }

    .top-elements {
        .left-side {
            background: #FAFAFA;
            border: 1px solid #E8E8E8;

            input[type=text] {
                border: 1px solid #707991;

            }

            .strategy {
                background: #E8E8E8;
                color: #707991;

                div {
                    .btn {
                        background: #FFFFFF;
                        color:  #4674B6;
                    }
                }
            }

            .upper-stop-loose, .lower-limit-loss {
                .react-toggle-track {
                    background: #E2E3EF;
                }
            }

            .orders, .buy-orders {
                h4 {
                    color: #2B1A3D;
                }
            }
        }
        .right-side {
            border: 1px solid #E8E8E8;

            .chart-header {
                background: #FAFAFA;
                box-shadow: inset 0px -1px 0px rgba(131, 131, 131, 0.28);
            }
        }
    }

    .bottom-elements {
        .left-side {
            border: 1px solid #E8E8E8;
            background: #FAFAFA;

            .search-by {
                border-bottom: 1px solid #D4CEDF;
            }
            .exchange {
                a {
                    color: #2B1A3D;
                }
            }
            .values {
                border-top: 1px solid #D4CEDF;
                border-bottom: 1px solid #D4CEDF;
        
                .groupButtons {
                    background: #FFFFFF;
                    border: 1px solid #E8E8E8;
            
                    a {
                        color: #302F35;
            
                        &:nth-child(1){
                            border-right: 1px solid #E8E8E8;
                        }
                        &:nth-child(2){
                            border-right: 1px solid #E8E8E8;
                        }
                        &:nth-child(3){
                            border-right: 1px solid #E8E8E8;
                        }
                        &:nth-child(4){
                            border-right: 1px solid #E8E8E8;
                        }
                        &:nth-child(5){
                            border-right: 1px solid #E8E8E8;
                        }
                    }
                }
            }
            .display {
                background: #F2F2F2;
        
                .center-column {
                    .current-line {
                        span {
                            color: rgba(43, 26, 61, 0.8);
                        }
                    }
                    .buttons {
                        background: #E8E8E8;
        
                        div {
                            color: #707991;
        
                            button {
                                background: #FFFFFF;
                                color: #4674B6;
                            }
                        }
                    }
                }
            }
        }

        .right-side {
            border: 1px solid #E8E8E8;
            background: #FAFAFA;

            .chart-header {
                background: #FAFAFA;;
            }
        }
        
    }
}

.night-theme {
    .topControls {
        .react-toggle-track {
            background: #414456;
        }

        .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
            background: #414456;
        }
    }

    .top-elements {
        .left-side {
            background: #1E1F23;
            border: 1px solid rgba(255, 255, 255, 0.2);

            input[type=text]{
                background: #1E1F23;
                border: 1px solid rgba(255, 255, 255, 0.2);
            }

            .strategy {
                background: #414456;
                color:  #FFFFFF;
                
                div {
                    .btn {
                        background: #303240;
                        color: #FFFFFF;
                    }
                }
            }

            .upper-stop-loose {
                .react-toggle-track {
                    background: #414456;
                }
            }

            .lower-limit-loss {
                .react-toggle-track {
                    background: #414456;
                }
            }

            .orders, .buy-orders  {
                h4 {
                    color: #FFFFFF;
                }
            }

        }
        .right-side {
            border: 1px solid rgba(255, 255, 255, 0.2);

            .chart-header {
                background: #1E1F23;
                border: 1px solid rgba(255, 255, 255, 0.2);
                //box-shadow: inset 0px -1px 0px rgba(131, 131, 131, 0.28);
            }
        }
    }

    .bottom-elements {
        .left-side {
            border: 1px solid rgba(255, 255, 255, 0.2);
            background: #1E1F23;

            .search-by {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        
                .fields {
                    input[type="text"] {
                        background: #1E1F23;
                        color: #FDFBFF;
                        border: 1px solid rgba(255, 255, 255, 0.2);
                    }
                    input::placeholder {
                        color: #FDFBFF;
                    }
                }
            }
            .exchange {
                a {
                    color: #E6E6E6;
                }
            }
            .values {
                border-top: 1px solid rgba(255, 255, 255, 0.2);
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        
                .groupButtons {
                    background: #1E1F23;
                    border: 1px solid rgba(255, 255, 255, 0.2);
            
                    a {
                        color: #FFFFFF;
            
                        &:nth-child(1){
                            border-right: 1px solid rgba(255, 255, 255, 0.2);
                        }
                        &:nth-child(2){
                            border-right: 1px solid rgba(255, 255, 255, 0.2);
                        }
                        &:nth-child(3){
                            border-right: 1px solid rgba(255, 255, 255, 0.2);
                        }
                        &:nth-child(4){
                            border-right: 1px solid rgba(255, 255, 255, 0.2);
                        }
                        &:nth-child(5){
                            border-right: 1px solid rgba(255, 255, 255, 0.2);
                        }
                    }
                }
            }
            .display {
                background: #1B1C1F;
        
                .left-column {
                    h5 {
                        opacity: .5;
                    }
                }
        
                .center-column {
                    .current-line {
                        span {
                            color: rgba(255, 255, 255, 0.8);
                        }
                    }
        
                    .buttons {
                        background: #414456;
        
                        div {
                            color: #FFFFFF;
        
                            button {
                                background: #303240;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }

        .right-side {
            border: 1px solid rgba(255, 255, 255, 0.2);
            background: #1E1F23;

            .chart-header {
                background:  #1E1F23;
            }
        }
        
    }
}   

.bottom-elements-height {
    height: fn-px2rem(870px);
}
