@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.swap-layout {

  /* Remove spin button */
  /* Chrome, Safari, Edge, Opera */
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &.content {
    display: grid;
    grid-template-rows: auto minmax(0, 100%);
    grid-template-columns: 1fr;
    grid-row-gap: fn-px2rem(16px);
    max-height: 100%;
    padding-bottom: fn-px2rem(16px);
    padding-top: fn-px2rem(16px);
    height: 100%;
  }

  .input-control {
    text-align: right;
    width: fn-px2rem(320px);
  }

  .controls {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, fn-px2rem(552px)) auto auto;

    .chains-selector {
      margin-top: fn-px2rem(16px);
      width: fn-px2rem(552px);
    }

    .tabs-selector {
      margin-top: fn-px2rem(16px);
      box-sizing: border-box;
      width: fn-px2rem(552px);
      height: fn-px2rem(64px);
      background: $global-colors-background-panel;
      border: 1px solid $global-colors-border-neutral-0;
      border-radius: fn-px2rem(16px);

      &-item {
        cursor: pointer;
        color: $global-colors-font-2;
        padding-left: fn-px2rem(24px);
        padding-right: fn-px2rem(24px);

        &:active,
        &.active {
          color: $global-colors-link-default;
        }
      }

      &-clear {
        padding-left: fn-px2rem(160px);
      }
    }

    .swap-form {
      margin-top: fn-px2rem(16px);
      box-sizing: border-box;
      width: fn-px2rem(552px);
      height: fn-px2rem(400px);
      background: $global-colors-background-panel;
      border: 1px solid $global-colors-border-neutral-0;
      border-radius: fn-px2rem(16px);

      &-sell {
        margin: fn-px2rem(24px);

        box-sizing: border-box;
        height: fn-px2rem(128px);
        background: $content-background-color;
        border: 1px solid rgba(177, 181, 191, 1);
        border-radius: 4px;

        &-captions {
          font-size: fn-px2rem(12px);
          line-height: fn-px2rem(16px);
          margin-left: fn-px2rem(16px);
          color: $global-button-color-default;

          &-balance {
            margin-left: fn-px2rem(96px);
            width: fn-px2rem(280px);

            &-value {
              margin-left: fn-px2rem(8px);
            }
          }

          &-max {
            padding-left: fn-px2rem(8px);
            margin-left: fn-px2rem(8px);

            &-button {
              border-radius: 0px;
              font-size: fn-px2rem(10px);
              color: $global-colors-link-default;
              background: $global-colors-background-panel;
              width: fn-px2rem(32px);
              height: fn-px2rem(16px);
              margin: 0;
              padding: 0;
            }
          }
        }

        &-exchange {
          font-size: fn-px2rem(12px);
          line-height: fn-px2rem(16px);
          margin-left: fn-px2rem(16px);
          color: $global-button-color-default;

          &-token {
            width: fn-px2rem(192px);
          }

          &-balance {
            margin-left: fn-px2rem(16px);
            width: fn-px2rem(256px);
          }
        }

        &-input {
          margin: fn-px2rem(16px);

          &-token {
            color: $global-button-color-default;
            text-decoration: none;
            border: 1px solid $content-background-color;
            border-radius: 4px;

            &:hover {
              color: $global-button-color-default;
              text-decoration: none;
              border: 1px solid rgba(177, 181, 191, 1);
              border-radius: 4px;
              cursor: pointer;
            }
          }
        }
      }

      &-buy {
        margin: fn-px2rem(24px);

        box-sizing: border-box;
        height: fn-px2rem(128px);
        background: $global-colors-background-panel;
        border: 1px solid $global-colors-button-default;
        border-radius: 4px;

        &-captions {
          font-size: fn-px2rem(12px);
          line-height: fn-px2rem(16px);
          margin-left: fn-px2rem(16px);
          color: $global-button-color-default;

          &-balance {
            margin-left: fn-px2rem(80px);
            width: fn-px2rem(336px);

            &-value {
              margin-left: fn-px2rem(8px);
            }
          }
        }

        &-exchange {
          font-size: fn-px2rem(12px);
          line-height: fn-px2rem(16px);
          margin-left: fn-px2rem(16px);
          color: $global-button-color-default;

          &-token {
            width: fn-px2rem(192px);
          }

          &-balance {
            margin-left: fn-px2rem(16px);
            width: fn-px2rem(256px);
          }
        }

        &-input {
          margin: fn-px2rem(16px);

          &-token {
            color: $global-button-color-default;
            text-decoration: none;
            border: 1px solid $global-colors-background-panel;
            border-radius: 4px;

            &:hover {
              color: $global-button-color-default;
              text-decoration: none;
              border: 1px solid rgba(177, 181, 191, 1);
              border-radius: 4px;
              cursor: pointer;
            }
          }
        }
      }

      &-button {
        margin: fn-px2rem(24px);

        &-do {
          background: $global-colors-button-default;

          &:hover {
            background: $global-colors-button-hover;
          }
        }
      }

    }

    .swap-more {
      color: $global-button-color-default;
      font-weight: 500;
      font-size: fn-px2rem(14px);
      line-height: fn-px2rem(17px);
      position: relative;
      top: fn-px2rem(-10px);

      &-rate {
        margin-left: fn-px2rem(24px);
        margin-top: fn-px2rem(16px);

        &-value {
          margin-left: fn-px2rem(8px);
          width: fn-px2rem(352px);
        }

        &-show {
          margin-left: fn-px2rem(8px);
        }
      }

      &-route {
        margin-left: fn-px2rem(24px);
        margin-top: fn-px2rem(16px);

        &-value {
          margin-left: fn-px2rem(8px);
          width: fn-px2rem(432px);
        }

        &-show {
          margin-left: fn-px2rem(8px);
        }
      }
    }
  }
}

.tokens-layout {
  &.content {
    display: grid;
    grid-template-rows: auto minmax(0, 100%);
    grid-template-columns: 1fr;
    grid-row-gap: fn-px2rem(16px);
    max-height: 100%;
    padding-bottom: fn-px2rem(16px);
    padding-top: fn-px2rem(16px);
    height: 100%;
  }

  .controls {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, fn-px2rem(552px)) auto auto;

    .tokens-form {
      margin-top: fn-px2rem(16px);
      box-sizing: border-box;
      width: fn-px2rem(552px);
      height: fn-px2rem(864px);
      background: $global-colors-background-panel;
      border: 1px solid $global-colors-border-neutral-0;
      border-radius: fn-px2rem(16px);

      &-go-back {
        margin: fn-px2rem(24px);
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: fn-px2rem(16px);
        line-height: fn-px2rem(19px);

        &-chevron-left {
          border: 1px solid $global-colors-background-panel;
          border-radius: 4px;
          padding: fn-px2rem(4px);

          &:hover {
            border: 1px solid rgba(177, 181, 191, 1);
            border-radius: 4px;
            padding: fn-px2rem(4px);
            cursor: pointer;
          }
        }

        &-caption {
          padding: fn-px2rem(8px);
        }
      }

      .search-input {
        color: $global-font-color-primary;
        background-color: transparent;
        padding: fn-px2rem(24px);

        .custom-btn-search {
          cursor: pointer;
          transition: color .15s ease-in-out,
            background-color .15s ease-in-out,
            border-color .15s ease-in-out,
            box-shadow .15s ease-in-out;
          background: $global-colors-button-default;
          color: $global-button-color-primary;
          border-color: $global-colors-button-default;
          z-index: 1;

          &:hover {
            border-color: $global-colors-button-hover;
            color: $global-button-color-hover-primary;
            background: $global-colors-button-hover;
          }

          &:active,
          &.active {
            border-color: $global-colors-button-active;
            color: $global-button-color-active-primary;
            background: $global-colors-button-active;
          }
        }

        .clear {
          background: inherit;
          border-right: none;
          color: inherit;
        }
      }

      .selected-tokens {
        color: $global-font-color-primary;

        &-container {
          padding: 0;
          width: fn-px2rem(504px);

          &-item {
            cursor: pointer;
            padding: fn-px2rem(4px);
            margin-bottom: fn-px2rem(16px);
            width: fn-px2rem(104px);
            border: 1px solid #E8E8E8;
            ;
            border-radius: fn-px2rem(100px);
            background-color: $global-colors-table-row-even
          }
        }
      }

      .divider {
        padding-top: fn-px2rem(8px);
        padding-bottom: fn-px2rem(16px);

        .line {
          border-top: 1px solid #E8E8E8;
          ;
          width: fn-px2rem(496px);
          margin-left: fn-px2rem(24px);
        }
      }

      .all-tokens {
        color: $global-font-color-primary;
        max-height: 100%;
        color: $global-colors-font-1;

        .scroll-container {
          overflow: auto;
          height: fn-px2rem(544px);
        }

        &-container {
          padding: 0;
          width: fn-px2rem(504px);

          &-row {
            padding-top: fn-px2rem(16px);

            &-item {
              cursor: pointer;
              padding-left: fn-px2rem(16px);
            }

            &-balance {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: fn-px2rem(12px);
              line-height: fn-px2rem(15px);
              padding-left: fn-px2rem(16px);
            }

            &-balance-usd {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: fn-px2rem(16px);
              line-height: fn-px2rem(19px);
              text-align: right;
            }

            &-add-token {
              cursor: pointer;
            }

          }
        }
      }
    }
  }
}

.settings-layout {
  &.content {
    display: grid;
    grid-template-rows: auto minmax(0, 100%);
    grid-template-columns: 1fr;
    grid-row-gap: fn-px2rem(16px);
    max-height: 100%;
    padding-bottom: fn-px2rem(16px);
    padding-top: fn-px2rem(16px);
    height: 100%;
  }

  .controls {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, fn-px2rem(552px)) auto auto;

    .settings-form {
      margin-top: fn-px2rem(16px);
      margin-bottom: fn-px2rem(16px);
      box-sizing: border-box;
      width: fn-px2rem(552px);
      height: fn-px2rem(688px);
      background: $global-colors-background-panel;
      border: 1px solid $global-colors-border-neutral-0;
      border-radius: fn-px2rem(16px);

      &-go-back {
        margin: fn-px2rem(24px);
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: fn-px2rem(16px);
        line-height: fn-px2rem(19px);

        &-chevron-left {
          border: 1px solid $global-colors-background-panel;
          border-radius: 4px;
          padding: fn-px2rem(4px);

          &:hover {
            border: 1px solid rgba(177, 181, 191, 1);
            border-radius: 4px;
            padding: fn-px2rem(4px);
            cursor: pointer;
          }
        }

        &-caption {
          padding: fn-px2rem(8px);
        }
      }

      &-gas-price {
        margin: fn-px2rem(24px);

        &-info {
          &-icon {
            padding-left: fn-px2rem(12px);
            width: fn-px2rem(24px);
          }

          &-caption {
            padding-left: fn-px2rem(16px);
            width: fn-px2rem(160px);
          }

          &-selected {
            padding-right: fn-px2rem(8px);
            text-transform: capitalize;
          }
        }

        &-select {
          padding-top: fn-px2rem(16px);

          &-item {
            cursor: pointer;
            padding: fn-px2rem(4px);
            margin-left: fn-px2rem(8px);
            width: fn-px2rem(128px);
            height: fn-px2rem(38px);
            border: 1px solid #E8E8E8;
            border-radius: fn-px2rem(100px);

            &:active,
            &.active {
              background-color: $global-colors-table-row-even
            }
          }

          &-custom {
            margin-left: fn-px2rem(8px);

            &-input {
              width: fn-px2rem(128px);
              height: fn-px2rem(38px);
              border: 1px solid #E8E8E8;
              border-radius: fn-px2rem(100px);
              background: none;
              color: $global-font-color-primary;
              text-align: center;
            }
          }
        }
      }

      &-slippage {
        margin: fn-px2rem(40px) fn-px2rem(24px) fn-px2rem(24px) fn-px2rem(24px);

        &-info {
          &-icon {
            padding-left: fn-px2rem(12px);
            width: fn-px2rem(24px);
          }

          &-caption {
            padding-left: fn-px2rem(16px);
            width: fn-px2rem(240px);
          }

          &-selected {
            padding-right: fn-px2rem(8px);
            text-transform: capitalize;
          }
        }

        &-select {
          padding-top: fn-px2rem(16px);

          &-item {
            cursor: pointer;
            padding: fn-px2rem(4px);
            margin-left: fn-px2rem(8px);
            width: fn-px2rem(96px);
            height: fn-px2rem(38px);
            border: 1px solid #E8E8E8;
            border-radius: fn-px2rem(100px);

            &:active,
            &.active {
               background-color: $global-colors-table-row-even
            }
          }

          &-custom {
            margin-left: fn-px2rem(8px);

            &-input {
              width: fn-px2rem(128px);
              height: fn-px2rem(38px);
              border: 1px solid #E8E8E8;
              border-radius: fn-px2rem(100px);
              background: none;
              color: $global-font-color-primary;
              text-align: center;
            }
          }
        }
      }

      &-partial {
        margin: fn-px2rem(40px) fn-px2rem(24px) fn-px2rem(24px) fn-px2rem(24px);

        &-info {
          &-icon {
            padding-left: fn-px2rem(12px);
            width: fn-px2rem(24px);
          }

          &-caption {
            padding-left: fn-px2rem(16px);
            width: fn-px2rem(240px);
          }

          &-toggle {
            padding-right: fn-px2rem(8px);
          }
        }
      }

      &-routing {
        margin: fn-px2rem(40px) fn-px2rem(24px) fn-px2rem(24px) fn-px2rem(24px);

        &-info {
          &-icon {
            padding-left: fn-px2rem(12px);
            width: fn-px2rem(24px);
          }

          &-caption {
            padding-left: fn-px2rem(16px);
            width: fn-px2rem(240px);
          }

          &-toggle {
            padding-right: fn-px2rem(8px);
          }
        }
      }

      &-compatibility {
        margin: fn-px2rem(40px) fn-px2rem(24px) fn-px2rem(24px) fn-px2rem(24px);

        &-info {
          &-icon {
            padding-left: fn-px2rem(12px);
            width: fn-px2rem(24px);
          }

          &-caption {
            padding-left: fn-px2rem(16px);
            width: fn-px2rem(240px);
          }

          &-toggle {
            padding-right: fn-px2rem(8px);
          }
        }
      }


      &-liquidity {
        margin: fn-px2rem(40px) fn-px2rem(24px) fn-px2rem(24px) fn-px2rem(24px);

        &-info {
          &-icon {
            padding-left: fn-px2rem(12px);
            width: fn-px2rem(24px);
          }

          &-caption {
            padding-left: fn-px2rem(16px);
            width: fn-px2rem(240px);
          }

          &-qty {
            padding-right: fn-px2rem(8px);
          }

          &-more {
            border: 1px solid $global-colors-background-panel;
            border-radius: 4px;
            padding: fn-px2rem(4px);

            &:hover {
              border: 1px solid rgba(177, 181, 191, 1);
              border-radius: 4px;
              padding: fn-px2rem(4px);
              cursor: pointer;
            }
          }
        }
      }

      &-custom {
        margin: fn-px2rem(40px) fn-px2rem(24px) fn-px2rem(24px) fn-px2rem(24px);

        &-info {
          &-icon {
            padding-left: fn-px2rem(12px);
            width: fn-px2rem(24px);
          }

          &-caption {
            padding-left: fn-px2rem(16px);
            width: fn-px2rem(240px);
          }

          &-qty {
            padding-right: fn-px2rem(8px);
          }

          &-more {
            border: 1px solid $global-colors-background-panel;
            border-radius: 4px;
            padding: fn-px2rem(4px);

            &:hover {
              border: 1px solid rgba(177, 181, 191, 1);
              border-radius: 4px;
              padding: fn-px2rem(4px);
              cursor: pointer;
            }
          }
        }
      }

    }
  }
}