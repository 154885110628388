@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}



$radius: fn-px2rem(8px);
$gap: fn-px2rem(16px);

.smart-trade-bot {
  &-content {
    display: grid;
    grid-template-columns: fn-px2rem(228px) auto;
    grid-template-rows: 1fr;
    grid-column-gap: $gap;

    .content-body {
      border-radius: $radius;
      border: 1px solid $global-smart-trade-bot-border-color;
      height: 100%;
    }
  }

  &-panel-left {
    display: grid;
    grid-template-rows: auto minmax(fn-px2rem(150px), fn-px2rem(292px));
    grid-template-columns: 1fr;
    grid-row-gap: $gap;

    &-part-top {
      .content-body {
        border: none;
        display: grid;
        grid-template-rows: fn-px2rem(180px) auto;
        grid-row-gap: $gap;
        @import "tab_styles";
        background: $global-smart-trade-bot-left-panel-background-color;
      }
    }
    &-part-bottom {
      .content-body {
        border: none;
        background: $global-smart-trade-bot-background-color;
        @import "signals";
      }
    }
  }
  &-panel-main {
    display: grid;
    grid-template-rows: auto fn-px2rem(442px);
    grid-template-columns: 1fr;
    grid-row-gap: $gap;

    .main-visual-panel {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr fn-px2rem(300px);
      grid-column-gap: $gap;

      .panel-chart {
        .content-body {
          border: none;
          background: $global-smart-trade-bot-chart-background-color;
        }
      }
      .panel-glass {
        .content-body {
          border-top-left-radius: $radius;
          border-bottom-left-radius: $radius;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          border: none;
          background: $global-smart-trade-bot-glass-background-color;
        }
      }
    }
    .main-control-panel {
      .content-body {
        border-color: $global-smart-trade-bot-tab-border-color;
        @import "tab_styles";
      }
    }
  }
}