@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$font-color: rgba(255, 255, 255, 0.8);
// $background-color: rgba(39, 37, 48, 1);
$background-nav: rgba(5, 13, 22, 0.8);
$background: rgba(6, 15, 25, 1);
$footer-border-bottom-color: rgba(184, 182, 182, 1);
$footer-menu-heading-color: rgba(255, 255, 255, 0.5);
$footer-menu-hover-color: linear-gradient(94.22deg, #3688FF 11.02%, #0BAFFF 43.69%, #05CDF9 79.94%);
$active-link: rgba(15, 65, 170, 1);
$card-bg: rgba(30, 31, 35, 1);
$card-footer-bg: linear-gradient(94.22deg, #105DCD 11.02%, #0D298C 118.56%), #000000;

.invest-page {
    padding-bottom: 250px;

    .invest-start-content {
        display: grid;
        padding-left: 155px;
        grid-template-rows: auto 130px;
        grid-template-columns: minmax(0, 50%) minmax(0, 50%);
        background-image: url(/public/images/dashboard-library/home-page-images/stars.svg);
        background-repeat: no-repeat;
        background-size: cover;

        .left-column {
            padding-top: 300px;
        }

        .heading {
            margin-bottom: 0;
        }
    }

    .hold {
        margin-bottom: 285px;

        &-description {
            width: 700px;
            text-align: center;
        }

        &-heading {
            font-size: 50px;
        }
    }

    .statistics {
        &-heading {
            font-size: 50px;
        }

        &-content {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: minmax(0, 100%);
        }
    }

    .long-text {
        color: red;
        font-size: 50px;
    }

    .heading {
        width: 750px;
        line-height: 60px;
        font-size: 50px;
    }

    .description {
        width: 620px;
        margin-top: 22px;
        margin-bottom: 35px;
    }
}