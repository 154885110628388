@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$font-color: rgba(255, 255, 255, 255);
// $background-color: rgba(39, 37, 48, 1);
//$background-nav: rgba(5, 13, 22, 0.8);
$background-nav: rgba(7, 22, 39, 1);
$background: rgba(6, 15, 25, 1);
$footer-border-bottom-color: rgba(184, 182, 182, 1);
$footer-menu-heading-color: rgba(255, 255, 255, 0.5);
$footer-menu-hover-color: linear-gradient(94.22deg, #3688ff 11.02%, #0bafff 43.69%, #05cdf9 79.94%);
$active-link: rgba(15, 65, 170, 1);

.home-page {
  .beta {
    position: absolute;
    top: -5px;
    right: -10px;
    color: $font-color;
    width: 65px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 15px;
    background: linear-gradient(96.68deg, #0068ff 10.68%, #7000ff 83.55%);
    border-radius: 2px;
    font-size: 16px;
    font-weight: 300;
  }

  .navigation {
    & .navbar {
      width: 100%;
      background-color: $background-nav;
      padding: 0 fn-px2rem(20px);
      padding-top: fn-px2rem(40px);
      padding-bottom: fn-px2rem(40px);

      @media (min-width: 1440px) {
        padding-left: fn-px2rem(27px);
        padding-right: fn-px2rem(32px);
      }

      .burger-button {
        cursor: pointer;
      }

      .navbar-toggler {
        &:focus {
          color: transparent;
          border: none !important;
          outline: none !important;
        }
      }

      &-brand {
        color: $font-color;
        padding: 0;
        margin-right: fn-px2rem(80px);

        .active {
          border: none;
          background: none;
          color: $font-color;
        }
      }

      .nav-link {
        div {
          span {
            padding-bottom: 5px;
            color: $font-color;
            margin-right: fn-px2rem(55px);

            &:hover {
              background: $footer-menu-hover-color;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            &:active {
              background: $active-link;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              color: $active-link;
            }
          }

          .active-link {
            border-bottom: 1px solid #e5e5e5;
            background: $footer-menu-hover-color;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .launch-app-button {
        .btn-launch {
          background: transparent;
          color: $font-color;
          width: 100%;
          max-width: fn-px2rem(200px);
          padding: fn-px2rem(16px) fn-px2rem(32px);
          border-radius: fn-px2rem(6px);
        }
      }
    }
  }
  .free-beta {
    color: red;
    font-size: 15px;
  }

  .for-investors-wrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    background-color: $background;

    .content-img {
      margin-top: 300px;
    }

    .list {
      margin-bottom: 38px;
    }

    .invest-content {
      padding-top: 196px;
      padding-left: 155px;
      padding-bottom: 196px;

      .heading {
        width: 750px;
        line-height: 60px;
        font-size: 50px;
      }

      .description {
        width: 620px;
        margin-top: 22px;
        margin-bottom: 35px;
      }
    }
  }

  .get-started-wrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 50%);
    background-image: url(/public/images/dashboard-library/home-page-images/bgImg.svg);
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;

    .buy-button {
      padding-right: 155px;
    }

    .start-content {
      //padding-top: 335px;
      padding-left: 155px;

      .heading {
        line-height: 60px;
        font-size: 50px;
      }

      .description {
        margin-top: 22px;
        margin-bottom: 35px;
        width: 320px;
      }
    }
  }

  .trade-wrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    background-color: $background;

    .list {
      margin-bottom: 38px;
    }

    .trade-content {
      padding-top: 96px;
      padding-left: 155px;
      padding-bottom: 196px;

      .heading {
        width: 700px;
        line-height: 60px;
        font-size: 50px;
      }

      .description {
        width: 620px;
        margin-top: 22px;
        margin-bottom: 35px;
      }
    }
  }

  .footer {
    padding: fn-px2rem(40px) fn-px2rem(94px) 0;
    background: $background-nav;

    @media screen and (max-width: 992px) {
      padding: fn-px2rem(30px) fn-px2rem(15px) 0;
    }

    &-brand {
      a {
        color: $font-color;
      }
    }

    .footer-links-wrapper {
      padding: fn-px2rem(40px) 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: start;
        padding: fn-px2rem(30px) 0;
      }

      .footer-links {
        display: flex;

        @media screen and (max-width: 992px) {
          padding-bottom: fn-px2rem(40px);
        }

        &-item {
          padding-right: fn-px2rem(40px);

          a {
            font-size: fn-px2rem(18px);
            color: $font-color;
            font-weight: 300;

            &:hover {
              background: $footer-menu-hover-color;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            &:active {
              background: $active-link;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              color: $active-link;
            }
          }
        }
      }

      .footer-social {
        display: flex;

        &-item {
          margin-right: fn-px2rem(22px);

          &:last-child {
            margin-right: 0;
          }

          a {
            &:hover {
              text-shadow: 0 0 3px #ffffff;
              filter: drop-shadow(0px 0px 15px #ffffff);
            }

            &:active,
            &:focus {
              background: $active-link;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              color: $active-link;
              text-shadow: none;
              filter: none;
            }

            .icon {
              width: fn-px2rem(24px);
              height: fn-px2rem(24px);

              @media screen and (max-width: 992px) {
                width: fn-px2rem(48px);
                height: fn-px2rem(48px);
              }
            }
          }
        }
      }
    }

    .footer-copyright {
      color: rgba(117, 122, 129, 1);
      padding: fn-px2rem(22px) 0 fn-px2rem(40px);
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .reviews {
    &-heading {
      font-size: 50px;
    }
    margin-bottom: 221px;
  }

  .build-wrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    background-color: $background;

    .list {
      margin-bottom: 38px;
    }

    .build-content {
      padding-top: 291px;
      padding-left: 155px;
      padding-bottom: 196px;

      .heading {
        width: 700px;
        line-height: 60px;
        font-size: 50px;
      }

      .description {
        width: 620px;
        margin-top: 22px;
        margin-bottom: 35px;
      }
    }
  }

  .contacts-wrapper {
    position: relative;
    background-image: url(/public/images/dashboard-library/home-page-images/bgImg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: fn-px2rem(950px);

    @media (max-width: 768px) {
      background-image: none;
      height: 100%;
      background: black;
    }

    .start-content {
      width: 100%;
      padding-top: fn-px2rem(200px);
      z-index: 100;
      position: relative;
      padding: 0 fn-px2rem(20px);
      padding-top: fn-px2rem(200px);

      @media (min-width: 768px) {
        position: absolute;
      }

      @media (min-width: 1440px) {
        padding: fn-px2rem(0px) fn-px2rem(98px);
        padding-top: fn-px2rem(200px);
      }

      .heading {
        max-width: 100%;
        line-height: 150%;
        font-weight: 700;
        font-size: fn-px2rem(32px);
        font-family: 'Unbounded';
        color: #f4f5f8;
        margin-bottom: fn-px2rem(40px);

        @media (min-width: 1440px) {
          max-width: fn-px2rem(700px);
        }
      }

      .contacts {
        display: grid;
        grid-template-columns: 1fr;
        gap: fn-px2rem(30px);
        width: 100%;

        @media (min-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 1440px) {
          grid-template-columns: repeat(3, 1fr);
        }

        .contact {
          border: 1px solid white;
          height: fn-px2rem(176px);
          border-radius: fn-px2rem(8px);
          display: flex;
          align-items: center;
          color: white;
          width: 100%;
          padding-left: fn-px2rem(76px);

          &:hover,
          &:active {
            background: rgba(255, 255, 255, 0.1);
          }

          i {
            margin-right: fn-px2rem(70px);
            font-size: fn-px2rem(48px);
          }

          &:hover,
          &:active {
            // globe icon
            .fa-globe {
              background: -moz-linear-gradient(to right, #1b64ff 0%, #00d0ff 100%);
              background: -webkit-linear-gradient(to right, #1b64ff 0%, #00d0ff 100%);
              background: linear-gradient(to right, #1b64ff 0%, #00d0ff 100%);
              -webkit-background-clip: text;
              -moz-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            // twitter icon
            .fa-twitter {
              color: #1da1f2;
            }

            // instagram icon
            .fa-instagram {
              background: radial-gradient(
                circle at 30% 107%,
                #fdf497 0%,
                #fdf497 5%,
                #fd5949 45%,
                #d6249f 60%,
                #285aeb 90%
              );
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            // tiktok icon
            .fa-tiktok {
              color: #000000;
              text-shadow: 2.3px 2.3px #ff3353fc, -2.3px -2.3px cyan;
            }

            // facebook icon
            .fa-facebook {
              position: relative;
              z-index: 100;
              color: #1877f2;

              &:after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 1px;
                transform: translateX(-50%);
                width: fn-px2rem(40px);
                height: fn-px2rem(40px);
                border-radius: 50%;
                background: white;
                z-index: -10;
              }
            }

            // youtube icon
            .fa-youtube {
              position: relative;
              color: #ff0302;
              z-index: 100;

              &:after {
                content: '';
                width: 50%;
                height: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: white;
                z-index: -10;
              }
            }

            // telegram
            .fa-telegram {
              color: #2597d2;
              position: relative;
              z-index: 100;

              &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: fn-px2rem(30px);
                height: fn-px2rem(30px);
                background: white;
                z-index: -10;
              }
            }
          }

          p {
            font-size: fn-px2rem(32px);
            font-weight: 700;
            line-height: fn-px2rem(34.5px);
            font-family: 'Unbounded';
            color: white;
            margin-bottom: fn-px2rem(0px);
          }

          .smlTitle {
            font-size: fn-px2rem(23px);
          }
        }
      }
    }
  }

  //display: grid;
  color: $font-color;
  background-color: $background;
  //grid-template-columns: 1fr;
  //grid-template-rows: 1fr;
  // align-items: center;

  .rcs-inner-container {
    padding: 0;
  }

  .content {
    align-items: center;
  }
  .navigation-vertical-logo-icon {
    background-image: $logo-url;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: fn-px2rem(250px);
    width: fn-px2rem(250px);
  }

  a {
    text-decoration: none;
  }

  .application-link {
    color: $font-color;
  }

  @media screen and (max-width: 1024px) {
    min-width: 300px;

    .docs-page {
      padding-top: 50px;
      img {
        margin-bottom: 0;
      }
    }

    .navigation {
      & .navbar {
        padding-left: 16px;
        padding-right: 16px;
      }

      .navbar-expand-lg {
        .launch-app-button {
          margin-top: 15px;
        }
      }
    }
  }
}
