@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$rem-1-value: fn-px2rem(16px);

.upcoming-events-page {
  height: 100%;
  .upcoming-events-body {
    display: flex;
    flex-direction: column;
    .choice-selector {
      border-radius: $rem-1-value;
      border: 1px solid $global-colors-border-neutral-0;
      background: $global-colors-background-panel;
      height: fn-px2rem(175px);
      margin: 0 5%;

      @media (max-width: 768px) {
        height: fn-px2rem(100px);
        margin: 0;
      }

      .input-titles {
        padding-left: 10px;
        padding-top: 10px;
        justify-content: space-between;
        font-family: 'Inter';
        font-size: fn-px2rem(14px);
        font-weight: 500;
      }
      .transparent-input {
        background-color: transparent;
        border: 1px solid #ccc;
        outline: none;
        color: #000;
      }
      .input-group {
        padding-right: fn-px2rem(5px);
        padding-top: fn-px2rem(5px);
      }
      .buttons {
        display: flex;
        padding-top: fn-px2rem(25px);
        padding-right: fn-px2rem(20px);
        padding-left: fn-px2rem(25px);
        background-color: transparent;
        .button-clear {
          padding-right: 10px;
        }
      }
      .horizontal-divider {
        margin-top: fn-px2rem(40px);
        border-top: fn-px2rem(1px) solid;
        border-color: $bot-card-divider-color;
        width: 100%;
        height: 0;
      }
      .selector-wrapper {
        padding-top: fn-px2rem(10px);
        padding-left: fn-px2rem(10px);
        .selector-value {
          margin-right: fn-px2rem(10px);
          padding: fn-px2rem(3px);
          border: 1px solid;
          border-radius: fn-px2rem(6px);
          font-family: 'Inter';
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .body-wrapper {
      display: flex;
      margin: 2% 5%;
      height: fn-px2rem(152px);

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        height: auto;
        margin: 0;
      }

      .card {
        width: 100%;
        height: fn-px2rem(152px);
        background: $global-colors-background-panel;
        margin-right: 10px;

        @media (max-width: 768px) {
          margin-right: 0;
          margin-bottom: 10px;
        }

        .card-type {
          width: fn-px2rem(98px);
          height: fn-px2rem(20px);
          align-items: center;
          padding-left: fn-px2rem(10px);
          color: white;
          border-top-right-radius: fn-px2rem(10px);
          border-bottom-right-radius: fn-px2rem(10px);

          .type-title {
            padding-left: fn-px2rem(5px);
            width: fn-px2rem(50px);
            font-family: 'Inter';
            font-weight: 400;
            font-size: fn-px2rem(10px);
          }
          .question-icon {
            padding-left: fn-px2rem(5px);
          }
        }
        .date-card {
          justify-content: center;
          padding-top: fn-px2rem(20px);
          .coin-icon {
            height: fn-px2rem(20px);
          }
          .date-title {
            font-size: 14px;
            font-family: 'Inter';
            font-weight: 500;
          }
        }
        .card-description {
          display: flex;
          .title {
            padding-left: fn-px2rem(80px);
            font-family: 'Inter';
            font-weight: 500;
            font-size: 24px;
          }
        }
        .votes-counter {
          padding-left: fn-px2rem(100px);
          .votes-title {
            font-family: 'Inter';
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }

    .card-wrapper {
      display: flex;
      margin: 2% 5%;
      padding-bottom: fn-px2rem(40px);
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        height: auto;
        margin: 0;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        width: fn-px2rem(403px);
        background: $global-colors-background-panel;
        margin-right: 10px;

        @media (max-width: 768px) {
          width: 100%;
          margin: 0 0 10px;
        }
        .card-layout {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .title-icons {
            width: 20%;
            justify-content: space-between;
          }
          .title-data {
            background-color: orange;
          }
        }
        .network-data {
          padding-top: fn-px2rem(20px);
          width: 100%;
          justify-content: center;
          display: flex;
          flex-direction: column;
          text-align: center;
          .network-subtitle {
            font-family: 'Inter';
            font-weight: 400;
            font-size: fn-px2rem(14px);
            text-align: center;
          }
          .date {
            padding-top: fn-px2rem(20px);
            font-family: 'Inter';
            font-weight: 500;
            font-size: fn-px2rem(14px);
          }
          .network-title {
            padding-top: fn-px2rem(20px);
            font-family: 'Inter';
            font-weight: 500;
            font-size: fn-px2rem(24px);
          }
          .network-description {
            font-family: 'Inter';
            font-weight: 400;
            font-size: fn-px2rem(14px);
          }
        }
        .card-buttons,
        .votes-data {
          padding-top: fn-px2rem(20px);
          justify-content: space-between;
          display: flex;
          .votes-precent,
          .votes-count {
            font-family: 'Inter';
            font-weight: 500;
            font-size: fn-px2rem(14px);
            color: #00a859;
          }
        }
        .progres-bar {
          padding-top: fn-px2rem(10px);
        }
        .like-unlike-buttons {
          display: flex;
          padding-top: fn-px2rem(20px);
          justify-content: space-between;
          .like-description,
          .unlike-description {
            padding-left: fn-px2rem(5px);
          }
        }
        .added-wrapper {
          padding-top: fn-px2rem(30px);
          width: 100%;
          text-align: center;
          .added-date {
            font-family: 'Inter';
            font-weight: 500;
            font-size: fn-px2rem(14px);
          }
        }
      }
    }
    .news-wrapper {
      display: flex;
      height: fn-px2rem(272px);
      margin: 2% 5%;
      background-color: $global-colors-background-panel;
      border: 1px solid;
      border-radius: fn-px2rem(16px);

      .news-image {
        margin: 1%;
        width: fn-px2rem(300px);
        height: fn-px2rem(240px);
        border-radius: fn-px2rem(14px);
        background-image: var(--news-image-url);
      }

      .news-container {
        margin-top: 1%;
        text-align: center;
        width: fn-px2rem(54px);
        height: fn-px2rem(25px);
        border-radius: 100px;
        border: 1px solid;

        .container-title {
          font-family: 'Inter';
          font-weight: 400;
          font-size: fn-px2rem(14px);
        }
      }

      .news-title {
        width: fn-px2rem(700px);
        font-family: 'Inter';
        font-weight: 500;
        font-size: fn-px2rem(24px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .news-description {
        text-align: start;
        width: fn-px2rem(1000px);
        font-family: 'Inter';
        font-weight: 400;
        font-size: fn-px2rem(14px);
      }

      .news-date {
        width: fn-px2rem(100px);
        padding-left: 1%;
        padding-top: 1%;
        font-family: 'Inter';
        font-weight: 400;
        font-size: fn-px2rem(14px);
      }
    }
  }
}
