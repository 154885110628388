@import '_functions';
@import '_vars';
@import '_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

@import "_bootstrap";
@import "_fonts";
@import "_font-icons";
@import "_scroll-bar";
@import "_theme-mapping";
@import "_theme";
@import "_wrapper";

  html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;


    body {
      margin: 0;
      padding: 0;
      height: 100vh;
      width: 100%;
      position: relative;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      background-color: transparent;
      color: $global-font-color-primary;

      #root {
        height: 100%;
      }

      .cursor-pointer {
        cursor: pointer;
      }

      .sr-only {
        display: none;
      }

      .font {
        &-style {
          &-normal {
            font-style: normal;
          }
          &-italic {
            font-style: italic;
          }
        }
        &-size {
          @include font-sizes-generator(4, 40, 1);
        }
        &-weight {
          $list: ('thin': 100, 'light': 300, 'regular': 400, 'medium': 500, 'bold': 700, 'black': 900);
          @include font-weight-generator($list);
        }
        &-roboto {
          font-family: Roboto !important;
        }
        &-orbitron {
          font-family: Orbitron !important;
        }
        &-protosans {
          font-family: ProtoSans !important;
        }
        &-opensans {
          font-family: OpenSans !important;
        }
        &-inter {
          font-family: Inter !important;
        }
        &-unbounded {
          font-family: Unbounded !important;
        }
        &-qanelas {
          font-family: Qanelas !important;
        }
      }

      i.icon {
        &[class*=" icon-"] {
          color: $icon-color;
          transition: $global-transition;

          &.active {
            color: $icon-active-color;
            transition: $global-transition;
          }

          &:hover {
            color: $icon-active-color;
            transition: $global-transition;
          }
        }
      }

      .hover > i.icon {
        transition: $global-transition;

        &[class*=" icon-"] {
          color: $icon-active-color;
          transition: $global-transition;
        }
      }

      .btn:disabled,
      .btn.disabled,
      fieldset:disabled .btn {
        cursor: not-allowed;
        pointer-events: auto !important;
      }
    }
  }

  * {
    &, &:before, &:after {
      box-sizing: border-box;
    }
  }
