@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$font-color: rgba(255, 255, 255, 255);
$background: rgba(7, 22, 39, 1);

.trade-page {
  padding-top: fn-px2rem(131px);

  @media (min-width: 1440px) {
    padding-top: fn-px2rem(144px);
  }

  .trade-start-content {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 45%) minmax(0, 55%);
    padding: 0 fn-px2rem(50px) 0 fn-px2rem(94px);
    align-items: center;
    background: radial-gradient(61.87% 37.94% at 76.28% 52.15%, #2b254a 0%, $background 100%),
      $background;

    @media screen and (max-width: 992px) {
      grid-template-columns: minmax(0, 100%);
      padding: 0 fn-px2rem(15px);

      .circle-img {
        display: none;
      }
    }

    .trade-start-heading {
      font-size: fn-px2rem(80px);
      color: rgba(244, 245, 248, 1);
      margin: 0;

      @media screen and (max-width: 992px) {
        font-size: fn-px2rem(48px);
        padding-top: fn-px2rem(30px);
      }
    }

    .trade-start-description,
    .trade-start-subheading {
      color: rgba(205, 216, 248, 1);
    }

    .trade-start-subheading {
      padding-bottom: fn-px2rem(24px);

      @media screen and (max-width: 992px) {
        font-size: fn-px2rem(18px);
        padding-bottom: fn-px2rem(18px);
      }
    }

    .trade-start-description {
      padding-bottom: fn-px2rem(84px);
      margin: 0;

      @media screen and (max-width: 992px) {
        font-size: fn-px2rem(16px);
        padding-bottom: fn-px2rem(24px);
      }
    }

    .trade-start-buttons {
      display: flex;

      @media screen and (max-width: 992px) {
        flex-direction: column;
        margin-bottom: fn-px2rem(38px);
      }

      .trade-start-launch-app {
        @media screen and (max-width: 992px) {
          width: 100%;
        }

        .trade-start-btn {
          background: linear-gradient(0deg, #1f55fe, #1f55fe),
            linear-gradient(0deg, #00d0ff, #00d0ff),
            linear-gradient(186.52deg, #00d0ff 18.48%, #1b64ff 114.68%);
          padding: fn-px2rem(16px) fn-px2rem(32px);
          width: 100%;
          max-width: fn-px2rem(259px);
          border-radius: fn-px2rem(6px);
          margin-right: fn-px2rem(120px);
          font-size: fn-px2rem(24px);

          @media screen and (max-width: 992px) {
            justify-content: center;
            margin-bottom: fn-px2rem(30px);
            font-size: fn-px2rem(18px);
            max-width: unset;
          }

          &:hover {
            background: linear-gradient(186.52deg, #1b64ff 18.48%, #00d0ff 116.64%);
          }

          &:active {
            background: linear-gradient(0deg, #0836c4, #0836c4),
              linear-gradient(186.52deg, #00d0ff 18.48%, #1b64ff 114.68%);
          }
        }
      }

      .trade-start-how-it-works {
        display: flex;
        align-items: center;
        color: $font-color;
        cursor: pointer;
        user-select: none;

        &-play {
          width: fn-px2rem(64px);
          height: fn-px2rem(64px);
          background: linear-gradient(310.36deg, #0d1e32 16.77%, $background 74.96%);
          box-shadow: inset 1px 1px 0 rgba(255, 255, 255, 0.32);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-text {
          padding: 0 fn-px2rem(25px) 0 fn-px2rem(16px);
          font-size: fn-px2rem(24px);

          @media screen and (max-width: 992px) {
            font-size: fn-px2rem(18px);
          }
        }
      }
    }
  }

  .trade-barriers-content {
    padding: fn-px2rem(40px) fn-px2rem(94px);
    background: $background;

    @media screen and (max-width: 992px) {
      padding: 0 fn-px2rem(15px);
    }

    .trade-barriers-heading {
      font-size: fn-px2rem(36px);
      margin: 0;

      @media screen and (max-width: 992px) {
        font-size: fn-px2rem(28px);
      }
    }

    .trade-barriers-subheading {
      font-size: fn-px2rem(18px);
      color: rgba(205, 216, 248, 1);
      margin: 0;
      padding-bottom: fn-px2rem(40px);

      @media screen and (max-width: 992px) {
        padding-bottom: fn-px2rem(30px);
      }
    }

    .trade-barriers-list {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 33%));
      grid-template-rows: auto;
      gap: fn-px2rem(20px);

      @media screen and (max-width: 992px) {
        grid-template-columns: minmax(0, 100%);
      }

      &-item {
        background: rgba(255, 255, 255, 0.1);
        border-radius: fn-px2rem(8px);
        display: flex;
        align-items: center;
        padding: fn-px2rem(30px);

        @media screen and (max-width: 992px) {
          padding: fn-px2rem(20px);
        }

        .item-text {
          font-size: fn-px2rem(24px);
          padding-left: fn-px2rem(20px);

          @media screen and (max-width: 992px) {
            font-size: fn-px2rem(16px);
          }
        }
      }
    }
  }

  .trade-aggregated-chains-content {
    padding: fn-px2rem(40px) fn-px2rem(94px);
    background: $background;

    @media screen and (max-width: 992px) {
      padding: fn-px2rem(40px) fn-px2rem(15px);
    }

    .trade-aggregated-chains-heading {
      font-size: fn-px2rem(24px);
      color: rgba(47, 75, 107, 1);
      margin: 0;
      padding-bottom: fn-px2rem(40px);

      @media screen and (max-width: 992px) {
        font-size: fn-px2rem(18px);
      }
    }

    .trade-aggregated-chains-list {
      display: flex;
      justify-content: space-between;
      //flex-wrap: wrap;
      overflow: hidden;
      white-space: nowrap;

      @media screen and (max-width: 992px) {
        gap: fn-px2rem(40px);
      }

      // &-item {
      // 	img {
      // 		@media screen and (max-width: 992px) {
      // 			max-width: fn-px2rem(155px);
      // 			height: fn-px2rem(48px);
      // 		}
      // 	}
      // }
    }
    .trade-aggregated-chains-list .marquee-wrapper {
      display: inline-block;
      white-space: nowrap;
    }
    .marquee-item {
      margin-inline: 80px;
    }
    .trade-aggregated-chains-list .marquee {
      position: relative;
      animation-duration: 120s;
      //display: inline-block;
      animation-name: marquee;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      // padding-right: 50px;
    }

    @keyframes marquee {
      0% {
        transform: translateZ(0);
      }

      to {
        transform: translate3d(-100%, 0, 0);
      }
    }

    .marquee-item1 {
      margin-inline: 40px;
    }

    .trade-aggregated-chains-list .marquee1 {
      position: relative;
      animation-duration: 120s;
      //display: inline-block;
      animation-name: marquee;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: reverse;
      // padding-right: 50px;
    }

    @keyframes marquee1 {
      0% {
        transform: translateZ(100);
      }

      to {
        transform: translate3d(100%, 0, 0);
      }
    }
    .trade-aggregated-chains-want-to-try {
      margin-top: fn-px2rem(50px);

      @media screen and (max-width: 992px) {
        display: none;
      }
    }

    .trade-aggregated-chains-want-to-try-mobile {
      display: none;
      background: linear-gradient(90deg, #ec008c 0%, #fc6767 100%),
        linear-gradient(90deg, #9400d3 0%, #4b0082 100%), #1f55fe;
      border-radius: fn-px2rem(8px);
      margin-top: fn-px2rem(50px);
      padding: fn-px2rem(24px) fn-px2rem(16px) fn-px2rem(18px) fn-px2rem(16px);

      &-text {
        font-size: fn-px2rem(24px);
        font-weight: 700;
        padding-bottom: fn-px2rem(38px);
      }

      .launch-btn {
        background: linear-gradient(0deg, $font-color, $font-color),
          linear-gradient(0deg, #00d0ff, #00d0ff),
          linear-gradient(186.52deg, #00d0ff 18.48%, #1b64ff 114.68%);
        border-radius: 6px;
        font-size: fn-px2rem(28px);
        color: #f11d82;
        border-color: transparent;
        height: fn-px2rem(74px);
        width: 100%;
        justify-content: center;

        &:hover {
          background: $font-color;
          box-shadow: 0 12px 13px -5px rgba(255, 254, 254, 0.3);
        }

        &:active {
          background: #e6e6e6;
        }
      }

      @media screen and (max-width: 992px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .trade-how-it-works-content {
    padding: fn-px2rem(40px) fn-px2rem(94px);
    background: radial-gradient(61.87% 37.94% at 76.28% 52.15%, #2b254a 0%, #071627 100%), #071627;

    @media screen and (max-width: 992px) {
      padding: fn-px2rem(40px) fn-px2rem(15px) fn-px2rem(80px);
    }
    @media screen and (max-width: 1415px) {
      background: $background;
    }

    .trade-how-it-works-heading {
      font-size: fn-px2rem(30px);

      @media screen and (max-width: 992px) {
        font-size: fn-px2rem(28px);
        padding-bottom: fn-px2rem(20px);
      }
    }

    .carousel {
      .active.carousel-item {
        display: flex;
        justify-content: end;
        //margin-left: 30px;
      }
      .carousel-item {
        float: none;
        margin-right: 0;
      }
      .carousel-caption {
        top: fn-px2rem(100px);
        width: 100%;
        max-width: fn-px2rem(580px);
        left: 0;
        text-align: left;

        @media screen and (max-width: 1415px) {
          position: static;
          max-width: unset;
        }

        h3 {
          font-size: fn-px2rem(26px);
          font-weight: 700;
          margin: 0;
          padding-bottom: fn-px2rem(40px);

          @media screen and (max-width: 992px) {
            padding-bottom: fn-px2rem(20px);
            font-size: fn-px2rem(18px);
          }
        }

        p {
          font-size: fn-px2rem(24px);
          line-height: fn-px2rem(32px);
          color: rgba(211, 211, 211, 1);

          @media screen and (max-width: 992px) {
            font-size: fn-px2rem(14px);
          }
        }
      }

      img {
        min-height: fn-px2rem(671px);

        @media screen and (max-width: 1828px) {
          max-width: fn-px2rem(1000px);
        }

        @media screen and (max-width: 1415px) {
          display: none;
        }
      }

      .carousel-control-next,
      .carousel-control-prev {
        bottom: fn-px2rem(100px);
        top: auto;
        background: rgba(255, 255, 255, 0.1);
        border-radius: fn-px2rem(6px);
        width: fn-px2rem(72px);
        height: fn-px2rem(72px);
        display: flex;
        justify-content: center;

        @media screen and (max-width: 1415px) {
          bottom: fn-px2rem(-70px);
        }

        &:hover {
          background: rgba(0, 104, 255, 0.5);
        }

        &:active {
          background: rgba(0, 104, 255, 1);
        }
      }

      .carousel-control-prev {
        @media screen and (max-width: 1415px) {
          left: fn-px2rem(5px);
        }
      }

      .carousel-control-next {
        right: auto;
        left: fn-px2rem(100px);

        @media screen and (max-width: 1415px) {
          right: fn-px2rem(5px);
          left: auto;
        }
      }
    }
  }
}
