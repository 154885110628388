@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$rem-1-value: fn-px2rem(16px);

.smart-trade {
  &-info-container {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: $rem-1-value;

    .pair-row {
      height: $rem-1-value;
      line-height: $rem-1-value;
    }

    .content-controls {
      height: fn-px2rem(64px);
      border-radius: $rem-1-value;
      background: $global-colors-background-panel;
      align-items: center;
      grid-template-columns: max-content max-content max-content 100%;
      display: grid;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: fn-px2rem(16px);
      line-height: fn-px2rem(19px);
    }

    .table-content {
      border-radius: fn-px2rem(16px);
      background: $global-colors-background-panel;
      border: 1px solid $global-colors-border-neutral-0;
      padding: fn-px2rem(16px);

      // @media(max-width: 768px){
      //     margin-bottom: 100px;
      // }
    }

    .table-order {
      background: $global-colors-background-panel;
      // border: 1px solid $global-colors-border-neutral-0;
      padding: fn-px2rem(16px);
    }

    .orders-table {
      display: grid;
      grid-template-columns: minmax(0, 100%);
      grid-template-rows: minmax(50px, auto) minmax(0, 100%);
      max-height: 100%;
      color: $global-colors-font-1;

      .scroll-container {
        overflow: auto;
        max-height: fn-px2rem(300px);
      }

      .table-header {
        display: grid;
        grid-template-columns:
          minmax(0, 3%) minmax(0, 10%) minmax(0, 10%) minmax(0, 15%) minmax(0, 32%)
          minmax(0, 10%) minmax(0, 10%) minmax(0, 10%);
        grid-template-rows: minmax(60px, auto);
        align-items: center;
        padding-left: fn-px2rem(14px);
        padding-right: fn-px2rem(14px);
      }

      .table-header-order {
        display: grid;
        grid-template-columns:
          minmax(0, 15%) minmax(0, 20%) minmax(0, 10%) minmax(0, 10%) minmax(0, 15%)
          minmax(0, 20%) minmax(0, 10%);
        grid-template-rows: minmax(80px, auto);
        align-items: center;
        padding-left: fn-px2rem(50px);
      }
      .table-header-positions {
        display: grid;
        grid-template-columns:
          minmax(0, 20%) minmax(0, 10%) minmax(0, 20%) minmax(0, 20%) minmax(0, 15%)
          minmax(0, 15%);
        grid-template-rows: minmax(80px, auto);
        align-items: center;
        padding-left: fn-px2rem(50px);
      }

      &-row {
        .collapse-header {
          display: grid;
          grid-template-columns:
            minmax(0, 3%) minmax(0, 10%) minmax(0, 10%) minmax(0, 15%) minmax(0, 32%)
            minmax(0, 10%) minmax(0, 10%) minmax(0, 10%);
          grid-template-rows: minmax(60px, auto);
          align-items: center;
          padding-left: fn-px2rem(14px);
          padding-right: fn-px2rem(14px);
          cursor: default;
          border-radius: fn-px2rem(4px);
        }

        .collapse-row {
          .collapse-content {
            .collapse-content-wrapper {
              padding-bottom: 112px;

              .collapse-data {
                display: grid;
                grid-template-columns: minmax(0, 25%) minmax(0, 25%);
                grid-template-rows: auto;
                row-gap: 1rem;
                padding: 16px;
              }

              .sell-steps-wrapper {
                padding: 0 fn-px2rem(16px) 0 fn-px2rem(16px);

                .step-title {
                  color: $global-colors-font-0;
                  font-weight: 500;
                }

                .sell-steps {
                  display: grid;
                  grid-template-columns: minmax(0, 25%) minmax(0, 25%) minmax(0, 25%) minmax(0, 25%);
                  grid-template-rows: auto;

                  .step-item {
                    text-align: center;
                    margin-bottom: fn-px2rem(10px);
                    color: $global-colors-font-2;

                    &-header {
                      color: $global-colors-font-0;
                    }
                  }
                }
              }

              .active-trades {
                padding: fn-px2rem(16px) fn-px2rem(16px) 0 fn-px2rem(16px);

                .active-trade-item {
                  margin-right: fn-px2rem(80px);
                  margin-bottom: fn-px2rem(10px);
                  color: $global-colors-font-2;

                  &.horizontal {
                    display: inline-block;
                  }

                  &.vertical {
                    display: block;
                  }

                  .value {
                    color: $info-label-color;
                  }
                }
              }
            }
          }
        }

        &.table-body {
          .custom-table-body-cell {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &.range-cell {
              padding-left: 10px;
            }

            .more {
              cursor: pointer;
            }
          }

          .range-cell {
            width: 80%;
          }

          .valign-middle {
            align-items: center;
          }

          &:nth-of-type(odd) {
            background: $global-colors-table-row-even;
          }
        }

        &.table-header-order,
        &.table-header-positions {
          width: calc(100% - 20px);
          border-bottom: 1px solid $global-colors-border-neutral-3;
          padding-left: fn-px2rem(14px);
          padding-right: fn-px2rem(14px);
          border-radius: 0;
          white-space: nowrap;
        }
      }
    }
  }
}

.table-order-body {
  display: grid;
  grid-template-columns:
    minmax(0, 15%) minmax(0, 20%) minmax(0, 10%) minmax(0, 10%) minmax(0, 15%) minmax(0, 10%)
    minmax(0, 5%) minmax(0, 10%) minmax(0, 5%);
  grid-template-rows: minmax(80px, auto);
  align-items: center;
  &:nth-of-type(odd) {
    background: $global-colors-table-row-even;
  }
  .icon-refresh {
    padding-left: fn-px2rem(40px);
  }
  .actions-icon {
    color: #ff0000;
  }
  .type {
    width: 60%;
    overflow: auto;
  }
}
.table-positions-body {
  display: grid;
  grid-template-columns:
    minmax(0, 20%) minmax(0, 10%) minmax(0, 20%) minmax(0, 20%) minmax(0, 15%)
    minmax(0, 15%);
  grid-template-rows: minmax(80px, auto);
  align-items: center;
  padding-left: fn-px2rem(10px);
  &:nth-of-type(odd) {
    background: $global-colors-table-row-even;
  }
  .actions-icon {
    color: #ff0000;
  }
}
.table-order-body,
.table-positions-body {
  font: {
    font-family: 'Roboto';
    font-style: normal;
    weight: 400;
    size: fn-px2rem(14px);
    line-height: fn-px2rem(16px);
  }
}
