@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.wrapper {
  min-width: 0px;

  .main-container {
    position: relative;
    height: 100%;
    width: 100%;
    //background-color: $content-background-color;
  }

  .column {
    height: calc(100% - #{$navigation-bar-height});
    width: 100%;
    overflow: hidden;
    max-width: fn-px2rem(1920px);

    .inner-frame {
      @media(max-width: 768px){
        padding-right: fn-px2rem(0px);
      }
    }
  }

  .page-container {
    width: 100%;
    height: 100%;
    padding-left: fn-px2rem(16px);
    padding-bottom: fn-px2rem(16px);
  }
}