@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$font-path-roboto: '/public/fonts/roboto';

.profile-page {
  display: flex;
  position: relative;
  align-items: center;
}

.profile-page-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 10%;
  flex-wrap: wrap;

  @media (max-width: 550px) {
    align-items: center;
  }
}

.cards-wrapper {
  display: flex;
  flex-direction: column;

  @media (max-width: 550px) {
    width: 100%;
  }
}

.create-profile {
  width: fn-px2rem(400px);
  height: fn-px2rem(184px);
  box-sizing: border-box;
  border: fn-px2rem(1px) dashed #467486;
  border-radius: fn-px2rem(16px);
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  margin-right: 45px;

  @media (max-width: 1100px) {
    margin-right: 0;
  }

  @media (max-width: 550px) {
    width: 100%;
    margin: 0 12px;
  }

  .title {
    padding-top: fn-px2rem(30px);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: fn-px2rem(14px);
    line-height: fn-px2rem(16px);
  }

  .icon {
    padding-top: fn-px2rem(50px);
  }
}

.profile-card {
  display: flex;
  flex-direction: column;
  width: fn-px2rem(550px);
  height: auto;
  min-height: fn-px2rem(290px);
  box-sizing: border-box;
  border: fn-px2rem(1px) solid #467486;
  border-radius: fn-px2rem(16px);
  margin-bottom: 5%;

  @media (max-width: 1100px) {
    margin: 12px;
  }

  @media (max-width: 550px) {
    width: 100%;
    margin: fn-px2rem(12px);
  }

  .header-wrapper {
    width: 100%;
    height: fn-px2rem(120px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: fn-px2rem(1px) solid #467486;
    font-family: "Roboto";
    .image-wrapper {
      margin: 5% 3%;
      height: 70%;
      width: 15%;
      border-radius: 50%;
      border: fn-px2rem(1px) solid #467486;
      background-image: url("./profile.svg");
      background-position: top center;
      background-size: cover;
    }
    .user-data {
      .profile-name {
        font-size: fn-px2rem(18px);
        font-weight: 600;
      }
      .profile-status-wrapper {
        height: 15px;
        width: 100px;
        border-radius: fn-px2rem(15px);
        border: fn-px2rem(1px) solid #467486;
        text-align: center;
        background: linear-gradient(90.26deg, #0068ff 10.79%, #6100ff 91.01%);
        .title {
          font-size: fn-px2rem(10px);
          font-weight: 400;
        }
      }
      .wallet-container {
        font-size: fn-px2rem(14px);
        font-weight: 400;
      }
    }
    .selector {
      margin-left: auto;
      padding-right: 5%;
    }
  }
  .body-wrapper {
    height: auto;
    max-height: 50%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 3%;
    font-family: "Roboto";
    .icon-delete {
      margin-left: auto;
      cursor: pointer;
    }
    .text-button {
      font-size: fn-px2rem(14px);
      font-weight: 500;
      color: #4674b6;
      cursor: pointer;
    }
    .wallet-network {
      padding-top: 3%;
      font-weight: 600;
      font-size: fn-px2rem(14px);
    }

    .wallet-address-wrapper {
      display: flex;
      padding: 2%;
      .wallet-address {
        font-weight: 600;
        font-size: fn-px2rem(14px);
        padding-left: 1%;
      }
    }
    .networks-list {
      display: flex;
      width: 101px;
      height: 28px;
      padding: 0.5%;
      border-radius: fn-px2rem(16px);
      border: fn-px2rem(1px) solid #467486;
      text-align: center;
      // background-color: #6100FF;
      align-items: center;
      .crypto-title {
        padding-left: 5%;
      }
      .crypto-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.profile-created.modal {
  .modal-content {
    background: $global-colors-background-main;
    color: $global-font-color-primary;
    border-radius: fn-px2rem(16px);

    .modal-header {
      border: none;
      justify-content: right;
      padding-bottom: 0;

      .icon-cancel {
        color: $global-colors-font-0;
      }
    }

    .modal-body {
      padding-top: 0;

      .submit-button {
        display: flex;
        justify-content: space-between;

        button {
          width: 60%;
        }

        .start {
          background-color: $global-colors-button-default;
          color: $global-button-color-primary;
        }
      }
    }
  }
}

.backButton {
  display: flex;
  flex: auto;
  width: 33px;
  height: 17px;
  left: 640px;
  top: 244px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #4674b6;
  cursor: pointer;
  padding-left: 55px;
  padding-top: 25px;
}

.flex-container-edit-name {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background: #fafafa;
  width: 700px;
  height: 86px;
  left: 610px;
  top: 280px;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  margin-left: 50px;
  margin-top: 35px;
}

.profile-name-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 71px;
  height: 15px;
  left: 632px;
  top: 316px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #707991;
  white-space: nowrap;
}
.profile-edit-box {
  width: 438px;
  height: 37px;
  left: 714px;
  top: 305px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 10px;
  padding-left: 10px;
}
.profile-save-button {
  width: 120px;
  height: 32px;
  left: 1168px;
  top: 307px;
  background: #4674b6;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin: 10px;
}

.wallets-counter {
  display: flex;
  width: 53px;
  height: 19px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4674b6;
  text-align: center;
  padding-left: 55px;
  padding-top: 25px;
  white-space: nowrap;
}

.flex-container-add-wallet {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #f5f6fa;
  width: 700px;
  height: 86px;
  left: 610px;
  top: 280px;
  border: 2px dashed #4674b6;
  border-radius: 8px;
  margin-top: 35px;
  margin-left: 50px;
}

.add-wallet-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100px;
  height: 50px;
  left: 945px;
  top: 457px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4674b6;
  background-color: transparent;
  white-space: nowrap;
  border: none;
  cursor: pointer;
}

.flex-container-wallet-info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  align-items: stretch;
  padding: 15px;
  box-sizing: border-box;
  background: #fafafa;
  width: 700px;
  height: auto;
  left: 610px;
  top: 529px;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  margin: 50px;
  white-space: nowrap;
}

.wallet-status {
  padding-bottom: 25px;
  padding-top: 10px;
  display: flex;
  gap: 10px;
  width: 44px;
  height: 14px;
  left: 634px;
  top: 554px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #454655;
  .binance-color {
    color: #707991;
  }
}
.crypto-icon-edit {
  float: left;
}

.wallet-address {
  display: grid;
  padding-bottom: 25px;
  grid-template-columns: 1px 570px 10px;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  align-items: center;
  width: 279px;
  height: 14px;
  left: 656px;
  top: 577px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #454655;
}

.trash-icon {
  background: #707991;
  cursor: pointer;
}

.icon-wallet {
  display: flex;
  justify-content: center;
  gap: 3px;
  width: 60px;
  height: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #707991;
  padding: 5px 8px 5px 2px;
  gap: 4px;
  width: 96px;
  height: 28px;
  left: 632px;
  top: 602px;
  background: #f2f2f2;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
}

.fitContent {
  width: fit-content;
  block-size: fit-content;
}
