@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.team-page {
    padding: 200px fn-px2rem(98px);
    background-color: #0F131C;

    .header {
        font-size: 44px;
        background-image: url(/public/images/dashboard-library/home-page-images/team/team-wrapper.svg);
        background-repeat: no-repeat;
        margin-bottom: 100px;
        padding-top: 3px;
    }

    .departments {
        .department {
            margin-bottom: 150px;

            &-header {
                height: 35px;
                padding-left: 18px;
                font-size: 20px;
                background-image: url(/public/images/dashboard-library/home-page-images/team/dep-head.svg);
                background-repeat: no-repeat;

                .label {
                    cursor: pointer;
                    margin-top: -15px;
                }

                .up-down {
                    cursor: pointer;
                    margin-top: -15px;
                    font-size: 12px;
                }
            }

            .collapse-content {
                .department-members {
                    padding-top: 54px;
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 33%));
                    grid-template-rows: auto;
                    column-gap: 3rem;
                    row-gap: 3rem;

                    .member-card {
                        &-img {
                            padding-bottom: 57px;

                            .avatar {
                                padding: 16px;
                                background-image: url(/public/images/dashboard-library/home-page-images/team/img-wrapper.svg);
                                background-repeat: no-repeat;
                            }
                        }

                        .member-description {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .departments > .department > .collapse-content {
            .department-members {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 50%));
                grid-template-rows: auto;
                column-gap: 3rem;
                row-gap: 3rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 200px fn-px2rem(16px);

        .departments > .department > .collapse-content {
            .department-members {
                display: grid;
                grid-template-columns: minmax(0, 100%);
                grid-template-rows: auto;
                column-gap: 3rem;
                row-gap: 3rem;
            }
        }
    }
}