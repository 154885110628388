@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

@import "react-toastify/scss/main";
$notify-text-color: rgba(255, 255, 255, .8);


.moneyr-notify {

  .Toastify__close-button {
    color: $notify-text-color;
    opacity: 1;
  }

  .Toastify__progress-bar {
    background: gray;
  }

  &-body {
    font-family: Roboto;
  }

  &-toast {
    &.notify-class {

      &-danger {
        background-color: rgba(239, 106, 106, 1);
        color: $notify-text-color;
      }

      &-warning {
        background-color: rgba(210, 199, 68, 1);
        color: $notify-text-color;
      }

      &-success {
        background-color: rgba(139, 210, 68, 1);
        color: $notify-text-color;
      }

      &-info {
        background-color: rgba(68, 199, 210, 1);
        color: $notify-text-color;
      }
    }
  }
}