@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}


.widget-info {
    &-control-panel {
        .standard-options {
            padding-right: fn-px2rem(2px);
        }
        &-label {
            color: $info-label-color;
        }
    }
    &-empty-state-text {
        color: $info-empty-state-text-color;
    }
    &-element {

        .vertical-divider {
            width: 0;
            border: 1px solid $info-vertical-divider;
            height: calc(100% + 1rem);
            position: relative;
            top: -1rem;
        }
    }
    .grid {
        display: grid;
        grid-template-columns: minmax(0, 100%) 1px fn-px2rem(216px);
        grid-template-rows: 100%;
        max-width: 100%;
        height: 100%;
    }
    .feed-column {
        width: 100%;
    }
    .feed-content {
        height: calc(100% - 1rem);
    }
    .signal-content {
        height: calc(100% - 4rem);
    }
    .signal-column {
        min-width: fn-px2rem(216px);

        .reset-icon {
            color: $navigation-font-color;
            &:hover {
                color: $navigation-font-hover-color;
            }
        }
        .signal-search-component {
            padding-left: fn-px2rem(2px);
            padding-right: fn-px2rem(2px);
            span {
                background-color: $navigation-background-color;
                border-color: $bots-widget-border-color;
            }
        }

        .signal-search.form-control {
            border: 1px solid $info-vertical-divider;
            background: transparent;
            color: $global-font-color-primary;
            &:focus, &:active {
               box-shadow: none;
            }
        }
    }
   
}

.feed-message-wraper {
    margin: 0 5% 0 5%;
    height: 90%;
    padding: 1%;
    border-style: outset;
    border-radius: 2%;
    border-color: $global-table-border-color;
    display: flex;
    flex: 1;
}

