@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.main-vesting-content {
  .controls {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 332px) auto minmax(0, 256px);
    padding-bottom: fn-px2rem(16px);
  }

  .vesting-controls {
    display: grid;
    grid-template-columns: minmax(0, 45%) minmax(0, 20%) minmax(0, 35%);
    column-gap: fn-px2rem(16px);
    grid-template-rows: auto;

    .control-block {
      background-color: $global-colors-background-panel;
      border: 1px solid $global-colors-border-neutral-0;
      border-radius: fn-px2rem(16px);
      padding: fn-px2rem(24px);
      white-space: nowrap;

      .invest-cancel-buttons {
        text-align: right;
      }

      .total-buy-button {
        text-align: end;
      }

      .mcs-icon {
        width: 24px;
        height: 24px;
        border-radius: 100%;
      }

      .withdraw-grid {
        display: grid;
        grid-template-columns: minmax(0, 10%) minmax(0, 90%);
        grid-template-rows: auto;
      }

      .control-amount {
        display: grid;
        grid-template-columns: minmax(0, 15%) minmax(0, 35%) minmax(0, 20%) minmax(0, 30%);
        grid-template-rows: auto;
      }

      .control-inner-grid {
        display: grid;
        grid-template-columns: minmax(0, 50%) minmax(0, 50%);
        row-gap: fn-px2rem(24px);
        grid-template-rows: auto;
        column-gap: 16px;
        align-items: center;
        margin-bottom: fn-px2rem(16px);
      }

      .input-amount {
        border: 1px solid $global-colors-border-neutral-0;
        border-radius: 8px;

        .form-control {
          background: inherit;
          border: none;
          font-size: 14px;
          color: inherit;

          &:focus {
            border-color: #ced4da;
            box-shadow: none !important;
            z-index: 0;
          }
        }

        .input-group-text {
          background: inherit;
          border: none;
          color: $global-font-color-primary;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .vesting-controls {
      display: grid;
      grid-template-columns: minmax(0, 100%);
      column-gap: 0;
      row-gap: fn-px2rem(16px);
      grid-template-rows: auto;

      .control-block {
        .withdraw-grid {
          display: grid;
          grid-template-columns: minmax(0, 100%);
          row-gap: fn-px2rem(16px);
          grid-template-rows: auto;
        }

        .control-amount {
          display: grid;
          grid-template-columns: minmax(0, 50%) minmax(0, 50%);
          grid-template-rows: auto;
          row-gap: fn-px2rem(16px);
        }

        .control-inner-grid {
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: minmax(0, 70%) minmax(0, 30%);
          row-gap: fn-px2rem(16px);
          column-gap: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1850px) {
    .vesting-controls {
      .control-block {
        .control-inner-grid {
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: minmax(0, 70%) minmax(0, 30%);
          row-gap: fn-px2rem(16px);
          column-gap: 0;

          .usdt-value {
            grid-column: span 3 / 4;
          }

          .enter-usdt-value {
            grid-column: span 3 / 4;
          }

          .control-amount {
            grid-column: span 3 / 4;
          }
        }
      }
    }
  }

  .investors-buy-layout {
    .investors-grid {
      display: grid;
      grid-template-columns: minmax(0, 100%);
      grid-template-rows: auto;
      row-gap: fn-px2rem(24px);

      .wrapper {
        background-color: $global-colors-background-panel;
        border: 1px solid $global-colors-border-neutral-0;
        border-radius: fn-px2rem(16px);
      }

      .investors-period {
        padding: fn-px2rem(24px);
        display: grid;
        grid-template-columns: minmax(0, 40%) minmax(0, 15%) minmax(0, 15%);
        grid-template-rows: auto;
        column-gap: fn-px2rem(24px);
      }

      .investors-purchase {
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        column-gap: fn-px2rem(24px);
        padding-right: fn-px2rem(24px);
        padding-left: fn-px2rem(24px);

        .mobile-grid {
          display: grid;
          grid-template-columns: minmax(0, 25%) minmax(0, 25%) minmax(0, 25%) minmax(0, 25%);
          grid-template-rows: auto;

          .purchase-item {
            padding-top: fn-px2rem(24px);
            padding-bottom: fn-px2rem(24px);
          }

          img {
            position: absolute;
            top: -20px;
            bottom: -20px;
            margin: auto;
            right: 100px;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        .investors-period {
          margin-bottom: 1rem;
          display: grid;
          grid-template-columns: minmax(0, 100%);
          row-gap: fn-px2rem(16px);
          grid-template-rows: auto;
          column-gap: 0;
        }
        
        .investors-purchase {
          margin-bottom: 1rem;
          display: grid;
          height: auto;
          grid-template-columns: minmax(0, 100%);
          grid-template-rows: auto;
          column-gap: fn-px2rem(8px);

          .mobile-grid {
            display: grid;
            height: auto;
            grid-template-columns: minmax(0, 50%);
            grid-template-rows: auto;

            img {
              right: -20px;
            }
            
            .purchase-item {
              display: block !important;
            }
          }
        }
      }
    }

    .heading {
      font-size: 16px;
      font-weight: 400;
      color: $global-font-color-secondary;
    }

    .value {
      font-size: 28px;
      font-weight: 500;
    }

    .investors-buy-header {
      display: grid;
      grid-template-rows: auto;
      row-gap: fn-px2rem(16px);
      grid-template-columns: auto;
      padding-bottom: fn-px2rem(16px);

      &-title {
        font-size: 16px;
        font-weight: 500;
      }

      &-tip {
        font-size: 14px;
        font-weight: 400;
        color: $global-colors-link-default;
      }
    }
  }

  .vesting-layout {
    margin-bottom: fn-px2rem(24px);

    .slider {
      &.-primary {
        .rc-slider-track {
          height: 4px;
          background: $global-slider-track-background-color;
        }

        .rc-slider-rail {
          height: 4px;
          background: linear-gradient(270deg, #0068FF 3.51%, #FD1717 96.13%);
        }

        .rc-slider-mark-text {
          color: $global-colors-link-default;
        }

        .rc-slider-dot {
          border-color: white;
        }

        .rc-slider-handle {
          border: 1px solid #ffffff;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
          background-color: #ffffff;
        }
      }

      &.-danger {
        .rc-slider-track {
          height: 2px;
          background: $global-negative-color;
        }

        .rc-slider-rail {
          height: 2px;
        }

        .rc-slider-mark-text {
          color: $global-negative-color;
        }

        .rc-slider-dot {
          border-color: $global-negative-color;
        }

        .rc-slider-handle {
          border: 1px solid #ffffff;
          background: $global-negative-color;
        }
      }
    }

    .heading {
      font-size: 16px;
      font-weight: 400;
      padding-bottom: fn-px2rem(4px);
      color: $global-font-color-secondary;
    }

    .value {
      font-size: 28px;
      font-weight: 500;
    }

    .vesting-header {
      display: grid;
      grid-template-rows: auto;
      row-gap: fn-px2rem(16px);
      grid-template-columns: auto;
      padding-bottom: fn-px2rem(16px);

      &-title {
        font-size: 16px;
        font-weight: 500;
      }

      &-tip {
        font-size: 14px;
        font-weight: 400;
        color: $global-colors-link-default;
      }
    }

    .vesting-period {
      margin-bottom: fn-px2rem(16px);
      display: grid;
      background-color: $global-colors-background-panel;
      grid-template-columns: minmax(0, 33%) minmax(0, 10%) minmax(0, 10%) minmax(0, 14%) minmax(0, 33%);
      grid-template-rows: auto;
      column-gap: fn-px2rem(120px);
      border: 1px solid $global-colors-border-neutral-0;
      border-radius: fn-px2rem(16px);
      padding: fn-px2rem(24px);

      .slider-left-days {
        text-align: right;
        padding-bottom: fn-px2rem(16px);

        .days {
          color: rgba(247, 147, 26, 1);
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .vesting-period {
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: minmax(0, 100%);
        row-gap: fn-px2rem(16px);
        grid-template-rows: auto;
        column-gap: 0;
      }

      .vesting-controls {
        display: grid;
        grid-template-columns: minmax(0, 100%);
        column-gap: 0;
        row-gap: fn-px2rem(16px);
        grid-template-rows: auto;

        .control-block {
          .withdraw-grid {
            display: grid;
            grid-template-columns: minmax(0, 100%);
            row-gap: fn-px2rem(16px);
            grid-template-rows: auto;
          }

          .control-amount {
            display: grid;
            grid-template-columns: minmax(0, 50%) minmax(0, 50%);
            grid-template-rows: auto;
            row-gap: fn-px2rem(16px);
          }

          .control-inner-grid {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: minmax(0, 70%) minmax(0, 30%);
            row-gap: fn-px2rem(16px);
            column-gap: 0;

            // .usdt-value {
            //   grid-column: span 3 / 4;
            // }

            // .enter-usdt-value {
            //   grid-column: span 3 / 4;
            // }

            // .control-amount {
            //   grid-column: span 3 / 4;
            // }
          }
        }
      }
    }
  }

  .buy-layout {
    display: grid;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    column-gap: fn-px2rem(16px);
    grid-template-rows: auto;

    img {
      border-radius: 100%;
    }

    .description-content {
      border: 1px solid $global-colors-border-neutral-0;
      border-radius: fn-px2rem(16px);
      padding: fn-px2rem(24px);
      background-color: $global-colors-background-panel;

      .buy-description-info-grid {
        display: grid;
        grid-template-columns: minmax(0, 50%) minmax(0, 50%);
        grid-template-rows: auto;

        .table {
          color: $global-font-color-primary;
        }
      }

      .buy-range {
        height: 47px;
        width: 100%;
        border: 1px solid $global-colors-border-neutral-0;
        position: relative;
  
        .range-content {
          height: 100%;
          background: linear-gradient(90deg, #00D0FF 0%, #00B0FF 47%, #1B64FF 98%);
        }
      }
    }

    .buy-controls {
      &-section {
        height: 568px;
        border: 1px solid $global-colors-border-neutral-0;
        border-radius: fn-px2rem(16px);
        padding: fn-px2rem(24px);
        background-color: $global-colors-background-panel;

        .form-item {
          .right-input-button {
            background-color: inherit;
            color: $global-font-color-primary;
            border-left: none;

            .token-icon {
              border-radius: 100%;
            }
          }

          .form-control {
            font-size: 14px;
            background: inherit;
            color: inherit;
            border-radius: 4px;
            border-right: none;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
    
            &:focus {
              border-color: #ced4da;
              box-shadow: none !important;
              z-index: 0;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      grid-template-columns: minmax(0, 100%);
      row-gap: fn-px2rem(16px);

      .description-content {
        .buy-description-info-grid {
          display: grid;
          grid-template-columns: minmax(0, 100%);
          grid-template-rows: auto;
          row-gap: fn-px2rem(16px);
        }
      }
    }
  }
}

.cancel-vesting-modal {
  .modal-body {
    padding: 0;
    border: 1px solid $global-table-border-color;
    border-radius: 8px;
  }

  .vesting-modal-main-header {
    display: grid;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    grid-template-rows: auto;
    padding: fn-px2rem(16px) fn-px2rem(22px);
    border-bottom: 1px solid $global-table-border-color;

    .cancel-button {
      transition: all ease 0.3s;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .vesting-modal-main-content {
    padding: fn-px2rem(16px);

    .usdt-to-pay {
      &-value {
        color: rgba(247, 147, 26, 1) !important;
      }
    }

    .cancel-investment-button {
      display: grid;
      grid-template-columns: minmax(0, 100%);
      grid-template-rows: auto;
      background-color: $global-colors-background-panel;
    }
  }
}

.custom-scroll-bar .rcs-outer-container {
  cursor: default;
}
