@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$modal-border-color: rgba(255, 255, 255, 0.4);
$button-color: #302f35;
$positions-border-color: rgba(255, 255, 255, 0.5);

.careers-page {
  padding: fn-px2rem(200px) fn-px2rem(98px);
  background-color: #0f131c;
  color: #ffffff;

  @media screen and (max-width: 1024px) {
    padding: fn-px2rem(200px) fn-px2rem(16px);
  }
 

  .header {
    font-size: fn-px2rem(44px);
    background-image: url(/public/images/dashboard-library/home-page-images/team/team-wrapper.svg);
    background-repeat: no-repeat;
    margin-bottom: fn-px2rem(35px);
    padding-top: fn-px2rem(3px);
  }

  .title {
    font-size: fn-px2rem(20px);
    max-width: fn-px2rem(579px);
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .outer-borders {
    height: fn-px2rem(74px);
    padding: fn-px2rem(15px) fn-px2rem(30px) fn-px2rem(15px) fn-px2rem(30px);
    position: relative;
    background: $positions-border-color;
    -webkit-clip-path: polygon(
      0 0,
      96% 0,
      100% 40%,
      100% 100%,
      3% 100%,
      0 70%
    );
    clip-path: polygon(0 0, 96% 0, 100% 40%, 100% 100%, 3% 100%, 0 70%);
    @media screen and (max-width: 1024px) {
      padding-left: fn-px2rem(35px);
      padding-right: fn-px2rem(50px);
    }
  }

  .inside {
    position: absolute;
    display: flex;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: #0f131c;
    align-items: center;
    padding-left: fn-px2rem(35px);
    padding-right: fn-px2rem(50px);
    -webkit-clip-path: polygon(
      0 0,
      96% 0,
      100% 40%,
      100% 100%,
      3% 100%,
      0 70%
    );
    clip-path: polygon(0 0, 96% 0, 100% 40%, 100% 100%, 3% 100%, 0 70%);
    @media screen and (max-width: 1024px) {
      padding-left: fn-px2rem(35px);
      padding-right: fn-px2rem(50px);
    }
  }
  .positions-list {
    padding: fn-px2rem(54px) fn-px2rem(20px) 0 fn-px2rem(20px);
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));
    grid-template-rows: auto;
    column-gap: 8rem;
    row-gap: 5rem;

    @media screen and (max-width: 1024px) {
      grid-template-columns: minmax(0, 100%);
      padding: fn-px2rem(20px) 0 0 0;
      row-gap: 1.5rem;
    }
    
    .position-info {
      p {
        margin: 0;
      }

      &-header {
        align-items: center;
        justify-content: space-between;

        p {
          font-size: fn-px2rem(17px);
          font-weight: 500;
          padding-left: fn-px2rem(15px);
          @media screen and (max-width: 1024px) {
            margin-left: auto;
            font-size: inherit;
          }
        }
       
        button {
          color: $button-color;
          padding: fn-px2rem(8px) fn-px2rem(25px);
          max-width: fn-px2rem(126px);
          display: block;
          margin-left: auto;
          @media screen and (max-width: 1024px) {
            margin-left: auto;
          }
        }
      }
     
      &-content {
        border: 1px solid $positions-border-color;
        border-top: none;
        height: 80%;
        margin-left: fn-px2rem(18px);
        @media screen and (max-width: 1024px) {
        height: inherit;
         
        }
        .titles {
          font-size: fn-px2rem(16px);
          padding: fn-px2rem(30px) 0 fn-px2rem(20px) fn-px2rem(35px);
          font-weight: 500;
        }

        .description {
          font-size: fn-px2rem(16px);
          padding: 0 fn-px2rem(35px) fn-px2rem(30px) fn-px2rem(35px);
        }
      }
    }
  }
}

.careers-apply.modal {
  .modal-content {
    background: $global-colors-background-panel;
    border-radius: fn-px2rem(16px);
    color: $global-colors-font-0;

    .modal-header {
      padding: fn-px2rem(30px) fn-px2rem(24px);
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }

    .modal-body {
      padding: fn-px2rem(24px);

      input {
        background: $global-colors-background-panel;
        border: 1px solid $modal-border-color;
        color: $global-colors-font-0;
        font-size: fn-px2rem(14px);
        padding: fn-px2rem(7px) fn-px2rem(12px);
      }

      .form-control {
        &:focus {
          border-color: #ced4da;
          box-shadow: none !important;
          z-index: 0;
        }
      }

      .submit-button {
        button {
          color: $button-color;
          &:disabled {
            color: $global-colors-font-0;
          }
        }
      }

      .dropzone {
        padding: fn-px2rem(20px);
        border: 1px dashed $modal-border-color;
        width: 100%;
        cursor: pointer;

        &-content {
          font-size: fn-px2rem(14px);
        }

        &-accepted-files {
          display: flex;
          justify-content: space-between;
          background: #282828;
          padding: fn-px2rem(17px) fn-px2rem(21px) fn-px2rem(17px)
            fn-px2rem(13px);
          border-radius: fn-px2rem(8px);

          li {
            list-style: none;
            padding-left: fn-px2rem(10px);
            font-size: fn-px2rem(14px);
          }
        }
      }

      .successfully-applied {
        text-align: center;
        padding-top: fn-px2rem(40px);

        p {
          padding: fn-px2rem(50px) 0;
          font-size: fn-px2rem(18px);
          width: 100%;
          max-width: fn-px2rem(320px);
          margin: 0 auto;
        }
      }
    }
  }
}

.grecaptcha-badge { 
  visibility: hidden;
}