@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.utils-sign-container {
  font-size: 6px;
  text-align: center;
  background-color: transparent;

  .icon {
    line-height: 4px;
    background-color: $balances-frame-color;
    color: $global-font-color-primary;
    padding: 4px;
     &:first-child {
       border-top-left-radius: fn-px2rem(3px);
       border-top-right-radius: fn-px2rem(3px);
     }
    &:last-child {
      border-bottom-left-radius: fn-px2rem(3px);
      border-bottom-right-radius: fn-px2rem(3px);
    }

    &.active {
      color: $sorting-active-font-color;
      background-color: $global-font-color-secondary;
    }

    &:first-child {
      margin-bottom: 1px;
    }
  }
}