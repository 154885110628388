@use "sass:map";

.btn-custom.btn {
  border-radius: $btn-border-radius;
  padding-top: fn-px2rem(8px);
  padding-bottom: fn-px2rem(8px);
  padding-right: fn-px2rem(54px);
  padding-left: fn-px2rem(54px);
  border-width: $btn-border-width;
  border-style: $btn-border-style;

  //
  // Alternate buttons
  //

  // scss-docs-start btn-variant-loops
  @each $color, $value in $btn-custom-theme-colors {

    $btn-background: map.get($value, "background");
    $btn-box-shadow: map.get($value, "box-shadow");
    $btn-color: map.get($value, "color");
    $btn-border-color: map.get($value, "border-color");

    &.btn-#{$color} {
      color: map.get($btn-color, "static");
      background: map.get($btn-background, "static");
      border:$btn-border-width $btn-border-style map.get($btn-border-color, "static");
      box-shadow: map.get($btn-box-shadow, "static");

      &:hover {
        background: map.get($btn-background, "hover");
        color: map.get($btn-color, "hover");
        border: $btn-border-width $btn-border-style map.get($btn-border-color, "hover");
        box-shadow: map.get($btn-box-shadow, "hover");
      }

      &.btn-check:focus + &,
      &:focus {
        outline: 0;
        background: map.get($btn-background, "active");
        color: map.get($btn-color, "active");
        border: $btn-border-width $btn-border-style map.get($btn-border-color, "active");
        box-shadow: map.get($btn-box-shadow, "active");
      }

      &.btn-check:checked + &,
      &.btn-check:active + &,
      &:active,
      &.active {
        background: map.get($btn-background, "active");
        color: map.get($btn-color, "active");
        border: $btn-border-width $btn-border-style map.get($btn-border-color, "active");
        box-shadow: map.get($btn-box-shadow, "active");
      }

      &:disabled,
      &.disabled,
      fieldset:disabled & {
        pointer-events: none;
        color: map.get($btn-color, "disabled");
        background: map.get($btn-background, "disabled");
        border: $btn-border-width $btn-border-style map.get($btn-border-color, "disabled") !important;
        box-shadow: map.get($btn-box-shadow, "disabled");
      }
    }
  }
}