@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$btn-submit: #4674B6;
$btn-night-submit: white;

.submit-btn {
    font-size: fn-px2rem(14px);
    height: fn-px2rem(32px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.tick-icon {
    width: fn-px2rem(60px);
    height: fn-px2rem(60px);
    border: 1px solid #E8E8E8;
    margin: auto;
    margin-top: fn-px2rem(40px);
    margin-bottom: fn-px2rem(32px);
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .fa-check {
        color: #00A859;
    }
    .fa-xmark {
        color: #F93939;
    }
}


.DayModal {
    display: flex;
    justify-content: center;

    .modal-content {
        background-color: white;
        width: fn-px2rem(400px);
        border-radius: fn-px2rem(16px);
    
        form{
            padding: 0 5px;

            label {
                margin-bottom: fn-px2rem(3px);
            }
            .modal-title {
                font-size: fn-px2rem(16px); 
                font-weight: 500;
            }
            input[type='text'] {
                height: fn-px2rem(32px);
                font-size: fn-px2rem(14px);
            }
            .textarea {
                height: fn-px2rem(85px);
            }
            .customInputFile {            
                width: 100%;
                height: fn-px2rem(56px);
                color: #4674B6;
                background: #F5F6FA;
                border: 1px dashed #4674B6;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .submitBtnDisabled {
                background: #EAEFF2;
                color: #707991;
                @extend .submit-btn;
            }
            .submitBtnEnabled {
                background: $btn-submit;
                color: white;
                @extend .submit-btn;
            }
        }
    }
}

.NightModal {
    display: flex;
    justify-content: center;

    .modal-content {
        background-color: #1E1F23; 
        width: fn-px2rem(400px);
        border-radius: fn-px2rem(16px);
        box-shadow: 0px 29px 14px -17px rgba(0, 0, 0, 0.16); 

        
        .modal-header{
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);

            .modal-title {
                font-size: fn-px2rem(16px); 
                font-weight: 500;
                color: white;
            }
        }

        form{
            padding: 0 5px;

            label {
                margin-bottom: 3px;
                color: white; 
            }
            input[type='text'] {
                height: fn-px2rem(32px);
                font-size: fn-px2rem(14px);
                background-color: #1E1F23; 
                color: white; 
                border: 1px solid rgba(255, 255, 255, 0.2); 
            }
            
            input[type='email']{
                background-color: #1E1F23;
                color: white;
                border: 1px solid rgba(255, 255, 255, 0.2);
            }   
            textarea{
                height: fn-px2rem(85px);
                background-color: #1E1F23; 
                color: white; 
                border: 1px solid rgba(255, 255, 255, 0.2); 
            }
            .customInputFile {
                width: 100%;
                height: fn-px2rem(56px);
                color: white; 
                background: #1E1F23; 
                border: 1px dashed white; 
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .submitBtnDisabled {
                background: #414456; 
                color: #626367; 
                @extend .submit-btn;
            }
            .submitBtnEnabled {
                background: $btn-night-submit;  
                color: #302F35; //
                @extend .submit-btn;
            }
        }
    }
}

.DaySuccsessMessage {
    display: flex;
    justify-content: center;

    .modal-content {
        width: fn-px2rem(400px);
        border-radius: fn-px2rem(16px);
    }

    .tick {
        @extend .tick-icon
    }
    p {
        text-align: center;
        color: #302F35;
        font-size: fn-px2rem(14px);
        line-height: fn-px2rem(17px);
        margin-bottom: fn-px2rem(0px);
    }
    .closeBtn {
        @extend .submit-btn;
        background-color: $btn-submit;
        border-radius: fn-px2rem(4px);
        margin-top: fn-px2rem(32px);
    }
    .repeatLink {
        width: 100%;
        display: flex;
        justify-content: center;
            a {
                text-decoration: none;
                display: inline-block;
                margin-top: fn-px2rem(32px);
                font-size: fn-px2rem(14px);
            }
    }
    .mail {
        text-decoration: none;
    }
}

.NightSuccsessMessage {
    display: flex;
    justify-content: center;

    .modal-content {
        width: fn-px2rem(400px);
        border-radius: fn-px2rem(16px);
        background-color: #1E1F23;
        box-shadow: 0px 29px 14px -17px rgba(0, 0, 0, 0.16);

        .modal-header{
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            .modal-title {
                color: white;
            }
        }
    }

    .tick {
        @extend .tick-icon
    }
    p {
        text-align: center;
        color: white;
        font-size: fn-px2rem(14px);
        line-height: fn-px2rem(17px);
        margin-bottom: 0px;
    }
    .closeBtn {
        @extend .submit-btn;
        background-color: $btn-night-submit;
        color: #4674B6;
        border-radius: fn-px2rem(4px);
        border: none;
        margin-top: fn-px2rem(32px);
    }
    .repeatLink {
        width: 100%;
        display: flex;
        justify-content: center;
            a {
                text-decoration: none;
                display: inline-block;
                margin-top: fn-px2rem(32px);
                font-size: fn-px2rem(14px);
                color: white;
            }
    }
    .mail {
        text-decoration: none;
    }
}


.DayErrorMessage {
    display: flex;
    justify-content: center;

    .modal-content {
        width: fn-px2rem(400px);
        border-radius: fn-px2rem(16px);
    }
    .modal-body {
        .tick {
            @extend .tick-icon;
            border: 1px solid #E8E8E8;
        }

        p{
            text-align: center;
            font-size: fn-px2rem(14px);
            margin-bottom: 0px;
            a {
                text-decoration: none;
            }
        }
        
        .closeBtn {
            @extend .submit-btn;
            background-color: $btn-submit;
            border-radius: fn-px2rem(4px);
            margin: 32px 0;
        }
    }
    
}

.NightErrorMessage {
    display: flex;
    justify-content: center;

    .modal-content {
        width: fn-px2rem(400px);
        border-radius: fn-px2rem(16px);
        background-color: #1E1F23;
        border-bottom: 1px solid;
        box-shadow: 0px 29px 14px -17px rgba(0, 0, 0, 0.16);
    }
    .modal-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        .modal-title{
            color: white;
        }
    }
    .modal-body {
        .tick {
            @extend .tick-icon;
            border: 1px solid rgba(232, 232, 232, 0.25);
        }

        p{
            text-align: center;
            font-size: fn-px2rem(14px);
            color: white;
            margin-bottom: 0px;

            a {
                color: white;
                text-decoration: none;
            }
        }
        
        .closeBtn {
            @extend .submit-btn;
            background-color: $btn-night-submit;
            color: #4674B6;
            border-radius: fn-px2rem(4px);
            margin: 32px 0;
            border: none;
        }
    }
    
}

.Thumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: fn-px2rem(10px);

    div {
        display: flex;
        align-items: center;

        .DayFileNameText {
            color: black;
        }
        .NightFileNameText{
            color: white;
        }
    }
}

.closeIcon {
    color: red;
    &:hover {
        color: gray;
    }
}