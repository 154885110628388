@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.yield-card {
  &-container {
    // background: $bot-card-background-color;
    background-color: $global-colors-table-row-even;
    // border: 1px solid $table-widget-border-color;
    border-radius: fn-px2rem(16px);
    // width: fn-px2rem(281px);
    width: auto;
    height: fn-px2rem(203px);

  }
  &-content {
    &-header {
      display: grid;
      grid-template-columns: fn-px2rem(40px) minmax(0, 100%);
      grid-column-gap: fn-px2rem(16px);
      padding: fn-px2rem(11px) fn-px2rem(20px) fn-px2rem(16px);
    }

    &-body {
      padding: fn-px2rem(4px) fn-px2rem(20px) fn-px2rem(16px);
      display: grid;
      grid-row-gap: fn-px2rem(4px);

      .details {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-row-gap: fn-px2rem(4px);
      }

      .value {
        color: $global-font-color-secondary;
        opacity: .7;
        &-limitation {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: fn-px2rem(100px);
        }
      }


      .action-harvest {
        .button-harvest {
          position: relative;
          text-align: center;
          span {
            color: rgba(124, 124, 126, 1);
            position: absolute;
            top: fn-px2rem(4px);
            left: fn-px2rem(13px);
          }
        }
      }
    }


    &-horizontal-divider {
      border-top: 1px solid;
      border-color: $bot-card-divider-color;
      width: 100%;
      height: 0;
    }
  }
  .icons-set {
    position: relative;
    top: fn-px2rem(4px);
    & img:first-of-type {
      width: fn-px2rem(25px);
    }
    & img:last-of-type {
      width: fn-px2rem(32px);
      position: absolute;
      top: fn-px2rem(10px);
      left: fn-px2rem(10px);
    }
  }
}