@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}


.bots-grid-widget {

  &-controls {
    //@import 'src/styles/dropdown-select';
    .standard-options {
      padding-right: fn-px2rem(2px);
    }

    .a-link-container-wapper {
      text-decoration: none;
    }

    .bots-widget-title {
      color: $bots-widget-controls-title-color;
      white-space: nowrap;
    }
    .add-button-label {
      white-space: nowrap;
    }
  }

  &-element {
    height: 100%;
    position: relative;
  }

  &-content {
    height: calc(100% - #{fn-px2rem(32px)});
  }

  &-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: grid;
    grid-gap: fn-px2rem(16px);
    grid-template-columns: repeat(auto-fit, fn-px2rem(268px));
    align-content: flex-start;
    justify-content: center;

    &-element {
      // background: $bot-card-background-color;
      background-color: $global-colors-table-row-even;
      // border: 1px solid $table-widget-border-color;
      border-radius: fn-px2rem(8px);
      // width: fn-px2rem(274px);
      width: auto;
      height: fn-px2rem(132px);
      position: relative;

      .bot {
        &-card {
          color: $bot-card-font-main-color;
        }

        &-label {

          &-value {
            overflow: hidden;
            white-space: nowrap;
            width: fn-px2rem(110px);
            text-overflow: ellipsis;
          }
        }

        &-img {
          width: fn-px2rem(21px);
          height: fn-px2rem(21px);
        }

        &-controls {
          position: relative;
          top: fn-px2rem(-4px);
        }

        &-horizontal-divider {
          border-top: 1px solid;
          border-color: $bot-card-divider-color;
          width: 100%;
          height: 0;
        }

        &-info {
          &-start-date {
            border: 1px solid $bot-card-time-frame-color;
            border-radius: fn-px2rem(3px);
            display: inline-block;
            padding: fn-px2rem(2px);
          }

          &-profit-label, &-start-date {
            color: $bot-card-font-slave-color;
            white-space: nowrap;
          }
        }

        &-profit-value {

          &.positive {
            color: $global-positive-color;
            white-space: nowrap;

            .bot-profit-sign-icon {
              background-color: $global-positive-color;
              opacity: 0.5;
            }
          }

          &.negative {
            color: $global-negative-color;
            white-space: nowrap;

            .bot-profit-sign-icon {
              background-color: $global-negative-color;
              opacity: 0.5;
            }
          }

        }

        &-profit-sign-icon {
          width: fn-px2rem(10px);
          height: fn-px2rem(10px);
          border-radius: 100%;
          display: inline-block;
          top: fn-px2rem(-1.5px);
          position: relative;
        }
      }
    }
  }
}

