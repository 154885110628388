@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$dropdown-select-menu-font-color: rgba(112, 121, 145, 1);
$dropdown-select-menu-font-active-color: rgba(28, 82, 254, 1);
$dropdown-selector-border-color: rgba(206, 212, 218, 1);

:export {
    selectBackgroundColor: $content-background-color;
    selectFontColor: $global-font-color-primary;
    fontColor: $dropdown-select-menu-font-color;
    fontColorActive: $dropdown-select-menu-font-active-color;
    transitionOption: $global-transition;
    borderSelectColor: $dropdown-selector-border-color;
    iconColor: $dropdown-selector-border-color;
    buttonRemoveHoverColor: $global-button-color-hover-default;
    buttonRemoveColor: $global-button-color-default;
}