@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.custom-table-wrapper {
    .table-content {
        border-radius: fn-px2rem(16px);
        background: $global-colors-background-panel;
        border: 1px solid $global-colors-border-neutral-0;
        padding: fn-px2rem(16px);
    }
    .custom-header-pools{
        grid-template-columns:minmax(0, 5%) minmax(0, 10%) minmax(0, 15%) minmax(0, 35%) minmax(0, 30%) minmax(0, 10%) ;
         column-gap: 0 ;
         
     }
    .custom-table {
        display: grid;
        grid-template-columns: minmax(0, 100%);
        grid-template-rows: minmax(50px, auto) minmax(0, 100%);
        max-height: 100%;
        color: $global-colors-font-1;
    
        .scroll-container {
            overflow: auto;
            margin-right: -20px !important ;
        }
        .custom-inner-row{
           // grid-template-columns: minmax(0, 5%) minmax(0, 30%) minmax(0, 10%) minmax(0, 10%) minmax(0, 15%) minmax(0, 10%) minmax(0, 10%) !important;
           grid-template-columns:minmax(0, 5%) minmax(0, 22%) minmax(0, 24%) minmax(0, 20%) minmax(0, 10%) minmax(0, 8%) minmax(0, 4%) minmax(0, 6%) !important;
            column-gap: 0 !important;
        }

      
    
        &-row {
            display: grid;
            grid-template-columns:minmax(0, 40%) minmax(0, 25%) minmax(0, 25%) minmax(0, 10%);
            grid-template-rows: minmax(50px, auto);
            align-items: center;
            padding-left: fn-px2rem(16px);
            padding-right: fn-px2rem(16px);
            column-gap: 16px;
            cursor: default;
            
    
            &.table-body {
                .custom-table-body-cell {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
    
                .valign-middle {
                    align-items: center;
                }
    
                &:nth-of-type(odd) {
                    background: $global-colors-table-row-even;
                    //color: $global-font-color-secondary;
                }
    
                &:hover {
                    //background-color: $global-table-hover-color;
                    //color: $global-font-color-primary;
                }
            }
    
            &.table-header {
                padding-left: fn-px2rem(14px);
                padding-right: fn-px2rem(14px);
                width: 100%;
                border-bottom: 1px solid $global-colors-border-neutral-3;
                border-top: 1px solid $global-colors-border-neutral-3;
                border-radius: 0;
                white-space: pre-wrap;
            }
        }
    }
}