@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$empty-state-icon-light: url('/public/images/empty-state/empty-state-light.svg');
$empty-state-icon-dark: url('/public/images/empty-state/empty-state-dark.svg');
$icon-width: fn-px2rem(230px);
$icon-height: fn-px2rem(235px);

.wrapper {
  &.day-theme {
    .empty-search-state-icon {
      background-image: $empty-state-icon-light;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: $icon-height;
      width: $icon-width;
    }
  }
  &.night-theme {
    .empty-search-state-icon {
      background-image: $empty-state-icon-dark;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: $icon-height;
      width: $icon-width;
    }
  }
  .component-empty-state {
    color: $standard-options-controls-color;

    .text {
      width: 100%;
      max-width: fn-px2rem(368px);
    }
  }
}