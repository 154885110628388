@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.remake-dashboard-visual {
    &-content {
      // background: rgb(48, 47, 53);
      background: #0F131C;
      height: 100%;
      &-top {
        position: relative;
        z-index: 3;
        .images-background-grid {
          display: grid;
          grid-template-columns: 35% 30% 35%;
          width: 100%;
  
          .image-left-part {
            width: 100%;
            position: relative;
            top: fn-px2rem(-16px);
          }
          .image-middle-part {
            width: 100%;
          }
          .image-right-part {
            width: 100%;
          }
        }
      }

      &-bottom {
        padding-right: 0;
        padding-left: 0;

        .content-grid {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: minmax(auto, 650px);
          column-gap: fn-px2rem(24px);
          row-gap: fn-px2rem(24px);
          position: relative;

          .title {
            color: $global-colors-font-2;
            font-weight: 300;
            font-size: fn-px2rem(30px);
            line-height: 1;
          }

          .vaults-block {
            overflow: hidden;
            height: 100%;
            background-color: $global-colors-background-panel;
            border-radius: fn-px2rem(16px);
            display: grid;
            grid-template-columns: minmax(0, 100%);
            grid-template-rows: auto;
            row-gap: fn-px2rem(16px);
            position: relative;

            .harvest-section {
              display: grid;
              grid-template-columns: minmax(0, 50%) minmax(0, 50%);
              grid-template-rows: auto;
              align-items: end;
              column-gap: fn-px2rem(16px);

              @media screen and (max-width: 768px) {
                grid-template-columns: minmax(0, 100%);
                column-gap: 0;
                row-gap: fn-px2rem(16px);
              }

              &-button-color {
                margin-bottom: fn-px2rem(16px);
                color: rgba(48, 47, 53, 1);

                @media screen and (max-width: 768px) {
                  margin-bottom: 0;
                }
              }

              .harvest-left {
                &-header {
                  font-size: fn-px2rem(32px);
                  color: $global-colors-font-3;
                }
    
                &-title {
                  color: $global-colors-font-2;
                }
              }
            }

            .resize-icon {
              position: absolute;
              top: fn-px2rem(16px);
              right: fn-px2rem(16px);
              font-size: 24px;
              cursor: pointer;
            }

            .vaults-grid {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-template-rows: auto;
              row-gap: fn-px2rem(16px);
              column-gap: fn-px2rem(16px);

              @media screen and (max-width: 1024px) {
                grid-template-columns: minmax(0, 100%);
                column-gap: 0;
                row-gap: fn-px2rem(16px);
              }
            }
          }

          @media screen and (max-width: 1024px) {
            grid-template-columns: minmax(0, 100%);
            column-gap: 0;
            row-gap: fn-px2rem(16px);
          }
        }
      }

      &-middle {
        position: relative;
        z-index: 3;
        display: grid;
        grid-template-columns: minmax(0, 100%);
        grid-template-rows: auto;
        row-gap: fn-px2rem(46px);

        .collapse-block {
          display: grid;
          grid-template-columns: minmax(0, 100%);
          grid-template-rows: auto;

          .collapse-header {
            padding: fn-px2rem(32px) fn-px2rem(16px);
            background-color: #0F182E;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            grid-template-rows: auto;
            border-top: 1px solid #203159;
          }

          .collapse-content {
            position: relative;
            z-index: 20;
            height: calc(100% - 100px);
            overflow: hidden;
            background-color: #0F182E;
            box-shadow: inset 0px 9px 11px -7px rgba(0,0,0,0.75);

            .cefi-assets-collapse-content {
              height: 300px;
              box-shadow: inset 0px 9px 11px -7px rgba(0,0,0,0.75);

              .table-assets {
                height: 296px;
                border-bottom: none;
                padding: fn-px2rem(16px) fn-px2rem(16px) fn-px2rem(0px) fn-px2rem(16px);
              }
            }

            .cefi-collapse-content {
              box-shadow: inset 0px 9px 11px -7px rgba(0,0,0,0.75);
            }

            .mobile-chart {
              height: 180px;
            }

            .inner-collapse {
              display: grid;
              grid-template-columns: 1fr;
              grid-template-rows: auto;
            }

            .cefi-collapse-header {
              border-top: 1px solid #203159;
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-template-rows: auto;
              align-items: center;
            }

            .balances-title {
              border-top: 1px solid #203159;
            }

            .table-assets {
              padding: fn-px2rem(16px) fn-px2rem(16px) fn-px2rem(0px) fn-px2rem(16px);
              max-height: 365px;
              height: calc(100%);
              border-bottom: 1px solid #203159;

              .available-assets {
                height: calc(100% + 55px);
              }
            }
          }
        }

        .slider {
          &.-primary {
            .rc-slider-track {
              height: 4px;
              background: $global-slider-track-background-color;
            }
      
            .rc-slider-rail {
              height: 4px;
              background: linear-gradient(90deg, #1F55FE -0.16%, #1D5DFE 21.34%, #0F9AFF 46.93%, #05CAFE 91.97%);
            }
      
            .rc-slider-mark-text {
              color: $global-colors-link-default;
            }
      
            .rc-slider-dot {
              border-color: white;
            }
      
            .rc-slider-handle {
              border: 1px solid #ffffff;
              box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
              background-color: #ffffff;
            }
          }
      
          &.-danger {
            .rc-slider-track {
              height: 2px;
              background: $global-negative-color;
            }
      
            .rc-slider-rail {
              height: 2px;
            }
      
            .rc-slider-mark-text {
              color: $global-negative-color;
            }
      
            .rc-slider-dot {
              border-color: $global-negative-color;
            }
      
            .rc-slider-handle {
              border: 1px solid #ffffff;
              background: $global-negative-color;
            }
          }
        }

        .harvest-chart-grid {
          display: grid;
          grid-template-columns: minmax(0, 100%);
          grid-template-rows: auto;
          row-gap: fn-px2rem(16px);
          column-gap: fn-px2rem(16px);

          .chart-controls-grid {
            display: grid;
            grid-template-columns: minmax(0, 50%) minmax(0, 50%);
            grid-template-rows: auto;

            @media screen and (max-width: 768px) {
              grid-template-columns: minmax(0, 100%);
              column-gap: 0;
              row-gap: fn-px2rem(16px);
            }

            .chart-control-group {
              .btn-custom {
                text-align: center;
                color: $global-colors-font-3;

                &.active {
                  border: none;
                  background: linear-gradient(90deg, #1F55FE -0.16%, #1D5DFE 21.34%, #0F9AFF 46.93%, #05CAFE 91.97%);
                }
              }
            }
          }

          .harvest {
            text-align: center;
            padding: fn-px2rem(29px) fn-px2rem(16px) fn-px2rem(16px) fn-px2rem(16px);
            background-color: $global-colors-background-panel;
            border-radius: fn-px2rem(16px);

            &-header {
              font-size: fn-px2rem(32px);
              color: $global-colors-font-3;
            }

            &-title {
              color: $global-colors-font-2;
            }

            &-button-color {
              color: rgba(48, 47, 53, 1);
            }
          }

          .chart {
            padding: fn-px2rem(27px);
            background-color: $global-colors-background-panel;
            border-radius: fn-px2rem(16px);
            height: 180px;
          }

          @media screen and (max-width: 768px) {
            grid-template-columns: minmax(0, 100%);
            column-gap: 0;
            row-gap: fn-px2rem(16px);
          }
        }

        .wallets-info {
          z-index: 20;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: minmax( auto, 400px);
          column-gap: fn-px2rem(24px);
          row-gap: fn-px2rem(24px);
          position: relative;

          @media screen and (max-width: 1024px) {
            grid-template-columns: minmax(0, 100%);
            column-gap: 0;
            row-gap: fn-px2rem(16px);
          }

          .title {
            color: $global-colors-font-2;
            font-weight: 300;
            line-height: 1;
            font-size: fn-px2rem(30px);
          }

          .middle-block {
            height: 100%;
            overflow: hidden;
            background-color: $global-colors-background-panel;
            border-radius: fn-px2rem(16px);
          }

          .defi-wallet-balances {
            padding: fn-px2rem(16px) fn-px2rem(16px) fn-px2rem(0px) fn-px2rem(16px);

            &-header {
              text-align: center;
              display: grid;
              grid-template-columns: minmax(0, 100%);
              grid-template-rows: auto;
              position: relative;

              .resize-icon {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 24px;
                cursor: pointer;
              }

              .usd-balance {
                color: $global-colors-font-3;
                font-weight: 400;
                font-size: fn-px2rem(50px);
              }

              .btc-balance {
                color: $global-colors-font-3;
                font-weight: 400;
                font-size: fn-px2rem(20px);
              }
            }

            &-table {
              padding-top: fn-px2rem(16px);
              height: calc(100% - 90px);
            }
          }

          .defi-lending {
            display: grid;
            grid-template-columns: minmax(0, 100%);
            grid-template-rows: minmax(0, 181px) minmax(0, 209px);
            row-gap: fn-px2rem(16px);

            &-limit {
              padding: fn-px2rem(16px) fn-px2rem(18px);
              display: grid;
              grid-template-columns: minmax(100%);
              grid-template-rows: auto;
              row-gap: fn-px2rem(16px);

              .lending-inner-grid {
                display: grid;
                grid-template-columns: minmax(0, 50%) minmax(0, 50%);
                grid-template-rows: auto;
              }
            }

            &-borrow-supply {
              text-align: center;
              padding: fn-px2rem(16px);

              .lending-headers {
                display: grid;
                grid-template-columns: minmax(0, 40%) auto minmax(0, 46%);
                grid-template-rows: auto;
              }
            }
          }
        }

        .available-assets {
          .rcs-inner-container {
            padding-right: fn-px2rem(12px);
          }

          height: 100%;
          .assets-grid {
            display: grid;
            .assets-row {
              background-color: $global-table-hover-color;
              
              &:nth-of-type(odd) {
                background: $global-colors-table-row-even;
              }
            }
          }

          .assets-header-grid {
            display: grid;
            width: calc(100% - 20px);
            justify-content: space-between;
            grid-template-rows: auto;
            grid-template-columns: minmax(0, 25%) minmax(0, 25%) minmax(0, 20%) minmax(0, 15%);
            padding: fn-px2rem(8px) fn-px2rem(8px) fn-px2rem(8px) fn-px2rem(30px);
          }

          .assets-item-grid {
            display: grid;
            width: 100%;
            grid-template-rows: auto;
            justify-content: space-between;
            grid-template-columns: minmax(0, 25%) minmax(0, 25%) minmax(0, 20%) minmax(0, 15%);
            white-space: nowrap;
            align-items: center;
            padding: fn-px2rem(15px) fn-px2rem(22px) fn-px2rem(15px) fn-px2rem(30px);
            
            .value-decoration {
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }