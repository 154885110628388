@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.dynamic-split-layout {
  .favorites-content {
    display: flex;
    flex-direction: column;
    border-radius: fn-px2rem(16px);
    border: 1px solid $global-colors-border-neutral-0;
    background: $global-colors-background-panel;
    margin-bottom: fn-px2rem(16px);
    width: 100%;
    max-width: fn-px2rem(268px);
    padding: fn-px2rem(23px) fn-px2rem(24px) fn-px2rem(22px) fn-px2rem(25px);
    margin-right: fn-px2rem(16px);

    &-items {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: minmax(0, 104px) minmax(0, 104px);
      justify-content: center;
      text-align: center;
      gap: fn-px2rem(15px);

      .item {
        display: grid;
        justify-content: center;
        justify-items: center;
        align-content: start;
        

        .link-text {
          color: $global-colors-font-0;
          overflow: hidden;
          width: fn-px2rem(104px);
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .img-clickable {
          cursor: pointer;
        }
      }
    }

    button {
      width: 100%;
    }

    .component-empty-state {
      height: auto !important;
    }

    .no-favorites {
      font-size: fn-px2rem(12px);
      color: $global-colors-font-3;
      margin-top: fn-px2rem(24px);
      background: $global-colors-background-panel;
      border: none;
    }
  }

  .iframes-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: fn-px2rem(16px);

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .iframes-item {
      border-radius: fn-px2rem(16px);
      border: 1px solid $global-colors-border-neutral-0;
      margin-bottom: fn-px2rem(16px);

      &-header {
        background: $global-colors-background-panel;
        border-bottom: 1px solid $global-colors-border-neutral-0;
        border-radius: fn-px2rem(16px) fn-px2rem(16px) 0 0;
        padding: fn-px2rem(18px);
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: minmax(0, fn-px2rem(30px)) minmax(0, fn-px2rem(30px)) minmax(0, fn-px2rem(30px)) minmax(0, fn-px2rem(565px)) minmax(0, fn-px2rem(48px)) minmax(0, fn-px2rem(144px));
        align-items: center;

        .multiple-selector {
          .css-1auj858-control {
            border: 1px solid $global-colors-border-neutral-4;
          }

          .css-13n3j5x-menu {
            width: fn-px2rem(230px);
          }
        }
        .newbutton-grup {
          display: flex;
          flex-direction: column;
          cursor: pointer;
          margin-bottom:fn-px2rem(2px);
          align-items: center;
          margin-left: fn-px2rem(8px);
          
         
      }
      .header-button{
        background: $global-colors-background-panel;
        color: $global-colors-button-default;
       border: none;
        padding: 0px;
        font-size: fn-px2rem(15px);
          text-align: center
      }
      }

      &-logo {
        display: flex;
        justify-content: center;
        padding: fn-px2rem(71px) 0 fn-px2rem(110px) 0;

        .logo {
          background-image: $work-board-logo-url;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: fn-px2rem(113px);
          width: fn-px2rem(158px);
        }
      }

      &-iframe {
        iframe {
          width: 100%;
          min-height: fn-px2rem(360px);
        }
      }
    }
  }

  .form-control {
    border: 1px solid $global-colors-border-neutral-4;

    &:focus {
      border-color: $global-colors-border-neutral-4;
      box-shadow: none !important;
      z-index: 0;
    }
  }
}

.favorites.modal {
  .modal-content {
    background: $global-colors-background-main;
    color: $global-font-color-primary;
    border-radius: fn-px2rem(16px);

    .modal-header {
      padding: fn-px2rem(31px) fn-px2rem(24px) fn-px2rem(24px);
    }

    .modal-body {
      padding: fn-px2rem(24px) fn-px2rem(24px) fn-px2rem(24px) fn-px2rem(19px);

      input {
        border: 1px solid $global-colors-border-neutral-4;
        font-size: fn-px2rem(14px);;
      }

      .form-control {
        &:focus {
          border-color: #ced4da;
          box-shadow: none !important;
          z-index: 0;
        }
      }

      .submit-button {
        button {
          padding: fn-px2rem(7px) fn-px2rem(35px) fn-px2rem(8px) fn-px2rem(33px);
        }

        button[type="submit"] {
          background-color: $global-colors-button-default;
          color: $global-button-color-primary;
        }

        button[type="button"] {
          background-color: $global-colors-background-main;
          color: $global-colors-button-default;
          border-color: $global-colors-button-default;
          margin-right: fn-px2rem(16px);
        }
      }
    }
  }
}
