@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$rem-1-value: fn-px2rem(16px);
$warning-color: rgba(247, 147, 26, 1);

.pools-layout {
    .asset-icons-pair {
        margin-right: 22px;

        .asset-icon {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            margin-right: -16px;
        }
    }

    a {
        text-decoration: none;
        color: $global-colors-link-default;
    }

    .asset-icon {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        margin-right: 8px;
    }
    &.content {
        display: grid;
        grid-template-rows: auto minmax(0, 100%);
        grid-template-columns: 1fr;
        grid-row-gap: fn-px2rem(16px);
        max-height: 100%;
        padding-bottom: fn-px2rem(16px);
        padding-top: fn-px2rem(16px);
        max-height: 100%;
    }

    .controls {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: minmax(0, 332px) minmax(0, 220px) auto minmax(0, 220px) minmax(0, 371px);
        column-gap: fn-px2rem(16px);

    

        .search-input {
            color: $global-font-color-primary;
            background-color: transparent;

            .custom-btn-search {
                cursor: pointer;
                transition: color .15s ease-in-out,
                    background-color .15s ease-in-out,
                    border-color .15s ease-in-out,
                    box-shadow .15s ease-in-out;
                background: $global-colors-button-default;
                color: $global-button-color-primary;
                border-color: $global-colors-button-default;
                z-index: 1;

                &:hover {
                    border-color: $global-colors-button-hover;
                    color: $global-button-color-hover-primary;
                    background: $global-colors-button-hover;
                }

                &:active,
                &.active {
                    border-color: $global-colors-button-active;
                    color: $global-button-color-active-primary;
                    background: $global-colors-button-active;
                }

            }

            .clear {
                background: inherit;
                border-right: none;
                color: inherit;
            }

            .form-control {
                font-size: 14px;
                background: inherit;
                border-left: none;
                color: inherit;
                border-radius: 4px;
                border-right: none;

                &:focus {
                    border-color: #ced4da;
                    box-shadow: none !important;
                    z-index: 0;
                }
            }
        }
    }

    .table-collapsible {
        border-radius: fn-px2rem(16px);
        border: 1px solid $global-colors-border-neutral-0;
        background: $global-colors-background-panel;
        padding: 16px;
        
        .custom-table {
            display: grid;
            grid-template-columns: minmax(0, 100%);
            grid-template-rows: minmax(80px, auto);
            max-height: 100%;
            color: $global-colors-font-1;
        
            .scroll-container {
                overflow: auto;
            }
    
            .collapse-content {
                &-wrapper {
    
                    .content-column {
                        padding-top: 40px;
                        padding-bottom: 40px;
    
                        .link {
                            cursor: pointer;
                            color: $global-colors-link-default;
                        }
    
                        .fee-text {
                            text-decoration: underline;
                            text-decoration-style: dotted;
                        }
    
                        .stats {
                            display: grid;
                            grid-template-columns: minmax(0, 30%) minmax(0, 30%);
                            grid-template-rows: subgrid;
                        }
                    }
                }
            }
        
            &-row {
                .collapse-header {
                    display: grid;
                    grid-template-columns: 20% repeat(5, 16%);
                    grid-template-rows: auto;
                    align-items: center;
                    padding-left: fn-px2rem(14px);
                    cursor: default;
                }
    
                .collapse-row {
                    .collapse-content {
                        .collapse-content-wrapper {
                            display: grid;
                            grid-template-columns: minmax(0, 30%) minmax(0, 30%) minmax(0, 30%);
                            grid-template-rows: auto;
                            padding-left: 16px;
                        }
                    }
                }
    
                &:nth-of-type(even) {
                    background: $global-colors-table-row-even;
                    //color: $global-font-color-secondary;
                }
        
                &.table-body {
                    .negative-color {
                        color: $global-negative-color;
                    }
        
                    .positive-color {
                        color: $global-positive-color;
                    }
        
                    .custom-table-body-cell {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
    
                        &.details {
                            text-align: center;
                            align-items: center;
                            color: $global-colors-link-default;
    
                            .details-button {
                                cursor: pointer;
                            }
                        }
                    }
        
                    .valign-middle {
                        align-items: center;
                    }
        
                    &:hover {
                        //background-color: $global-table-hover-color;
                        //color: $global-font-color-primary;
                    }
                }
        
                &.table-header {
                    width: calc(100% - 20px);
                    border-bottom: 1px solid $global-colors-border-neutral-3;
                    padding-left: fn-px2rem(14px);
                    padding-right: fn-px2rem(14px);
                    border-radius: 0;
                    white-space: nowrap;
                }
            }
        }
    
        .custom-scroll-bar .rcs-inner-container {
            padding-right: 10px;
    
        }
    
        .rcs-positioning {
            // margin-top: 8px;
        }
    
        .locked {
            color: $warning-color;
            background: rgba(247, 147, 26, 0.1);
            padding: 0 7px;
            border-radius: 20px;
            font-weight: 300;
        }
    
        .position-card {
            display: grid;
            grid-template-columns: minmax(0, 50%) minmax(0, 50%);
            grid-template-rows: auto;
            border-radius: fn-px2rem(16px);
            border: 1px solid $global-colors-border-neutral-0;
            margin-top: 24px;

            .nowrap {
                white-space: nowrap;
                column-gap: 1rem;
            }
        }
    
        .link-color {
            color: $global-colors-link-default;
        }
    
        .link {
            color: $global-font-color-secondary;
        }
    
        .warning-color {
            color: $warning-color;
        }
    
        .auto-refresh {
            transition: color .15s ease-in-out,
                background-color .15s ease-in-out,
                border-color .15s ease-in-out,
                box-shadow .15s ease-in-out;
            background: $global-colors-button-default;
            color: $global-button-color-primary;
            padding-left: 3px;
            padding-right: 6px;
            border-radius: 20px;
            cursor: pointer;
            font-weight: 300;
    
            &:hover {
                border-color: $global-colors-button-hover;
                color: $global-button-color-hover-primary;
                background: $global-colors-button-hover;
            }
    
            &:active,
            &.active {
                border-color: $global-colors-button-active;
                color: $global-button-color-active-primary;
                background: $global-colors-button-active;
            }
        }
    
        .more-info {
            color: $global-colors-link-default;
            font-size: 16px;
        }
    }

    @media screen and (max-width: 1024px) {
        .controls {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: minmax(0, 100%);
            column-gap: 0;
            row-gap: fn-px2rem(16px);
        }
    }
}



 
.Modal {
    width: 400px;
    border-radius: 16px;
    .form{
        // div {
        //     margin-bottom: 24px;
        // }
        label {
            margin-bottom: 3px;
        }
        .modalTitle {
            font-size: 16px; 
            font-weight: 500;
        }
        input[type='text'] {
            height: 32px;
            font-size: 14px;
        }
        .description {
            height: 85px;
        }
        .customInputFile {
            width: 100%;
            height: 56px;
            color: #4674B6;
            background: #F5F6FA;
            border: 1px dashed #4674B6;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .submitBtn {
            background: #EAEFF2;
            color: #707991;
            font-size: 14px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .removeFile{
            :hover {
                cursor: pointer;
            }
        }
    }
}

.analytics-content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 33%));
    grid-template-rows: auto;
    column-gap: 16px;

    .analytics-item {
        border-radius: fn-px2rem(16px);
        border: 1px solid $global-colors-border-neutral-0;
        background: $global-colors-background-panel;
        padding: 24px 36px 64px 24px;
        margin-bottom: 16px;

        &-header {
            display: grid;
            grid-template-columns: minmax(0, 50%) minmax(0, 50%);
            grid-template-rows: auto;
            padding-bottom: 29px;

            .heading {
                text-align: left;
            }

            .show-all {
                text-align: right;
                color: $global-colors-link-default;
                cursor: pointer;
            }
        }

        &-content {
            display: grid;
            grid-template-columns: minmax(0, 40%) minmax(0, 60%);
            grid-template-rows: auto;
            column-gap: 24px;

            .analytics-chart-legend {
                padding-top: 8px;

                .legend-item {
                    display: grid;
                    grid-template-columns: minmax(0, 65%) minmax(0, 35%);
                    grid-template-rows: auto;

                    .protocol-value {
                        text-align: right;
                    }

                    .title-wrapper {
                        padding-bottom: 8px;

                        .color-circle {
                            border-radius: 100%;
                            width: 16px;
                            height: 16px;
                        }

                        .legend-value {
                            white-space: nowrap;
                        }

                        .legend-value {
                            color: $global-font-color-secondary;
                        }
                    }
                }
            }
        }
    }
}