@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.dropdown-navigation-selector {
  .dropdown-toggle {
    &.no-sign {
      &:after {
        display: none;
      }
    }
  }
  .dropdown-menu {
    background-color: $navigation-background-main-color;
    border-color: $logo-divider-color;
  }
}

.navigation {
  &-selector-item {
    border-radius: fn-px2rem(8px);
    background-color: transparent;
    color: $navigation-selector-color;
    cursor: pointer;
    padding: fn-px2rem(8px) fn-px2rem(24px);

    &:hover {
      background-color: $navigation-selector-background-hover;
      color: $navigation-selector-color-hover;
    }
    &:active, &.active {
      background-color: $navigation-selector-background-active;
      color: $navigation-selector-color-active;
    }

  }
  &-menu-item {
    height: fn-px2rem(36.46px);
    color: $navigation-selector-color;
    opacity: 0.7;
    .icon-container {
      width: fn-px2rem(25px);
    }
    &:hover {
      background-color: $navigation-selector-background-hover;
      color: $navigation-selector-color-hover;
      opacity: 1;
    }
    &:active, &.active {
      background-color: transparent;
      color: $navigation-selector-color-active;
      opacity: 1;
    }
  }
}

.news-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fn-px2rem(100px);
  padding-top: fn-px2rem(8px);
  font-family: 'Roboto';
  cursor: pointer;
}