@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$v30: fn-px2rem(30px);

.account-settings {
  padding-top: fn-px2rem(8px);
  padding-left: fn-px2rem(12px);
  padding-right: fn-px2rem(12px);

  .item-more {
    .standard-options-more {
      position: relative;
      left: fn-px2rem(2px);
    }
  }
  &-items {
    display: grid;
    grid-template-rows: $v30 $v30 $v30;
    grid-row-gap: $v30;
    grid-template-columns: 1fr;
    align-items: center;

    .item {
      position: relative;

      &-label {
        top: fn-px2rem(-20px);
      }
    }
  }
}