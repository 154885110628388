@import '../../styles/_functions';
@import '../../styles/_vars';
@import '../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

$success-color: rgba(33, 176, 110, 1);
$font-color: rgba(255, 255, 255, 255);

.nft-generator-layout {
  .global-colors-font-2 {
    color: $global-colors-font-2;
  }

  .global-colors-font-0 {
    color: $global-colors-font-0;
  }

  &-header {
    display: flex;
    align-items: center;

    .react-toggle {
      top: 0;
    }
  }

  &-body {
    display: grid;
    grid-template-columns: minmax(0, 25%) minmax(0, 50%) minmax(0, 25%);
    grid-template-rows: auto;
    grid-gap: fn-px2rem(16px);

    @media screen and (max-width: 1398px) {
      grid-template-columns: minmax(0, 100%);
    }

    .panel-wrapper {
      background: $global-colors-background-panel;
      border: 1px solid $global-colors-border-neutral-0;
      border-radius: fn-px2rem(16px);
    }

    .left-side-content {
      height: fit-content;

      &-title {
        padding: fn-px2rem(30px) fn-px2rem(24px) 0;
      }

      &-items {
        padding: 0 fn-px2rem(16px) fn-px2rem(20px);

        .item {
          color: $global-colors-font-4;
          cursor: pointer;
          padding: fn-px2rem(16px);
          user-select: none;

          &.active {
            border-radius: fn-px2rem(8px);
            color: $global-colors-tab-active;
            font-weight: 500;
            background: $global-colors-background-tab-active;
          }
        }
      }
    }

    .middle-content {
      &-items {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 33%));
        grid-template-rows: auto;
        grid-gap: fn-px2rem(16px);

        .item {
          padding: fn-px2rem(10px);
          cursor: pointer;
          position: relative;

          &.active {
            border-color: rgba(70, 116, 182, 1);
            background: $global-colors-background-nft-active;
          }

          .image {
            width: 100%;
            height: 100%;
          }

          .selected-wrapper {
            position: absolute;
            top: fn-px2rem(6px);
            left: fn-px2rem(6px);

            .selected {
              display: flex;
              background: $success-color;
              border-radius: fn-px2rem(50px);
              padding: fn-px2rem(4px) fn-px2rem(8px) fn-px2rem(4px) fn-px2rem(4px);
              width: fn-px2rem(79px);
              height: fn-px2rem(24px);
              color: $font-color;
            }
          }
        }
      }
    }

    .right-side-content {
      .nft-wrapper {
        padding: fn-px2rem(24px);

        &-image {
          background: $global-colors-background-nft-active;
          border-radius: fn-px2rem(16px);
          padding: fn-px2rem(10px);

          img {
            width: 100%;
          }
        }

        &-color {
          .input-group {
            .form-control {
              border-color: $global-colors-border-neutral-1;
              border-radius: fn-px2rem(6px);
              height: fn-px2rem(48px);
              width: fn-px2rem(160px);
              flex: none;

              &:focus {
                border-color: $global-colors-border-neutral-1;
                box-shadow: none !important;
                z-index: 0;
              }
            }
          }
        }
      }

      .buttons-wrapper {
        display: flex;
        justify-content: space-between;

        .btn-random {
          background: $success-color;
          border: none;
          color: $font-color;
        }

        .btn-enable-nft {
          background: transparent;
          border: 1px solid $success-color;
          color: #00A859;
        }
      }

      .disclaimer-wrapper {
        background: $global-colors-background-panel;
        border-radius: fn-px2rem(8px);
        box-shadow: 0 fn-px2rem(8px) fn-px2rem(6px) rgba(0, 0, 0, 0.08);
        padding: fn-px2rem(20px) fn-px2rem(20px) fn-px2rem(15px) fn-px2rem(22px);

        @media screen and (max-width: 714px) {
          margin-bottom: fn-px2rem(60px);
        }
      }
    }
  }
}

.select-chain.modal {
  .modal-dialog {
    max-width: fn-px2rem(600px);

    .modal-content {
      border-radius: fn-px2rem(16px);
      background: $global-colors-background-main;

      .modal-header {
        padding: fn-px2rem(31px) fn-px2rem(24px) fn-px2rem(24px);
      }

      .modal-body {
        padding: fn-px2rem(21px) fn-px2rem(24px) fn-px2rem(29px);

        &-title {
          color: $global-colors-font-4;
        }

        &-items {
          display: flex;
          flex-wrap: wrap;
          padding: fn-px2rem(21px) 0 fn-px2rem(31px);
          gap: fn-px2rem(16px);

          .item {
            border-radius: fn-px2rem(16px);
            border: 1px solid $global-colors-border-neutral-0;
            display: flex;
            align-items: center;
            width: 100%;
            max-width: fn-px2rem(171px);
            height: fn-px2rem(32px);
            padding: 0 fn-px2rem(8px);
            color: $global-colors-font-2;
            cursor: pointer;

            &.active {
              background: rgba(70, 116, 182, 1);
              border: 1px solid rgba(70, 116, 182, 1);
              box-shadow: 0 fn-px2rem(2px) fn-px2rem(2px) rgba(70, 116, 182, 0.32);
              color: $font-color;
            }

            img {
              padding-right: fn-px2rem(3px);
            }
          }
        }

        &-button {
          button {
            background: $global-colors-button-default;
            color: $global-button-color-primary;
            border-color: $global-colors-button-default;
            width: 100%;
            border-radius: fn-px2rem(4px);
          }
        }
      }
    }
  }
}
