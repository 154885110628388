@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

.donat-chart-container {
  position: relative;
  width: fit-content;
  min-width: 270px;
  min-height: 270px;
  height: fit-content;
  top: fn-px2rem(5px);

  svg {
    overflow: hidden;

    .legend {
      text {
        fill: $global-font-color-secondary;
      }
    }

    .blank-circle {
      fill: $donat-circle-color;
    }

    tspan {
      &.negative {
        color: $global-negative-color;
        fill: $global-negative-color;
      }

      &.positive {
        color: $global-positive-color;
        fill: $global-positive-color;
      }
    }
  }
}