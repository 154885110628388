$font-path-orbitron: "/public/fonts/orbitron";

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: Orbitron;
  src: url('#{$font-path-orbitron}/Orbitron-Regular.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: Orbitron;
  src: url('#{$font-path-orbitron}/Orbitron-Medium.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: Orbitron;
  src: url('#{$font-path-orbitron}/Orbitron-SemiBold.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: Orbitron;
  src: url('#{$font-path-orbitron}/Orbitron-Bold.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 800;
  font-family: Orbitron;
  src: url('#{$font-path-orbitron}/Orbitron-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: Orbitron;
  src: url('#{$font-path-orbitron}/Orbitron-Black.ttf') format('truetype');
}