@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}

@import "src/styles/customized-toggle";

.table-widget {

  &-element {
    position: relative;
    height: 100%;
    width: 100%;

    .table-container {

      .table, .table-striped {
        color: $global-font-color-secondary !important;
      }
      thead {
        th {
          cursor: pointer;

          &:first-child {
            width: fn-px2rem(16px);
          }
        }
      }
      tbody {

        td {
          text-align: center;

          &.negative {
            color: $global-negative-color;
          }

          &.positive {
            color: $global-positive-color;
          }
        }
      }

      .table-striped > tbody > tr:nth-of-type(odd) td {
        color: $global-font-color-secondary !important;
        background-color: $global-table-border-color !important;
      }

      .table-hover > tbody > tr {
        transition: $global-transition;

        &:hover {
          background-color: $global-table-hover-color !important;
          
          & > td {
            color: $global-font-color-primary !important;
          }
        }
      }
      .table th, .table td {
        border-right: 1px solid $global-table-border-color;
        border-left: transparent;
        border-bottom: transparent;
        border-top: transparent;
        white-space: nowrap;
        text-align: start;

        &:last-child {
          border: transparent;
          border-top-right-radius: $global-table-cell-border-radius;
          border-bottom-right-radius: $global-table-cell-border-radius;
        }

        &:first-child {
          border: transparent;
          border-top-left-radius: $global-table-cell-border-radius;
          border-bottom-left-radius: $global-table-cell-border-radius;
        }
      }
    }
  }

  &-controls {

  }

  &-controls-container {
    min-height: fn-px2rem(45px);
    position: relative;

    .currency-toggle-selector, .volume-toggle-selector {
      position: relative;
      top: fn-px2rem(4px);

      label {
        span {
          position: relative;
          top: fn-px2rem(-2px);
        }
      }
    }
    .add-button-action {
      white-space: nowrap;
    }

    .exchange-selector {
      position: relative;
      top: fn-px2rem(1px);

      .dropdown-select {
        position: absolute;
        top: fn-px2rem(22px);
      }

      .title {
        white-space: nowrap;
      }
    }
  }

  &-container {
      height: calc(100% - 59px);
      position: relative;

      &-main-row {
        height: 100%;
        width: 100%;

        .donat-content {
          height: 100%;
        }

        .table-content {
          width: 100%;
          padding-right: .125rem;
        }

      }
    .content-container {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 100%;
      height: 100%;
    }
  }

}

.icon-bordered {
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: fn-px2rem(3px);
  padding: fn-px2rem(1px);
}

.tooltip {
  &.small-value {
    .tooltip-inner {
      width: fit-content !important;
      max-width: fit-content;
      background: $gradient-background;

      .tooltip-content {
        white-space: nowrap;
      }
    }

    .arrow {
      bottom: 0;
      position: absolute;

      &:before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
        top: fn-px2rem(-6px);
        border-width: .4rem .4rem 0;
        border-top-color: rgba(0, 0, 0, .4);
      }
    }
  }
}


/**
BREAK POINTS
 */
@import "breakpoints";


