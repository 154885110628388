@import '../../../styles/_functions';
@import '../../../styles/_vars';
@import '../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}



.empty-state-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.625rem;

    .modal-body {
        background: $gradient-background;

        .info-container {
            color: rgba(255, 255, 255, 1);
        }
    }
    .modal-dialog {
        width: fit-content;
    }

    .modal-badge {
        color: rgba(255, 255, 255, 1);
        top: fn-px2rem(-16px);
        left: fn-px2rem(-16px);
        background-color: rgba(29, 83, 255, 1);
        border-radius: 50%;
        padding: fn-px2rem(4px) fn-px2rem(5px) fn-px2rem(4px) fn-px2rem(5px);
    }


    &-backdrop {
        position: absolute;
        background-color: transparent;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.625rem;

    }
}
