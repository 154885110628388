.wrapper {
  &.day-theme {
    --global-colors-background-main: rgba(255, 255, 255, 1);
    --global-colors-gradient: linear-gradient(180deg, #fefdff 0%, #eae7ee 100%);
    --global-colors-background-panel: rgba(250, 250, 250, 1);

    --global-colors-border-neutral-0: rgba(232, 232, 232, 1);
    --global-colors-border-neutral-1: rgba(226, 227, 239, 1);
    --global-colors-border-neutral-2: rgba(242, 242, 242, 1);
    --global-colors-border-neutral-3: rgba(212, 212, 212, 1);
    --global-colors-border-neutral-4: rgba(177, 181, 191, 1); // New

    --global-colors-font-0: rgba(52, 53, 63, 1);
    --global-colors-font-1: rgba(43, 26, 61, 0.8);
    --global-colors-font-2: rgba(112, 121, 145, 1);
    --global-colors-font-3: rgba(195, 195, 195, 1);
    --global-colors-font-4: rgba(48, 47, 53, 1);

    --global-colors-link-default: rgba(70, 116, 182, 1);
    --global-colors-link-hover: rgba(74, 135, 222, 1);
    --global-colors-link-active: rgba(35, 95, 181, 1);

    --global-colors-table-row-even: rgba(242, 242, 242, 1);

    --global-colors-button-default: rgba(70, 116, 182, 1);
    --global-colors-button-hover: rgba(74, 135, 222, 1);
    --global-colors-button-active: rgba(35, 95, 181, 1);

    --global-colors-tab-active: rgba(29, 83, 255, 1);
    --global-colors-background-tab-active: rgba(85, 126, 255, 0.1);
    --global-colors-background-nft-active: rgba(255, 255, 255, 1);

    --global-colors-background-ask-price: rgba(250, 231, 231, 1);
    --global-colors-background-bid-price: rgba(225, 242, 234, 1);
  }
  &.night-theme {
    --global-colors-background-main: rgba(48, 47, 53, 1);
    --global-colors-gradient: linear-gradient(180deg, #706d7c 0%, #1e1e21 100%);
    --global-colors-background-panel: rgba(30, 31, 35, 1);

    --global-colors-border-neutral-0: rgba(58, 59, 62, 1);
    --global-colors-border-neutral-1: rgba(58, 59, 62, 1);
    --global-colors-border-neutral-2: rgba(242, 242, 242, 1);
    --global-colors-border-neutral-3: rgba(212, 212, 212, 1);

    --global-colors-font-0: rgba(255, 255, 255, 1);
    --global-colors-font-1: rgba(255, 255, 255, 0.8);
    --global-colors-font-2: rgba(255, 255, 255, 0.7);
    --global-colors-font-3: rgba(255, 255, 255, 1);
    --global-colors-font-4: rgba(255, 255, 255, 255);

    --global-colors-link-default: rgba(255, 255, 255, 1);
    --global-colors-link-hover: rgba(255, 255, 255, 0.8);
    --global-colors-link-active: rgba(255, 255, 255, 0.5);

    --global-colors-table-row-even: rgba(255, 255, 255, 0.1);

    --global-colors-button-default: rgba(255, 255, 255, 1);
    --global-colors-button-hover: rgba(255, 255, 255, 0.8);
    --global-colors-button-active: rgba(255, 255, 255, 0.5);

    --global-colors-tab-active: rgba(255, 255, 255, 1);
    --global-colors-background-tab-active: rgba(255, 255, 255, 0.1);
    --global-colors-background-nft-active: rgba(30, 31, 35, 1);

    --global-colors-background-ask-price: rgba(52, 34, 38, 1);
    --global-colors-background-bid-price: rgba(27, 45, 41, 1);
  }
}
