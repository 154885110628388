@import '../../../../styles/_functions';
@import '../../../../styles/_vars';
@import '../../../../styles/_mixins';

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-loading-btn {
  color: transparent !important;
  pointer-events: none;
  user-select: none;
}

.is-loading-btn::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $global-colors-background-main;
  //border: 2px solid #ffffff;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  left: calc(50% - (25px / 2));
  top: calc(50% - (25px / 2));
  position: absolute !important;
}



.bar-chart-widget {
  &-controls {
   &-container {
        //@import 'src/styles/dropdown-select';
        .bar-chart-widget-title {
            color: $bots-widget-controls-title-color;
        }
     }
  }
  &-element {
    height: 100%;
    position: relative;
  }

  &-container {
    height: calc(100% - 65px);
    width: 100%;
    position: relative;

    svg {
      position: relative;
      height: 100%;
      width: 100%;

      &.bar-chart {
        overflow: visible;
        .tooltip-value {
          fill: $bar-chart-tick-text-color;
        }
        .x-tick text {
          font-size: 8px;
        }
        .y-tick text {
          font-size: 12px;
        }
        .x-tick, .y-tick {
          path {
            stroke: transparent;
          }
          .hidden-tick {
            line {
              stroke: transparent;
            }
          }
          .tick {
            line {
              stroke: transparent;
            }
            text {
              fill: $bar-chart-tick-text-color;
            }
            circle {
              stroke: $bar-chart-circle-border-color;
            }
            .dash-line {
              stroke: $bar-chart-dash-line-color;
            }
          }
        }
        .zero-line {
          line {
            stroke: $bar-chart-divider-color;
          }
          circle {
            stroke: $bar-chart-circle-border-color;
          }
        }
        .footer {
          .footer-line {
            stroke: $bar-chart-footer-border-color;
          }
          .legend-text {
            fill: $bar-chart-tick-text-color;
          }
        }
      }
    }
  }
}
